import React from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn } from 'mdbreact';
import { createBrowserHistory } from 'history';
import * as baseUrl from '../../commons/constants';
import * as headers from '../../commons/APIConstants';

const history = createBrowserHistory();

export default class BillDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            fields: {
                paymentDate: '',
                paymentTransactionId: '',
                serialNumber: '',
                rate: '',
                totalKwh: '',
                totalTime: '',
                chargedKwh: '',
                chargedTime: '',
                status: '',
                paidAmount: '',
                ccFee: '',
                refundAmount: '',
                billAmount: ''
            }
        }
        this.toggle = this.toggle.bind(this);
        this.tagOk = this.tagOk.bind(this);
        this.getBillDetails = this.getBillDetails.bind(this);
        this.backToList = this.backToList.bind(this);
    }

    componentDidMount() {
        let state = this.props.location.state;
        let billingId;

        if (state.id != undefined && state.id != "" && state.id != null) {
            billingId = state.id;
            localStorage.setItem('billingId', billingId);
        } else {
            billingId = localStorage.getItem('billingId');
        }

        this.getBillDetails(billingId);
    }

    componentWillUnmount() {

    }

    async getBillDetails(Id) {
        let url = baseUrl.WebPath + "billings/detail/" + Id;

        await fetch(url, headers.getHeaders())
            .then(response => {
                if (response.status == 200) {
                    return response.json();
                } else if (response.status == 401) {
                    this.toggle();
                    localStorage.clear();
                } else if (response.status == 500) {
                    alert('Internal Server Error');
                }
            })
            .then(result => {

                if (result != undefined) {
                    let fields = this.state.fields;

                    fields['paymentDate'] = result.paymentDate;
                    fields['paymentTransactionId'] = result.paymentTransactionId;
                    fields['serialNumber'] = result.serialNumber;
                    fields['rate'] = result.rate;
                    fields['totalKwh'] = result.totalKwh;
                    fields['totalTime'] = result.totalTime;
                    fields['chargedKwh'] = result.chargedKwh;
                    fields['chargedTime'] = result.chargedTime;
                    fields['status'] = result.status;
                    fields['paidAmount'] = result.paidAmount;
                    fields['ccFee'] = result.ccFee;
                    fields['refundAmount'] = result.refundAmount;
                    fields['billAmount'] = result.billAmount;

                    this.setState({
                        fields: fields
                    });
                }
            })
            .catch(error => {
                console.error(error);
            })
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    tagOk() {
        history.push('/');
        window.location.reload();
    }

    backToList() {
        history.goBack();
    }

    render() {
        let totalBillingAmount = this.state.fields.billAmount + this.state.fields.ccFee;
        return (
            <>
                <main className='content-div'>
                    <p>Bill Details</p>
                    <div className="page-outerdiv">
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="pull-right">
                                    <button className="refresh-btn" type="button" data-test="button" onClick={this.backToList} ><i className="fas fa-angle-left mr-2"></i> Back</button>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-md-6'>
                                <div className='content-innerdiv'>
                                    <div>
                                        <span><b> Payment Date : </b></span>
                                        <label>{this.state.fields.paymentDate}</label>
                                    </div>
                                    <div>
                                        <span><b> Serial No : </b></span>
                                        <label>{this.state.fields.serialNumber}</label>
                                    </div>
                                    <div>
                                        <span><b> Total Charged (kWh) : </b></span>
                                        <label>{this.state.fields.totalKwh}</label>
                                    </div>
                                    <div>
                                        <span><b> Charged (kWh) : </b></span>
                                        <label>{this.state.fields.chargedKwh}</label>
                                    </div>
                                    <div>
                                        <span><b> Status : </b></span>
                                        <label>{this.state.fields.status}</label>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='content-innerdiv'>
                                    <div>
                                        <span><b> Transaction Id : </b></span>
                                        <label>{this.state.fields.paymentTransactionId}</label>
                                    </div>
                                    <div>
                                        <span><b> Rate : </b></span>
                                        <label>{this.state.fields.rate}</label>
                                    </div>
                                    <div>
                                        <span><b> Total Time : </b></span>
                                        <label>{this.state.fields.totalTime}</label>
                                    </div>
                                    <div>
                                        <span><b> Charged Time : </b></span>
                                        <label>{this.state.fields.chargedTime}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3' style={{ margin: "1px", borderRadius: "10px" }}>
                            <div className='col-md-12 h4 text-center p-2' style={{ background: "whitesmoke" }}>Bill Details</div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-md-12'>
                                <div className='content-innerdiv'>
                                    <div className="col-md-12 datatable">
                                        <table className="table">
                                            <thead>
                                                <tr><th><span><b>Total Amount Authorized</b></span></th>
                                                    <th><span><b>Charged Amount</b></span></th>
                                                    <th><span><b>Refund Amount</b></span></th>
                                                    <th><span><b>Total Billing Amount</b></span></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>${parseFloat(this.state.fields.paidAmount).toFixed(2)}</td>
                                                    <td>${parseFloat(this.state.fields.billAmount).toFixed(2)}</td>
                                                    <td>${parseFloat(this.state.fields.refundAmount).toFixed(2)}</td>
                                                    <td>${parseFloat(totalBillingAmount).toFixed(2)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br /><br />
                    </div>
                </main>
                {/* session expiry popup */}
                <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
                    <MDBModalHeader toggle={this.toggle}>Session Expired</MDBModalHeader>
                    <MDBModalBody>
                        Please login again.
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="primary" onClick={this.tagOk}>OK</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </>
        );
    }
}