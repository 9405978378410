import React from 'react';
import { MDBInput, MDBCol,MDBRow, MDBModal , MDBBtn, MDBModalHeader,MDBModalBody,MDBModalFooter} from "mdbreact";
import {Form} from 'react-bootstrap';
import * as baseUrl from './commons/constants';
import * as Roles from './commons/roles';
import * as GeneralUser from './commons/GeneralUser';
import * as PlatformUser from './commons/PlatformUser';
import * as TenantUser from './commons/TenantUser';
import {Link} from 'react-router-dom';
import  $ from "jquery";

class editUserManagement extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
          sucess1: false,
          colour: 'sucess',
          message:"",
          fields: {id: '', active:'', email:'', firstName:'', lastName:'', mobileNumber:'', organisation:'', role:'',employeeId:'',status:'',rfidTag:'',aliasName:''},
          sucess:{},
          errors: {},
          activeItem: "1",
          model:"false",
          page_title:"Edit User",
          dataArray:[],
          modalsession:false,
          roles:[],
          disabledAttr:true,
          multipleRfidArray:[
            {
              rfidTag: '', aliasName: '' ,tagType:'other'
            }
          ],
          multipleRfidArrayAlias:[],
          availableSites: [],
          siteManagementsIds: [],
          selectedSites:[]
        }
        this.toggle = this.toggle.bind(this)
        this.tagOk = this.tagOk.bind(this)
        this.addNew = this.addNew.bind(this)
        this.backToList = this.backToList.bind(this)
        this.changeHandler = this.changeHandler.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.roleidChange = this.roleidChange.bind(this)
        this.organizationChange = this.organizationChange.bind(this)
        this.activeChange = this.activeChange.bind(this)
        this.togglesession = this.togglesession.bind(this)
        this.getRoles=this.getRoles.bind(this)
        this.backToList = this.backToList.bind(this)
        this.confirmtoggle1 = this.confirmtoggle1.bind(this)
        this.addMultipleRfid = this.addMultipleRfid.bind(this)
        this.removeRfid=this.removeRfid.bind(this)
        this.viewUserDetails = this.viewUserDetails.bind(this)
        this.changeRfid = this.changeRfid.bind(this)
        this.onShowAlert=this.onShowAlert.bind(this)
        this.getIsAllSitesSelected = this.getIsAllSitesSelected.bind(this);
        this.onUpdateSiteSelectAll = this.onUpdateSiteSelectAll.bind(this);



}
changeRfid(index,field,e){
  let tempArray = [...this.state.multipleRfidArrayAlias] ; 
 tempArray[index][field] = e.target.value;
  this.setState({multipleRfidArrayAlias : tempArray});
  console.log(this.state.multipleRfidArrayAlias);
  this.setState({disabledAttr:""});
  if(field == "rfidTag"){
    $(`.rfidTag_${index}`).text("");
    $('.rfidexists').text("");
  }else if(field == "aliasName"){
    $(`.aliasName_${index}`).text("");
  }
$('.empty_rfids').text("");
}
onShowAlert(){
  
  
 this.setState({sucess1:true},()=>{
   window.setTimeout(()=>{
     this.setState({sucess1:false})
   },5000)
 });

}


addMultipleRfid(){
  this.setState({
    multipleRfidArrayAlias:[...this.state.multipleRfidArrayAlias,{rfidTag:"",aliasName:"",tagType:"other"}]
  })
  }
  removeRfid(index){
    
    let data=this.state.multipleRfidArrayAlias

    // remove object
    data.splice(index, 1);
    this.setState({
        multipleRfidArrayAlias:data,
        disabledAttr:""
    });
   
    let lookup = this.state.multipleRfidArrayAlias.reduce((a, e) => {
      a[e.rfidTag] = ++a[e.rfidTag] || 0;
      return a;
    }, {});

    let duplicateCount = this.state.multipleRfidArrayAlias.filter(e => lookup[e.rfidTag]);

    if(duplicateCount.length == 0){
      $('.rfidexists').text("");
    }
  }
confirmtoggle1(event){
  // event.preventDefault();
  
  this.setState({
    confirmmodal1: !this.state.confirmmodal1
  });
}
backToList(){
  this.props.history.push('/userManagementController');
}

async togglesession(){
  
  this.setState({
  modalsession: !this.state.modalsession
  });
}


sessiontagOk(){
  this.props.history.push('/');
  window.location.reload();
}
roleidChange(){
  var x = document.getElementById("role").value;
  let fields = this.state.fields;
  fields["role"] = x; 
  this.setState({fields});
}
tagOk(){
  this.props.history.push('/userManagementController');
  window.location.reload();
}
toggle = () => {
  this.setState({
  modal: !this.state.modal
  });
}




addNew(){
  this.props.history.push('/Userlist');
}


// checking the format of number
  numberHandler(field, e){ 
      let fields = this.state;
      if(e.target.validity.valid){
        fields[field] = e.target.value; 
        this.setState({fields});
      }else if(e.target.value==""){
        fields[field] = e.target.value; 
        this.setState({fields});
      }
  }
  changeHandler(field, e){ 
    
    let errors = {};
    let fields = this.state.fields;
    fields[field] = e.target.value; 
    if(field == "employeeId"){
      $('.employeeidexists').text("");
    }
    this.setState({fields});
    this.setState({disabledAttr:""});
    // if(e.target.value != ""){
    //   errors["rfidempty"]=""
    //   this.setState({
    //     errors: errors
    //     });
    // }
    }

    activeChange = () => {
      var x = document.getElementById("myCheck").checked;  
      let fields = this.state.fields;
      if(x){
         fields.status=true;
           }
         else{
          
          fields.status=false;
           }
           this.setState({fields});
           this.setState({disabledAttr:""});
        
    }
    isHaveAlphabetic(string){
      if(/[a-zA-Z]/.test(string) == true){
        return true;
      }else{
        return false;
      }
    }
    isLengthAllowed(string){
      if(string.length<=20){
        return true;
      }else{
        return false;
      }
    }
    isLengthAllowed128(string){
      if(string.length<=128){
        return true;
      }else{
        return false;
      }
    }
  async handleSubmit(event) {
    event.preventDefault();

    let errors = {};

    this.setState({
      confirmmodal1: !this.state.confirmmodal1
    });
    this.state.multipleRfidArrayAlias.forEach((element, index) => {
      if (element.rfidTag != "" && element.rfidTag != null) {

        if (!element.rfidTag.match(/^[0-9a-zA-Z_@,.!#%&*]{0,20}$/)) {

          errors["rfidempty"] = "please enter valid rfid tag.";
          $(`.rfidTag_${index}`).text("please enter valid rfid tag");
        } else {
          $(`.rfidTag_${index}`).text("");
        }
      }
      else {
        $(`.rfidTag_${index}`).text("please enter rfid tag.")
      }

      if (element.aliasName != "" && element.aliasName != null) {

        if (!element.aliasName.match(/^[0-9a-zA-Z_@,.!#%&*]{0,20}$/)) {
          // $(`.aliasName_${index}`).text("please enter valid rfid alias tag");
          // errors["rfidalias"] = "please enter valid rfid alias tag"
        } else {
          $(`.aliasName_${index}`).text("");
        }
      }
      else {
        $(`.aliasName_${index}`).text("please enter alias name.")
      }

    })




    var email = this.state.fields['email'];
    var organisation = this.state.fields['organisation'];
    var firstName = this.state.fields['firstName'];
    var lastName = this.state.fields['lastName'];
    var mobileNumber = this.state.fields['mobileNumber'];
    var employeeId = this.state.fields['employeeId'];
    var role = this.state.fields['role'];
    var rfid = this.state.fields['rfid'];
    const siteManagementsIds = this.state.selectedSites;

    if (email == '') {
      errors["emailempty"] = "Please enter email";
    }
    if (email != '') {
      if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/)) {
        errors["entervalidemail"] = "Please enter valid email id";
      }

    }
    if (organisation == '') {
      errors["organisationempty"] = "Please select organization";
    }
    if (firstName == '') {
      errors["firstName"] = "Please enter first name";
    }
    if (lastName == '') {
      errors["lastName"] = "Please enter last name";
    }

    if (role == "") {
      errors["role"] = "Please select role";
    }
    if (role == Roles.WEBASTO_END_USER_COMMERCIAL && rfid == "") {
      errors["rfidempty"] = "Please enter rfid tag"
    }

    if (errors.hasOwnProperty("emailempty") || errors.hasOwnProperty("organisationempty") || errors.hasOwnProperty("lastName") || errors.hasOwnProperty("firstName") || errors.hasOwnProperty("role") || errors.hasOwnProperty("employeeId") || errors.hasOwnProperty("entervalidemail") || errors.hasOwnProperty("rfidempty")) { }
    //  errors.hasOwnProperty("mobileNumber")|||| errors.hasOwnProperty("validphoneNo")
    else {
      let userRole = localStorage.getItem('role');
      var url = "";
        url = PlatformUser.UPDATE_USER + this.state.fields.id;

      let data = { "email": this.state.fields.email, "firstName": this.state.fields.firstName, "lastName": this.state.fields.lastName, "mobileNumber": this.state.fields.mobileNumber, "role": this.state.fields.role, "organisation": this.state.fields.organisation, "status": this.state.fields.status};
        fetch(url, {
          method: 'PUT', // or 'PUT'
          body: JSON.stringify(data), // data can be `string` or {object}!
          headers: {
            "content-type": "application/json",
            "Authorization": localStorage.getItem("Authorization"),
          }

        }).then(resp => {
          
          if (resp.status == 401) {
            this.togglesession();
            // alert("Access token is expired, please login again")
            localStorage.clear();
          }
          else if (resp.status == 404) {
            this.setState({ errors: errors });

          } else if (resp.status == 500) {


          }

          else if (resp.status == 409) {
            this.setState({
              errors: errors
            });
          }
          else if (resp.status == 400) {
          } else if (resp.status == 200) {
            return resp.json();

          }
        }).then(response => {
          
          if (response.status == "SUCCESS") {

            this.toggle()
            if (localStorage.getItem('email') == this.state.fields.email) {
              localStorage.setItem('user', this.state.fields.firstName);
            }
            // localStorage.setItem('user', this.state.fields.firstName);
            $('.employeeidexists').text("");

          }
          else if (response.status == "FAILURE" && response.errorCode == "3104" && response.errorMessage == "User already exists") {
            errors["userexists"] = "User is already exists with this email id"
            this.setState({
              errors: errors
            });
          }
          else if (response.status == "FAILURE" && response.errorCode == "3123" && response.errorMessage == "RFID tag issue") {
            var rfids = response.rfids[0];
            var errorRfid = rfids.rfidTag;
            var errorrfidMessage = rfids.errorReason;
            if (rfids.errorReason == "RFID is already assigned") {
              this.setState({ message: errorRfid + "  is already assigned", colour: 'danger' })
              this.onShowAlert();
            }


            $('.rfidexists').text("Duplicate rfids not allowed");

            if (rfids.errorReason == "Alias name should not be empty" || rfids.errorReason == "RFID tag should not be empty") {
              $('.rfidexists').text("");
            }
          } else if (response.status == "FAILURE" && response.errorCode == "3124" && response.errorMessage == "EmployeeId is already exists") {
            $('.employeeidexists').text("EmployeeId is already exists");
          }
          else { }

          this.setState({
            errors: errors
          });

        })
          .catch(error => alert('Error:' + error));
      }
    this.setState({ errors: errors });
  }

  organizationChange(){
    var x = document.getElementById("organisation").value;
    let fields = this.state.fields;
    fields["organisation"] = x;
    this.setState({fields});
}
getEditUserData(){
  var url = baseUrl.URLPath + "admin/v1.0/account/all";
  fetch(url,{
    method: "GET",
    headers: {
          "content-type":"application/json",
          "Authorization":localStorage.getItem("Authorization")
        }
    })
    .then((resp) => {
      if(resp.status==401){
        this.togglesession();
        localStorage.clear();
      }
      if(resp.status==200){
        return resp.json();
      }
    }) 
    .then((response) =>{
      if(response!=undefined){
    this.setState({ dataArray:response.organisationDTOS })
      }
    })
    .catch((error) => {
        console.log(error, "catch the loop")
    })
}
  componentDidMount(){
   // document.getElementById("myCheck").checked = true;   
    this.getRoles();
    this.getEditUserData();
    this.viewUserDetails(); //selected sitemanagement sites
  }

  viewUserDetails(){
     var userDetails = this.props.location.state;
     let role = localStorage.getItem('role');

    var url = "";
      url = PlatformUser.GET_USER_DETAILS + userDetails;

    fetch(url,{
      method: "GET",
      headers: {
            "content-type":"application/json",
            "Authorization":localStorage.getItem("Authorization")
          }
      })
      .then((resp) => {
        if(resp.status==401){
          this.togglesession();
          localStorage.clear();
        }
        if(resp.status==200){
          return resp.json();
        }
      }) 
      .then((response) =>{
        if (response != undefined) {
          var data1 = response.userDTOS;
          var data2 = response.rfids;
          this.setState({ fields: data1[0] });
        }
      })
      .catch((error) => {
          console.log(error, "catch the loop")
      })
  }

  getRoles(){
    var url = baseUrl.URLPath + "roles/";
    fetch(url,{
      method: "GET",
      headers: {
            "content-type":"application/json",
            "Authorization":localStorage.getItem("Authorization")
          }
      })
      .then((resp) => {
        if(resp.status==401){
          this.togglesession();
          localStorage.clear();
        }
        if(resp.status==200){
          return resp.json();
        }
      }) 
      .then((response) =>{
        let fields = this.state.fields;
        fields.status = true;
        if(response!=undefined){
      this.setState({ roles:response.roles})
        }
        document.getElementById("role").value=this.state.fields.role;
      })
      .catch((error) => {
          console.log(error, "catch the loop")
      })
  }
  getIsAllSitesSelected() {
    const {availableSites, selectedSites} = this.state;
    let isAllChecked = false;
    if(availableSites && selectedSites){
      for(let i=0; i<availableSites.length;i++){
        if(selectedSites.some(e => e==availableSites[i].id) == true){
          isAllChecked = true;
        }else{
          isAllChecked = false;
          break;
        }
      }
    }else{
      isAllChecked = false;
    }
    return isAllChecked;
  }
  onUpdateSiteSelectAll(event) {
    const isChecked = event.target.checked;
    if(isChecked) {
      var managementsIds = [];
      this.state.availableSites.map((site,index)=>{
        managementsIds = [...managementsIds,site.id];
      });
      this.setState({ selectedSites: managementsIds });
    } else {
      this.setState({ selectedSites: []});
    }
  }
  onSelectSite(event, el) {
    const isChecked = event.target.checked;
    var managementsIds = [];
    managementsIds=this.state.selectedSites;
    if(isChecked) {
      managementsIds = [...managementsIds, el.id];
    } else {
      const index = managementsIds.indexOf(el.id);
      if (index > -1) {
        managementsIds.splice(index, 1);
      }
    }
    this.setState({selectedSites: managementsIds});
  }
render() {

var getRole = localStorage.getItem("role");

let editCommercialAndResidential = false;
let isCommercialAndResidential = false;

let editCommercialAndResidentialAndSiteAdmin = false;
let isCommercialAndResidentialAndSiteAdmin = false;

if(getRole == "WEBASTO_SUPER_ADMIN_DEVOPS" || 
   getRole == "WEBASTO_CUSTOMER_ADMIN" || 
   getRole == "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE" || 
   getRole == "WEBASTO_SITE_ADMIN"){
     editCommercialAndResidential = true;
}

if(this.state.fields.role == "WEBASTO_END_USER_COMMERCIAL" || this.state.fields.role == "WEBASTO_END_USER_RESIDENTIAL"){
  isCommercialAndResidential = true;
}

if(getRole == "WEBASTO_SUPER_ADMIN_DEVOPS" || 
   getRole == "WEBASTO_CUSTOMER_ADMIN" || 
   getRole == "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE"){
     editCommercialAndResidentialAndSiteAdmin = true;
}

if(this.state.fields.role == "WEBASTO_END_USER_COMMERCIAL" || this.state.fields.role == "WEBASTO_END_USER_RESIDENTIAL" || this.state.fields.role == "WEBASTO_SITE_ADMIN"){
  isCommercialAndResidentialAndSiteAdmin = true;
}
  const organisationDTOS =this.state.dataArray.map((dataArray, index) => {
    return(
      <option value={dataArray.id}>{dataArray.company}</option>
    )
   
  })
  const roles =this.state.roles.map((role, index) => {
    return(
      <option value={role.identifier}>{role.displayName}</option>
    )
   
  })
  return (
    <>
    <main class="content-div" >
         {/* <Header page_title={this.state.page_title}/> */}
         <p>Edit User
         <div className="breadcrumb_div">
          Settings &gt; <Link to="/userManagementController"> <span className="">Users </span></Link> &gt; Edit User
        </div>
           </p>
        <div className="page-outerdiv">
        <MDBRow className="mb-2">
          <MDBCol sm="12">
          <button type="button" className="btn_primary mt-1" onClick={this.backToList}><i class="fa fa-angle-left mr-2" aria-hidden="true"></i> Back</button>
          </MDBCol>
        </MDBRow>
      <MDBRow>
            <MDBCol sm="12">
               <div>
        <form
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
        >
          <MDBRow>
          <div className="col-md-12 mb-3">
          <span class=" form-title">User Details:</span> 
       </div>
            <MDBCol md="4">
            <MDBInput disabled
                 value={this.state.fields["email"]}
                 onChange={this.changeHandler.bind(this, "email")}
                type="text"
                id="email"
                name="Email"
                label="E-mail"
                className="text_lowercase"
              >
                <div className="invalid-feedback">
                  Please provide a valid Email.
                </div>
      <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["validemail"]}</span></span> 
      <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["emailempty"]}</span></span>
      <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["entervalidemail"]}</span></span> 
              </MDBInput>
            </MDBCol>
            <MDBCol md="4">
              <MDBInput
                 value={this.state.fields["firstName"]}
                 onChange={this.changeHandler.bind(this, "firstName")}
                type="text"
                id="firstName"
                name="firstName"
                label="First Name"
              >
                <div className="invalid-feedback">
                  Please provide a valid Email.
                </div>
                <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["firstName"]}</span></span> 
            </MDBInput>
            </MDBCol>
            <MDBCol md="4">
              <MDBInput
                 value={this.state.fields["lastName"]}
                 onChange={this.changeHandler.bind(this, "lastName")}
                type="text"
                id="lastName"
                name="lastName"
                label="Last Name"
              >
                <div className="invalid-feedback">
                  Please provide a valid Email.
                </div>
                <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["lastName"]}</span></span> 
            </MDBInput>
            </MDBCol>
            <MDBCol md="4" className="mt-2" >
              <MDBInput
                 value={this.state.fields["mobileNumber"]}
                 onChange={this.changeHandler.bind(this, "mobileNumber")}
                type="text"
                id="mobileNumber"
                name="mobileNumber"
                label="Mobile No"
              >
                <div className="invalid-feedback">
                  Please provide a valid Email.
                </div>
            </MDBInput>
            <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["mobileNumber"]}</span></span> 
            <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["validphoneNo"]}</span></span> 
            </MDBCol>
            <MDBCol md="4" className="mt-2"  >
            <select disabled className="browser-default custom-select select_height mb_8" id="role" onChange={this.roleidChange.bind(this)} value={this.state.fields.role}>
                          <option value=""> -- Select Role --</option>
                          <option value={this.state.fields.role}>{this.state.fields.role}</option>
                          {/* {roles} */}
                                   </select> 
          <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["role"]}</span></span> 
          </MDBCol>
            <MDBCol md="4" className="mt-4">
          <Form.Check 
              custom
             value={this.state.fields.status}
              onChange={this.activeChange}
               checked={this.state.fields.status}
              type="checkbox"
              id="myCheck"
              label="Active"
            />
            </MDBCol>
           
          </MDBRow>
          <MDBCol md="12" className="text-right">
          <button type="button" className="refresh-btn mt-5 mr-3"  onClick={this.confirmtoggle1}><i class="fas fa-save mr-2"></i> Save</button>
            </MDBCol>
        </form>
      </div>
         </MDBCol>
        </MDBRow>
        </div>
 
    </main>

    <MDBModal isOpen={this.state.confirmmodal1} toggle={this.confirmtoggle1} size="md" className="model_top">
<MDBModalHeader toggle={this.confirmtoggle1}>Confirmation</MDBModalHeader>
<MDBModalBody>
 Are you sure you want to save the changes?
</MDBModalBody>
<MDBModalFooter>
<button type="button" data-test="button" className="btn_primary" onClick={this.confirmtoggle1}>Close</button>
<button type="button" data-test="button" className="btn_primary" onClick={this.handleSubmit}>YES</button>
{/* <MDBBtn color="danger" onClick={this.confirmtoggle1}>Close</MDBBtn> */}
{/* <MDBBtn color="primary" onClick={this.handleSubmit}>YES</MDBBtn> */}
</MDBModalFooter>
</MDBModal>
    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
      <MDBModalHeader toggle={this.toggle}>Success</MDBModalHeader>
      <MDBModalBody>
        User details updated sucessfully.
      </MDBModalBody>
      <MDBModalFooter>
      <button type="button" data-test="button" className="btn_primary" onClick={this.tagOk}>OK</button>
      {/* <MDBBtn color="primary" onClick={this.tagOk}>OK</MDBBtn> */}
      </MDBModalFooter>
      </MDBModal>

      {/* popup for session expire */}

 <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
      <MDBModalHeader toggle={this.togglesession}>Session has expired</MDBModalHeader>
      <MDBModalBody>
       Please login again.
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
      {/* <MDBBtn color="primary" onClick={this.sessiontagOk}>OK</MDBBtn> */}
      <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk}>OK</button>
      </MDBModalFooter>
      </MDBModal>
    </>
  );
}
}

export default editUserManagement;