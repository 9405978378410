import React from 'react';
import { MDBDataTable,MDBIcon,Select,MDBModal,MDBModalHeader, MDBModalBody,MDBModalFooter,MDBBtn,MDBNav,MDBNavLink,MDBNavItem,MDBTabPane,MDBTabContent} from 'mdbreact';
import {Form,Table,Check } from 'react-bootstrap';
import * as baseUrl from './commons/constants';
import { createBrowserHistory } from 'history'
import $ from 'jquery';
import Pagination from "react-js-pagination";
import { Line ,Bar, Doughnut, Polar  } from "react-chartjs-2";
import {Link} from 'react-router-dom';
import moment from 'moment';




const history = createBrowserHistory();

class userHistory extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
          allUser:[],
          dataLine:'',
          userData:[],
          allUserValue:[],
          chargingHistory:[],
          userValue:[],
          yearmonthName:[],
           saveUserId:"",
          saveWallBoxNo : "",
          saveEmail : "",
         userStep:{
                min:0,
                suggestedMax:10
            },
            alluserStep:{
              min:0,
              suggestedMax:10
            },
          dataBar:[],
          modal:false,
          activeItem: "2",
          page_title:"User History",
          billingHistory: [],
    dataArray1: [],
    noOfRecords:'',
    data:new Map(),
    pageSize:"10",
    activePage: 1,
    activeItem: "2",
    adminStatus:1,
    pageSize1:10,
    data1:new Map(),
    activePage1: 1,
    noOfRecords1:'',
  
}
this.tabToggle = this.tabToggle.bind(this);
this.backToList = this.backToList.bind(this);
this.toggle = this.toggle.bind(this);
this.tagOk = this.tagOk.bind(this);
this.handlePageChange = this.handlePageChange.bind(this);
this.showEntriesChange = this.showEntriesChange.bind(this);
this.showAdminChange = this.showAdminChange.bind(this);
// user
this.handlePageChangeUser = this.handlePageChangeUser.bind(this);
this.getBillingHistory = this.getBillingHistory.bind(this);
this.getBillingCount = this.getBillingCount.bind(this);
this.UserhandleChange = this.UserhandleChange.bind(this);
this.chargingHistory = this.chargingHistory.bind(this);
this.getMonthList = this.getMonthList.bind(this);
this.exportBillingHistoryCsv = this.exportBillingHistoryCsv.bind(this);

}


// tab view click
// tab view click
tabToggle(tab,event){
  this.setState({
    currentTab: tab
  });
  if (this.state.activeItem !== tab) {
    this.setState({
      activeItem: tab
    });
  }

   if(tab==2){
     this.UserhandleChange();
      // this.getUserAcount()
      this.chargingHistory();
   }
   if(tab==3){
this.chargingHistory();
   }
   if(tab==1){
    this.getBillingHistory(this.state.activePage,this.state.pageSize);

   }

};








      handlePageChangeUser(pageNumber){
        if(pageNumber !=this.state.activePage1){
          var data1 = this.state.data1;
          var pageData = data1.get(pageNumber);
          if(pageData == undefined){
        this.setState({activePage1: pageNumber});
          }else{
            this.setState({activePage1:pageNumber});
            this.setState({dataArray1:pageData})
          }
        }
      }

handlePageChange(pageNumber) {
  
  if(pageNumber !=this.state.activePage){
    var data=this.state.data;
    var pageData=data.get(pageNumber);
    if(pageData==undefined){
  this.setState({activePage: pageNumber});
  this.getBillingHistory(pageNumber,this.state.pageSize);

    }else{
      this.setState({activePage: pageNumber});
      this.setState({dataArray:pageData})
    }
  }
}

showEntriesChange(){
  var entries=document.getElementById("showEntries").value;
  this.state.data1.clear();
  this.setState({pageSize1:entries});
  this.getUser(1,entries,this.state.userStatus);
  
}
showAdminChange(){
  var entries=document.getElementById("showAdminEntries").value;
  this.state.data.clear();
  this.setState({pageSize:entries});
  this.getBillingHistory(1,entries);
}
showAdminChange3(){
  var entries=document.getElementById("showAdminEntries3").value;
  this.state.data.clear();
  this.setState({pageSize:entries});
  this.getBillingHistory(1,entries);
}

backToList(){
  this.props.history.push('/wallBoxUserList')
}

  tagOk(){
    history.push('/');
    window.location.reload();}
  toggle = () => {
    this.setState({
    modal: !this.state.modal
    });
  }


        componentDidMount(){

          let wallBox = this.props.location.state
          if(wallBox != undefined){
            localStorage.setItem('saveSno', JSON.stringify(this.props.location.state)); //stringify object and store
            var wallBox1=this.props.location.state;
            var getSno = wallBox1.wallBoxNo;
            this.state.saveWallBoxNo = getSno;
            this.setState({saveWallBoxNo : getSno})
            var getEmailArray = [];
            getEmailArray = wallBox1.dataArray1;
            var getEmail = getEmailArray.email
            this.state.saveEmail = getEmail;
            this.setState({saveEmail : getEmail});
            this.setState({userInternalId : getEmailArray.id},()=>{this.UserhandleChange();this.chargingHistory();})
          }else{
            var getSavedSno = JSON.parse(localStorage.getItem('saveSno')); //retrieve the object
            this.props.location.state = getSavedSno
            var wallBox1=this.props.location.state;
            var getSno = wallBox1.wallBoxNo;
            this.state.saveWallBoxNo = getSno;
            this.setState({saveWallBoxNo : getSno})
            var getEmailArray = [];
            getEmailArray = wallBox1.dataArray1;
            var getEmail = getEmailArray.email
            this.state.saveEmail = getEmail;
            this.setState({saveEmail : getEmail});
           this.setState({userInternalId : getEmailArray.id},()=>{this.UserhandleChange();this.chargingHistory();})
          }
          this.getMonthList();
          }


      
          getBillingCount(){
            var wallBox=this.props.location.state;
            var getUserId = [];
            getUserId = wallBox.dataArray1;
          var getEmailId = getUserId.id
          this.state.saveUserId = getEmailId;
          var url=baseUrl.URLPath+"web/billings/"+this.state.saveUserId+"/count";
           //cordsetplusbapi.evsolutions.com/web/billings/bbd712bb-30c2-4b8f-b967-647eab1c8be2/count
             
             fetch(url,{
              method: "get",
              headers:{
                "content-type":"application/json",
                "Authorization":localStorage.getItem("Authorization")               
              }
            })
              .then((resp) => {
                
                if(resp.status==401){
                  this.toggle();
            
                  localStorage.clear();
                 
                }
                if(resp.status==200){
                  return resp.json();
                }    }) 
              .then((response) =>{
                
                if(response!=undefined){
              this.setState({ noOfRecords:response.count});
              console.log(this.state.noOfRecords);
            
            }
              })
              .catch((error) => {
                  console.log(error, "catch the loop")
              })
              this.getBillingHistory(this.state.activePage,this.state.pageSize);
             
            }
           
    // to get the billing history details
          getBillingHistory(pageNo,pageSize){
           
           var wallBox=this.props.location.state;
          if(wallBox != undefined){
            localStorage.setItem('saveSno', JSON.stringify(this.props.location.state)); //stringify object and store
            var getUserId = [];
            getUserId = wallBox.dataArray1;
          var getInId = getUserId.id
          this.state.saveUserId = getInId;
          }else{
            var getEmailId = JSON.parse(localStorage.getItem('saveSno')); //retrieve the object
            this.props.location.state = getEmailId
          //   var getUserId = [];
          //   getUserId = wallBox.dataArray1;
          // var getInId = getUserId.id
          // this.state.saveUserId = getInId;

          }

           
            var url = baseUrl.URLPath + "web/billings/"+this.state.saveUserId+"?pageNo="+parseInt(pageNo-1)+"&pageSize="+pageSize;
            //cordsetplusbapi.evsolutions.com/web/billings/bbd712bb-30c2-4b8f-b967-647eab1c8be2?pageNo=1&pageSize=10
            fetch(url,{
                method: "GET",
                  headers:{
                    "content-type":"application/json",
                    "Authorization":localStorage.getItem("Authorization")
                  }
                })
                .then((resp) => {
                  
                  if(resp.status==401){
                    this.toggle()
                    localStorage.clear();
                  }
                  if(resp.status==200){
                    return resp.json();
                  }         }) 
                .then((response) => {
                  
                  if(response!=undefined){
                  this.setState({billingHistory : response.billings})
                  }
          
                })
                .catch((error) => {
                  console.log(error, "catch the loop")
                })
          }    


          UserhandleChange(e){
            var user_month = [];
              var user_value = [];
         
             var url =  baseUrl.URLPath + "web/statistics/users/"+this.state.userInternalId;
            //cordsetplusbapi.evsolutions.com/web/statistics/users/1b068375-9a1c-4668-a4a2-228e01c33f64
            var header = {'content-type': 'application/json',"Authorization":localStorage.getItem("Authorization")}

            fetch(url,{
              method:"GET",
              headers:header
            })
          .then((resp) => {
            if(resp.status==401){
              this.toggle();
              localStorage.clear();
            
            }
            if(resp.status==200){
              return resp.json();
            } 
           
          }) 
          .then((response) => {
            
           
            if(response!=undefined){
              console.log(response);
            
              
            for(var i=0; i<response.length; i++){
              user_month.push(response[i]["month"])
              user_value.push(response[i]["value"])
              
            }
           
            // this.setState({ 
            //   userData:user_month,
            //   userValue:user_value
            // })
              this.state.userValue = user_value;
              var max_val =  Math.max(...user_value);
                   
              if(max_val < 10){
                  this.state.userStep = {
                      min:0,
                      suggestedMax:10,
                     
                  }
              }else{
                    this.state.userStep = {
                      min:0,
                      suggestedMax:max_val,
                  }
              }
      
              // var monthName = new Array("Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sept", "Octr", "Nov", "Dec");
              // var d = new Date();
              // d.setDate(1);
              // var i=0;
              // for (i=0; i<=11; i++){
              //     this.state.yearmonthName.push(monthName[d.getMonth()] + ' ' + d.getFullYear().toString().substr(-2));
              //     d.setMonth(d.getMonth() - 1);
              // }
            
                //this.state.yearmonthName.reverse();
                
            
                this.state.dataBar =  {
                    labels:this.state.yearmonthName,
                    datasets: [
                      {
                        label:"kWh of Charged energy usage",
                        data:user_value.reverse(),
                        backgroundColor:"rgba(255, 134,159,0.4)",
                        borderWidth:2,
                        borderColor:"rgba(255, 134, 159, 1)"
                      }
                    ]
                  }
                  this.setState({usersData:response})
              //this.getMonthList()
            }
          })
          .catch((error) => {
            console.log(error, "catch the loop")
          }) 
          //this.getMonthList()
            }

            chargingHistory(){
              
            
              var url =  baseUrl.URLPath + "web/statistics/users/"+this.state.userInternalId;
              fetch(url,{
                  method: "GET",
                    headers:{
                      "content-type":"application/json",
                      "Authorization":localStorage.getItem("Authorization")
                    }
                  })
                  .then((resp) => {
                    
                    if(resp.status==401){
                      this.toggle()
                      localStorage.clear();
                    }
                    if(resp.status==200){
                      return resp.json();
                    }         }) 
                  .then((response) => {
                    
                    if(response!=undefined){
                    this.setState({chargingHistory : response})
                    }
            
                  })
                  .catch((error) => {
                    console.log(error, "catch the loop")
                  })
            }
            getMonthList(){
              var monthName = new Array("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec");
                    var d = new Date();
                    d.setDate(1);
                    var i=0;
                    for (i=0; i<=11; i++){
                        this.state.yearmonthName.push(monthName[d.getMonth()] + ' ' + d.getFullYear().toString().substr(-2));
                        d.setMonth(d.getMonth() - 1);
                    }
                  
                    this.state.yearmonthName = this.state.yearmonthName.reverse();
                     // this.state.userValue.reverse();
                  
                      this.state.dataBar =  {
                          labels:this.state.yearmonthName,
                          datasets: [
                            {
                              label: "kWh of Charged energy usage",
                              data:this.state.userValue.reverse(),
                              backgroundColor: "rgba(255, 134,159,0.4)",
                              borderWidth: 2,
                              borderColor:"rgba(255, 134, 159, 1)",
                              
                            }
                          ]
                        }
            
                this.state.dataLine= {
                  labels: this.state.yearmonthName,
                  datasets: [
                    {
                      label:"EVSE accumulated charged energy",
                      fill:true,
                      lineTension: 0.3,
                      backgroundColor: "rgba(225, 204,230, .3)",
                      borderColor: "rgb(205, 130, 158)",
                      borderCapStyle: "butt",
                      borderDash:[],
                      borderDashOffset: 0.0,
                      borderJoinStyle: "miter",
                      pointBorderColor: "rgb(205, 130,1 58)",
                      pointBackgroundColor: "rgb(255, 255, 255)",
                      pointBorderWidth: 10,
                      pointHoverRadius: 5,
                      pointHoverBackgroundColor: "rgb(0, 0, 0)",
                      pointHoverBorderColor: "rgba(220, 220, 220,1)",
                      pointHoverBorderWidth: 2,
                      pointRadius: 1,
                      pointHitRadius: 10,
                      data:this.state.allUserValue.reverse()
                    }
                  ]
                }
            }
            exportBillingHistoryCsv(){
             var url = baseUrl.URLPath + "web/billings/export/"+this.state.userInternalId;
             //cordsetplusbapi.evsolutions.com/web/billings/export/f1a85d44-6dd5-4194-9041-e59f5912f6cd
              var header = {'content-type':'application/json',"Authorization":localStorage.getItem("Authorization")}
              fetch(url,{
                method: "GET",
                headers:header 
                })
                .then((resp) => {
                  
                  if(resp.status==401){
                    this.togglesession();
                    localStorage.clear();
                  }
                  if(resp.status==200){
                    return resp.blob();
                  }
                }) 
                .then((response) =>{
                  
                  if(response!=undefined){
                      let d = new Date();
                      let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
                      let url = window.URL.createObjectURL(response);
                      let a = document.createElement('a');
                      a.href = url;
                      a.download = 'Billing_History_'+dformat+'.csv';
                      a.click();
                 }
                })
                .catch((error) => {
                    console.log(error, "catch the loop")
                })
            
            }
render() {

 
  const getBillingDetails = this.state.billingHistory.map((billingHistory, index) => {
          let chargedKwh=0;
          let rate=0;
          let billAmount=0;
          let paidAmount=0;
          let refundAmount=0;
          if(billingHistory.chargedKwh!="" && billingHistory.chargedKwh!=null && billingHistory.chargedKwh!=undefined){
            chargedKwh=parseFloat(billingHistory.chargedKwh).toFixed(2);
          }
          if(billingHistory.rate!="" && billingHistory.rate!=null && billingHistory.rate!=undefined){
            rate=parseFloat(billingHistory.rate).toFixed(2);
          }
          if(billingHistory.billAmount!="" && billingHistory.billAmount!=null && billingHistory.billAmount!=undefined){
            billAmount=parseFloat(billingHistory.billAmount).toFixed(2);
          }
          if(billingHistory.paidAmount!="" && billingHistory.paidAmount!=null && billingHistory.paidAmount!=undefined){
            paidAmount=parseFloat(billingHistory.paidAmount).toFixed(2);
          }
          if(billingHistory.refundAmount!="" && billingHistory.refundAmount!=null && billingHistory.refundAmount!=undefined){
            refundAmount=parseFloat(billingHistory.refundAmount).toFixed(2);
          }

          const sec = parseInt(billingHistory.chargedTime, 10); // convert value to number if it's string
          let hours   = Math.floor(sec / 3600); // get hours
          let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
          let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
          
          // add 0 if value < 10; Example: 2 => 02
          if (hours   < 10) {hours   = "0"+hours;}
          if (minutes < 10) {minutes = "0"+minutes;}
          if (seconds < 10) {seconds = "0"+seconds;}

    return(
      <tr>
      <td>{billingHistory.paymentDate}</td>
      <td>{chargedKwh}</td>
      <td>{hours+' : '+minutes+' : '+seconds}</td>
      <td>{rate}</td>
      {/* <td>{billingHistory.totalKwh}</td> */}
      <td>{billAmount}</td>
      <td>{paidAmount}</td>
      <td>{refundAmount}</td>
      <>
      {
        (billingHistory.status=="PaymentSuccess" || billingHistory.status=="Success" || billingHistory.status=="Session finished" || billingHistory.status=="Finished" || billingHistory.status=="Paid")? <td style={{color:"green"}}>{billingHistory.status} <img className="ml-1" src={process.env.PUBLIC_URL + "/assets/img/SuccessIcon.png"} alt="" style={{width:"0.9rem", height:"0.9rem", marginTop: "-3px"}} /></td>:
        (billingHistory.status=="PaymentFailed" || billingHistory.status=="Refund Failed")? <td style={{color:"red"}}>{billingHistory.status} <img className="ml-1" src={process.env.PUBLIC_URL + "/assets/img/FailedIcon.png"} alt="" style={{width:"0.9rem", height:"0.9rem", marginTop: "-3px"}} /></td>:
        (billingHistory.status=="Refund is Initiated")? <td style={{color:"blue"}}>{billingHistory.status} <img className="ml-1" src={process.env.PUBLIC_URL + "/assets/img/RefundIcon.png"} alt="" style={{width:"0.9rem", height:"0.9rem", marginTop: "-3px"}} /></td>: 
        <td>{billingHistory.status}</td>
      }
      </>
      {/* <td>{billingHistory.status}</td> */}
      {/* <td>{billingHistory.</td> */}
     
     </tr>    )
    })
    const getChargingHistoryDetails = this.state.chargingHistory.map((chargingHistory, index) => {


      // if(chargingHistory.month == null){
      //   var startTime = "";
      // }else{
      //   var startTime = moment(`${chargingHistory.month}`).format("MMM")
      // }
      var months = [ "January", "February", "March", "April", "May", "June", 
      "July", "August", "September", "October", "November", "December" ];
       var monthName = months[chargingHistory.month]

      return(
        <tr>
        <td>{chargingHistory.year}</td>
        <td>{monthName}</td>
        <td>{chargingHistory.count}</td>
        <td>{chargingHistory.value}</td>
        
       
       </tr>    )
      })


  return (
    <>
   
    <main class="content-div">
    <p>User History</p>

        <div className="page-outerdiv">
        <div  className="pull-right ">  
        <Link to ={{ 
          pathname:'/wallBoxUserList', 
          state:this.state.saveWallBoxNo}} >
          <button class="btn btn-primary m-0 btn-blue" type="button" onClick={this.backToList}> Back
          </button></Link></div>
       
        <div className="row mt-3 trans_lable">
           <div className="col-md-4">
            <b>Serial Number:</b> {this.state.saveWallBoxNo}
            </div>
        </div>
        <div className="row mt-3 trans_lable">
        <div className="col-md-4">
            <b>User Email:</b> {this.state.saveEmail}
            </div>
            </div>
        
          {/* end page menu bar  */}
          
          {/* table start */}
          <form
          className='needs-validation'
          // onSubmit={this.toggle1}
          noValidate
        >
            <MDBNav  className="nav-tabs mt-3">
          {/* <MDBNavItem>
            <MDBNavLink to="#" className={this.state.activeItem == "1" ? "activetab" : ""}   onClick={this.tabToggle.bind(this,"1")} role="tab" >
              Payment History
            </MDBNavLink>
          </MDBNavItem> */}
          <MDBNavItem>
            <MDBNavLink to="#" className={this.state.activeItem == "2" ? "activetab" : ""}  onClick={this.tabToggle.bind(this,"2")} role="tab" >
              Statistics
            </MDBNavLink>
          </MDBNavItem>
          {/* <MDBNavItem>
            <MDBNavLink to="#" className={this.state.activeItem == "3" ? "activetab" : ""}  onClick={this.tabToggle.bind(this,"3")} role="tab" >
             Charging History
            </MDBNavLink>
          </MDBNavItem> */}
      
        </MDBNav>
        <MDBTabContent activeItem={this.state.activeItem} className="pt-2" >
          {/* details */}
        {/* <MDBTabPane tabId="1" role="tabpanel">
        <div className="">
        <div className="row  trans_lable">
            
            <div className="col-md-9 mt-3">
              <label className="pull-left sel_lbl">Show entries</label>
                        <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left " >
                          <Form.Control as="select" className="form_select showentry_sel"  id="showAdminEntries" onChange={this.showAdminChange}> 
                            <option>10</option>
                            <option>20</option>
                            <option>30</option>
                            <option>40</option>
                            <option>50</option>
                          </Form.Control>
                        </Form.Group>
              </div>
              {
                (this.state.billingHistory.length>0)? 
                <div className="col-md-3  pull-right mt-2">
                     <MDBBtn color="primary accent-2  pull-right"  className="mt-2" style={{color:"#fff"}} onClick={this.exportBillingHistoryCsv} >
                        <i className="fas fa-file-export mr-2"></i>
                        Export
                     </MDBBtn>
                  </div>
                :null
              }
              
    
                
                
              </div>
          </div>
        <div className="row mt-2 ">
           <div className="col-md-12">
          <Table striped bordered hover size="sm" className="page-table">
            <thead>
              <tr>
                <th>Date/Time</th>
                <th>Charged (kWh)</th>
                <th>Charged Time</th>
                <th>Rate/kWh</th>
                
                <th>Bill ($)</th>
                <th>Paid ($)</th>
                <th>Refund ($)</th>
                 <th>Status</th>
                
              </tr>
            </thead>
            <tbody>
             {getBillingDetails}
              {
                (this.state.billingHistory.length == 0) ?
                  <tr>
                    <td colSpan="8" style={{ textAlign: "center" }}>No Data Available</td>
                  </tr> : null
              }
            </tbody>
          </Table>
          <Pagination
            hideDisabled
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.pageSize}
            totalItemsCount={this.state.noOfRecords}
            onChange={this.handlePageChange.bind(this)}
          />
          </div>
   
          
           //End table  
          </div>
        </MDBTabPane> */}

          {/* details */}
          <MDBTabPane tabId="2" role="tabpanel">
            <div className="row mt-2">
              {/* Graph Start*/}
              <div className="col-lg-6 col-md-6 mb-4 mt-2" id="graph4">
                <div className="card card-cascade narrower pb-3 content">
                  <div className="view view-cascade gradient-card-header warning-color">
                    <h5 className="mb-0">User Monthly Report</h5>
                  </div>
                  <div className="card-body card-body-cascade text-center">
                    <Bar
                      type='bar'
                      data={this.state.dataBar}
                      options={{
                        scales: {
                          yAxes: [{
                            scaleLabel: {
                              display: true,
                              labelString: 'Kw / h'
                            },
                            ticks: this.state.userStep
                          }],
                          xAxes: [{
                            scaleLabel: {
                              display: true,
                              labelString: 'Month'
                            }
                          }]
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* Graph End*/}
              {/* Charging History table start */}
              <div className="col-lg-6 col-md-6 mb-4 mt-2" id="charging_history_table">
                <div className="row mt-2 ">
                  <div className="col-md-12">
                    <Table striped hover size="sm" className="page-table">
                      <thead>
                        <tr>
                          <th>Year</th>
                          <th>Month </th>
                          <th>Count </th>
                          <th>Value (kWh)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getChargingHistoryDetails}
                        {
                          (this.state.chargingHistory.length == 0) ?
                            <tr>
                              <td colSpan="4" style={{ textAlign: "center" }}>No Data Available</td>
                            </tr> : null
                        }
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
              {/* Charging History table end */}
            </div>
          </MDBTabPane>
<MDBTabPane tabId="3" role="tabpanel">
        <div className="">
        <div className="row  trans_lable">
            
            {/* <div className="col-md-9 mt-3">
              <label className="pull-left sel_lbl">Show entries</label>
                        <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left " >
                          <Form.Control as="select" className="form_select showentry_sel"  id="showAdminEntries3" onChange={this.showAdminChange3.bind(this)}> 
                            <option>10</option>
                            <option>20</option>
                            <option>30</option>
                            <option>40</option>
                            <option>50</option>
                          </Form.Control>
                        </Form.Group>
              </div>
     */}
                {/* <div className="col-md-3 mt-1 pull-right">
                    <select className="browser-default custom-select select_height" id="inactiveId" onChange={this.adminsStatusChanged.bind(this)}>
                      <option value="">Select Status</option>
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </select>
                </div> */}
                
              </div>
          </div>
        <div className="row mt-2 ">
           <div className="col-md-12">
          <Table striped hover size="sm" className="page-table">
            <thead>
              <tr>
                <th>Year</th>
                <th>Month </th>
                <th>Count </th>
                <th>Value (kWh)</th>
               </tr>
            </thead>
            <tbody>
             {getChargingHistoryDetails}
             {
                (this.state.chargingHistory.length == 0) ?
                  <tr>
                    <td colSpan="4" style={{ textAlign: "center" }}>No Data Available</td>
                  </tr> : null
              }
            </tbody>
          </Table>
          {/* <Pagination
            hideDisabled
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.pageSize}
            totalItemsCount={this.state.noOfRecords}
            onChange={this.handlePageChange.bind(this)}
          /> */}
          </div>
   
          
          {/* End table  */}
          </div>
        </MDBTabPane>

        

          </MDBTabContent>
        
          </form>
          </div>
      </main>
      <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
      <MDBModalHeader toggle={this.toggle}>Session Expired</MDBModalHeader>
      <MDBModalBody>
       Please login again.
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn> */}
      <MDBBtn color="primary" onClick={this.tagOk}>OK</MDBBtn>
      </MDBModalFooter>
      </MDBModal>
 </>
  );
}
}

export default userHistory;

