import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import * as baseUrl from './commons/constants'
import $ from "jquery";
import { Alert } from 'reactstrap';

export default class CloudUserSignup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: { first_name: '', last_name: '', email: '', password: '', confirmPassword: '', phone: '' },
            sucess: false,
            errors: {},
            activeItem: "1",
            token: "",
            Usercapcha:"",
            capchaText:"",
            colour:"",
            message:""
        }
        this.handleChange=this.handleChange.bind(this);
        this.handleChangeCapcha=this.handleChangeCapcha.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
        this.isHaveLowerCase = this.isHaveLowerCase.bind(this);
        this.isHaveUpperCase = this.isHaveUpperCase.bind(this);
    }

    componentWillMount() {
        // window.history.pushState(null, null, window.location.href);
        // window.onpopstate = function () {
        //     window.history.go(1);
        // };
    }

    componentDidMount() {
        this.RefreshNewCapcha()
        $('#txtCaptcha').bind("copy", function (e) {
            e.preventDefault();
        });
        $('#cat').bind("paste", function (e) {
            e.preventDefault();
        });
    }
    
    handleChangeCapcha(field, e) {
        var usertext = e.target.value;
        this.setState({
            Usercapcha: usertext
        });
    }

    //Refresh Catpcha
    RefreshNewCapcha() {
        this.state.Usercapcha = "";
        const lengthOfCode = 5;
        this.state.capchaText = this.makeid(lengthOfCode);
        document.getElementById("txtCaptcha").value = this.state.capchaText;
    }

    makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    onShowAlert() {
        this.setState({ sucess: true }, () => {
            window.setTimeout(() => {
                this.setState({ sucess: false })
            }, 7000)
        });
    }

    isHaveLowerCase(string) {
        if (/[a-z]/.test(string) == true) {
            return true;
        } else {
            return false;
        }
    }

    isHaveUpperCase(string) {
        if (/[A-Z]/.test(string) == true) {
            return true;
        } else {
            return false;
        }
    }

    isHaveNumerics(string) {
        if (/[0-9]/.test(string) == true) {
            return true;
        } else {
            return false;
        }
    }

    /* handleSubmit:Used to perform Validations*/
    async handleSubmit(event) {
        event.preventDefault();

        // Variable 
        let errors = {};
        var email = this.state.fields['email'];
        var pwd = this.state.fields['password'];
        var first_name=this.state.fields['first_name'];
        var last_name=this.state.fields['last_name'];
        var cpwd=this.state.fields['confirmPassword'];
        var capcha = this.state.Usercapcha;
        var phone = this.state.fields['phone'];

        if(email != "" && email != undefined && email != null){
            email = email.trim();
        }
        if(pwd != "" && pwd != undefined && pwd != null){
            pwd = pwd.trim();
        }
        if(first_name != "" && first_name != undefined && first_name != null){
            first_name = first_name.trim();
        }
        if(last_name != "" && last_name != undefined && last_name != null){
            last_name = last_name.trim();
        }
        if(cpwd != "" && cpwd != undefined && cpwd != null){
            cpwd = cpwd.trim();
        }
        if(phone != "" && phone != undefined && phone != null){
            phone = phone.trim();
        }

        //if email and password is blank   

        if (email == '') {
            errors["emailempty"] = "E-mail is empty.";
        }
        if (capcha == "") {
            errors["captchaempty"] = "Please Enter Captcha.";
        }
        if(first_name == ""){
            errors["firstnameempty"] = "First Name is empty";
        }
        if(last_name == ""){
            errors["lastnameempty"] = "Last Name is empty";
        }

        if (pwd == '' && cpwd == '') {
            errors["passwordempty"] = "Password cannot be empty";
            errors["confirmpasswordempty"] = "confirm password cannot be empty";
        } else if (pwd == '') {
            errors["passwordempty"] = "Password cannot be empty";
        } else if (cpwd == '') {
            errors["confirmpasswordempty"] = "Confirm password cannot be empty";
        } else if (cpwd != pwd) {
            errors["confirmpasswordempty"] = "New password and confirm password doesn't match";
        } else if(pwd.trim().length < 8 ||
        /\d/.test(pwd) == false ||
        /[A-Z]/.test(pwd) == false ||
        /[a-z]/.test(pwd) == false ||
        /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(pwd) == false
        ){
            errors["confirmpasswordempty"] = "Password should be a minimum of 8 characters at least 1 Uppercase Alphabet, 1 Lowercase Alphabet, 1 Number, and 1 Special Character";
        } else if(pwd.trim().length > 20){
            errors["confirmpasswordempty"] = "Password should not exceed maximum 20 characters";
        }

        if(phone == ''){
            errors["phoneempty"] = "Phone Number must not be empty";
        }

        if (this.state.capchaText !== capcha) {
            errors["invalidCapcha"] = "Invalid Captcha.";
            this.RefreshNewCapcha();
        }

        //  you have to enter at least 6 digit!
        this.setState({
            errors: errors
        });

        if(errors.hasOwnProperty("emailempty") || errors.hasOwnProperty("captchaempty") || 
        errors.hasOwnProperty("firstnameempty") || errors.hasOwnProperty("lastnameempty") || 
        errors.hasOwnProperty("passwordempty") || errors.hasOwnProperty("confirmpasswordempty") || 
        errors.hasOwnProperty("invalidCapcha") || errors.hasOwnProperty("phoneempty")){

        }else{
            let url = baseUrl.APIPath + "user/cloudUsers/signup"; //url
            var email;
            let data = { "firstName":this.state.fields.first_name, "lastName":this.state.fields.last_name, "email": this.state.fields.email.split(' ').join(''), "password": this.state.fields.password, "mobileNumber": phone }; //data

            await fetch(url, {
                method: 'POST',
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(resp => {
                if (resp.status == 404) {
                    errors["invalidEmailPass"] = "E-mail id already exist"
                    this.setState({
                        errors: errors
                    });
                    this.RefreshNewCapcha();
                }
                else if (resp.status == 400) {
                    errors["invalidEmailPass"] = "E-mail id already exist"
                    this.setState({
                        errors: errors
                    });
                    this.RefreshNewCapcha();
                } else if (resp.status == 409) {
                    errors["invalidEmailPass"] = "E-mail id already exist"
                    this.setState({
                        errors: errors
                    });
                    this.RefreshNewCapcha();
                } else if (resp.status == 200) {
                    this.setState({})
                    this.onShowAlert();
                    this.setState({
                        fields: { first_name: '', last_name: '', email: '', password: '', confirmPassword: '' },
                        message:"Your Signup process is completed successfully. An email has been sent to the supplied email address.",
                        colour:'success',
                        Usercapcha:''
                    }, ()=>{
                        setTimeout(() => {
                            this.props.history.push('/');
                        }, 2000);
                    });
                    return resp.json();
                }
            }).then(response => {
                if (response != undefined) { }
            })
            .catch(
                error => alert('Error:' + error)
            );
        }
    }

    /* handleChange:Used to keep fields in state*/
    handleChange(field, e) {
        let value = e.target.value;
        let fields = this.state.fields;

        if (field == 'phone') {
            if (e.target.validity.valid) {
                fields[field] = value;
                this.setState({ fields });
            }
        } else if(field == 'password' || field == 'confirmPassword'){
            if(value?.trim().length <= 20){
                fields[field] = value.trim();
                this.setState({ fields });
            }
        } else {
            fields[field] = value;
            this.setState({ fields });
        }
    }
    
    render() {
        return (
            <>
            <script type="text/javascript" >
         function preventBack(){window.history.forward()}
         setTimeout("preventBack()", 0);
         window.onunload=function(){null};
       </script>
 
        <script language="JavaScript">
        javascript:window.history.forward(1);
        </script>
            <div className="login-page">

                {/* Navbar */}

                {/* satrt login page  */}
                <section className="intro-2" style={{position:"relative",cursor:"default"}}>
                    <div className="rgba-stylish-strong h-100 d-flex justify-content-center align-items-center" style={{top:"0", left:"0",bottom:"0",right:"0",overflowY:"scroll",width:"100%",backgroundAttachment:"fixed"}}>
                        <div className="container">
                            <form className="form-login" onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <div className="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-3">
                                        {/* Form with header */}
                                        <div className="card wow fadeIn animated" data-wow-delay="0.3s" style={{ visibility: 'visible', animationName: 'fadeIn', animationDelay: '0.3s' }}>
                                            <div className="card-body">
                                                {/* Header */}
                                                <div className="col-md-12 login_icon">
                                                    <img src={process.env.PUBLIC_URL + "/assets/img/webasto-logo-png-transparent.png"} alt="logo" style={{ width: "138px" }} />
                                                </div>
                                                <p class="h4 text-center mb-3 pb-3 pt-2 title_font"> Sign Up</p>
                                                <Alert color={this.state.colour} isOpen={this.state.sucess} className="mt-2" >{this.state.message}</Alert>
                                                {/* Body */}
                                                <div className="md-form">
                                                    <i className="fas fa-user prefix white-text" style={{top:"0.9rem"}} />
                                                    <input type="text" id="first_name" className="form-control pl-0" onChange={this.handleChange.bind(this, "first_name")} value={this.state.fields['first_name']} />
                                                    <label htmlFor="first_name">First Name*</label>
                                                    <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["firstnameempty"]}</span></p></span>
                                                    <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["entervalidfirstname"]}</span></p></span>
                                                </div>
                                                <div className="md-form">
                                                    <i className="fas fa-user prefix white-text" style={{top:"0.9rem"}} />
                                                    <input type="text" id="last_name" className="form-control pl-0" onChange={this.handleChange.bind(this, "last_name")} value={this.state.fields['last_name']} />
                                                    <label htmlFor="last_name">Last Name*</label>
                                                    <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["lastnameempty"]}</span></p></span>
                                                    <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["entervalidlastname"]}</span></p></span>
                                                </div>
                                                <div className="md-form">
                                                    <i className="fas fa-user prefix white-text" style={{top:"0.9rem"}} />
                                                    <input type="text" id="email" className="form-control pl-0" onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]} />
                                                    <label htmlFor="email">E-mail*</label>
                                                    <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["emailempty"]}</span></p></span>
                                                    <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["entervalidemail"]}</span></p></span>
                                                </div>
                                                <div className="md-form">
                                                    <i className="fas fa-user prefix white-text" style={{top:"0.9rem"}} />
                                                    <input type="text" id="phone" className="form-control pl-0" onChange={this.handleChange.bind(this, "phone")} value={this.state.fields["phone"]} pattern="[0-9+]*" maxLength={19} />
                                                    <label htmlFor="phone">Phone</label>
                                                    <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["phoneempty"]}</span></p></span>
                                                    <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["entervalidphone"]}</span></p></span>
                                                </div>
                                                <div className="md-form">
                                                    <i className="fas fa-lock prefix white-text" style={{top:"0.9rem"}} />
                                                    <input type="password" id="password" className="form-control " onChange={this.handleChange.bind(this, "password")} value={this.state.fields['password']} />
                                                    <label htmlFor="password">Password*</label>
                                                    <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }} >{this.state.errors["passwordempty"]}</span></p></span>
                                                    <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["entervalidpwd"]}</span></p></span>
                                                    <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["active"]}</span></p></span>
                                                </div>
                                                <div className="md-form">
                                                    <i className="fas fa-lock prefix white-text" style={{top:"0.9rem"}} />
                                                    <input type="password" id="confirmPassword" className="form-control " onChange={this.handleChange.bind(this, "confirmPassword")} value={this.state.fields['confirmPassword']} />
                                                    <label htmlFor="confirmPassword">Confirm Password*</label>
                                                    <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }} >{this.state.errors["confirmpasswordempty"]}</span></p></span>
                                                    <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["entervalidconfirmpwd"]}</span></p></span>
                                                    <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["active"]}</span></p></span>
                                                    <span className="error_msg1 w3-animate-top"> <p> <span style={{color: "red"}}>{this.state.errors["invalidEmailPass"]}</span></p></span> 
                                                </div>
                                                <div class="row mt-2">
                                                    <div class="col-md-12 mb-2"><span for="" class="capcha_label">Captcha</span></div>
                                                    <div class=" col-md-12  col-xs-6" >

                                                        <input type="text" id="txtCaptcha" readonly="true" class="input_captcha" />
                                                        <i class="fa fa-refresh cursor" title="Refresh Captcha" aria-hidden="true" onClick={this.RefreshNewCapcha} className="fas fa-refresh refresh" ></i>
                                                        <input type="text" id="password" autocomplete="off" className="form-control refres_lbl" name="cat" maxlength="10" placeholder="Enter the Captcha" onChange={this.handleChangeCapcha.bind(this, "captch")} value={this.state.Usercapcha} />
                                                        {/* <input type="text" class="form-control"  className="" />  */}

                                                        <span className="error_msg1 w3-animate-top mt-2 mb-2" ><p><span style={{ color: "red" }} > {this.state.errors["invalidCapcha"]} </span></p></span>
                                                    </div>
                                                </div>
                                                <div className="text-center mt-1">
                                                    <button type='submit' className="btn login-btn btn-lg waves-effect waves-light" onSubmit={this.handleSubmit}>Sign Up</button>
                                                    <p className="mt-2" />
                                                </div>
                                                <div className="inline-ul text-center d-flex justify-content-center">
                                                    Already have an account? &nbsp;<Link className="txt2" to={{pathname:'/'}}>Login</Link>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Form with header */}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
            </>
        );
    }
}