import React from 'react';
import { Link } from 'react-router-dom';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import $ from 'jquery';
import * as baseUrl from './commons/constants';
import '../App.css';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalactivate: false,
      fields: { email: '', pwd: '' },
      sucess: {},
      errors: {},
      data: [],
      activeItem: "1",
      captchaMatchStatus: false,
      Usercapcha: "",
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.makeid = this.makeid.bind(this);
    this.RefreshNewCapcha = this.RefreshNewCapcha.bind(this);
    this.handleChangeCapcha = this.handleChangeCapcha.bind(this);
    this.toggleactivate = this.toggleactivate.bind(this);
    this.accountActivate = this.accountActivate.bind(this);
    this.toggleactivesuccess = this.toggleactivesuccess.bind(this);
    this.closeOkPopup = this.closeOkPopup.bind(this);
  }

  closeOkPopup() {
    this.props.history.push('/');
  }

  toggleactivesuccess = () => {
    this.setState({
      modalactivesuccess: !this.state.modalactivesuccess
    });
  }

  async toggleactivate() {
    this.setState({
      modalactivate: !this.state.modalactivate
    });
  }

  /* handleSubmit:Used to perform Validations*/
  async handleSubmit(event) {
    localStorage.clear();
    event.preventDefault();

    // Variable 
    let errors = {};
    var email = this.state.fields['email'];
    var pwd = this.state.fields['pwd'];
    var capcha = this.state.Usercapcha;
    errors["emailempty"] = "";
    errors["entervalidpwd"] = "";
    errors["passwordempty"] = "";
    errors["entervalidemail"] = "";
    errors["invalidEmailPass"] = "";
    errors["invalidCapcha"] = "";

    //if email and password is blank   
    if (email == '') {
      errors["emailempty"] = "E-mail is empty.";
    }
    if (pwd == "") {
      errors["passwordempty"] = "Password is empty.";
    }
    if (capcha == "") {
      errors["captchaempty"] = "Please Enter Captcha.";
    }
    if (this.state.capchaText !== capcha) {
      errors["invalidCapcha"] = "Invalid Captcha.";
      this.RefreshNewCapcha();
    }
    //  you have to enter at least 6 digit!
    this.setState({
      errors: errors
    });
    //if email and password is not blank and email is in wrong format   
    if (email != '' && pwd != '' && capcha != '' && this.state.capchaText == capcha) {
      // after entering proper email-id and password 
      let url = baseUrl.LoginPath + "/login/v1.0/"; //url
      var email;
      let data = { "email": this.state.fields.email.toLowerCase(), "password": this.state.fields.pwd, "tenant": "webasto" }; //data
      await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(resp => {
        if (resp.status == 404) {
          errors["invalidEmailPass"] = "Your E-mail / password is incorrect."
          this.setState({
            errors: errors
          });
          this.RefreshNewCapcha();
        }

        if (resp.status == 404) {
          errors["invalidEmailPass"] = "Your E-mail / password is incorrect."
          this.setState({
            errors: errors
          });
          this.RefreshNewCapcha();
        }
        else if (resp.status == 400) {
          errors["invalidEmailPass"] = "Your E-mail / password is incorrect."
          this.setState({
            errors: errors
          });
          this.RefreshNewCapcha();
        } else {
          return resp.json();
        }
      }).then(response => {
        if (response != undefined) {
          localStorage.setItem("csOperation", response.enableCSOperation);
          localStorage.setItem("broker", response.enableBroker);
          localStorage.setItem("saveConnector", response.enableConnector);
          localStorage.setItem("vehicle", response.enableVehicle);
          localStorage.setItem("role", "response.role");
          localStorage.setItem("cronJobStatus", response.enableCronJob);
          localStorage.setItem("emailNotificationStatus", response.enableEmailNotification);
          localStorage.setItem("activeAuthorization", "Bearer " + response.token);
          localStorage.setItem("saveInventory", response.enableInventoryUpdate)
          localStorage.setItem("enableCPImport", response.enableCPImport);

          if (response.status == "FAILURE") {
            if (response.errorMessage == "You must change your password.") {
              this.getUserDetails(response.token, "updatepassword")
            }
            if (response.errorMessage == "Your account is not activated") {
              errors["active"] = "Your account is not activated.";
              this.toggleactivate();
              this.setState({
                errors: errors
              });
            }
            else {
              errors["invalidEmailPass"] = response.errorMessage
              this.setState({
                errors: errors
              });
              this.RefreshNewCapcha()
            }
          } else {
            if (response.active == "0") {
            } else {
              this.getUserDetails(response.token);
              localStorage.setItem("roleAccess", response.roleAccess);
            }
          }
        }
      }).catch(
        error => alert('Error:' + error)
      );
    }
  }

  getUserDetails(token, method_name) {
    var url = baseUrl.URLPath + "v1.0/user/me"
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": "Bearer " + token
      }
    })
      .then((resp) => {
        return resp.json();
      })
      .then((response) => {
        this.setState({ data: response });
        localStorage.setItem("accountId", this.state.data.accountId);
        localStorage.setItem("email", this.state.data.email);
        localStorage.setItem("firstName", this.state.data.firstName);
        localStorage.setItem("user", this.state.data.firstName);
        localStorage.setItem("lastName", this.state.data.lastName);
        localStorage.setItem("role", this.state.data.role);
        localStorage.setItem("tenant", this.state.data.tenant);
        localStorage.setItem("UserTenant", this.state.data.tenant);
        localStorage.setItem("userId", this.state.data.userId);
        localStorage.setItem("Authorization", "Bearer " + token);

        if (method_name == "updatepassword") {
          this.props.history.push({
            pathname: '/updatepassword',
            state: { syspassword: this.state.fields.pwd }
          })
        }else {
          this.props.history.push('/wallboxList');
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error(error, "catch the loop")
      })
  }

  UNSAFE_componentWillMount() {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
  }

  componentDidMount() {
    this.RefreshNewCapcha()
    $('#txtCaptcha').bind("copy", function (e) {
      e.preventDefault();
    });
    $('#cat').bind("paste", function (e) {
      e.preventDefault();
    });

    let token = localStorage.getItem("Authorization");
    if(token!='' && token!=null && token!=undefined){
      this.props.history.push('/wallboxList');
      window.location.reload();
    }
  }

  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  handleChangeCapcha(field, e) {
    var usertext = e.target.value;
    this.setState({
      Usercapcha: usertext
    });
  }

  //Refresh Catpcha
  RefreshNewCapcha() {
    this.state.captchaMatchStatus = false;
    this.state.Usercapcha = "";
    const lengthOfCode = 5;
    this.state.capchaText = this.makeid(lengthOfCode);
    document.getElementById("txtCaptcha").value = this.state.capchaText;
  }

  /* handleChange:Used to keep fields in state*/
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  accountActivate() {
    let errors = {};
    this.setState({
      modalactivate: !this.state.modalactivate
    });
    var url = baseUrl.URLPath + "v1.0/user/account/activate";
    fetch(url,
      {
        method: 'PUT', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          "Authorization": localStorage.getItem("activeAuthorization"),
        }
      }
    ).then(resp => {
      if (resp.status == 200) {
        this.setState({
          modalactivesuccess: !this.state.modalactivesuccess
        });
      }
      else if (resp.status == 401) {
      }
      else if (resp.status == 404) {
        this.setState({ errors: errors });
        return resp.json();
      }
      else if (resp.status == 500) {
        alert("internal server error")
      } else if (resp.status == 400) {
        alert("Bad request")
      }
      else { }
    })
      .catch(error => alert('Error:' + error));
    this.setState({ errors: errors });
  }

  render() {
    return (
      <>
        <div className="login-page">
          {/* Navbar */}
          {/* satrt login page  */}
          <section className="view intro-2">
            <div className="mask rgba-stylish-strong h-100 d-flex justify-content-center align-items-center">
              <div className="container">
                <form className="form-login" onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5">
                      {/* Form with header */}
                      <div className="card wow fadeIn animated" data-wow-delay="0.3s" style={{ visibility: 'visible', animationName: 'fadeIn', animationDelay: '0.3s' }}>
                        <div className="card-body">
                          {/* Header */}
                          <div className="col-md-12 login_icon">
                            <img src={process.env.PUBLIC_URL + "/assets/img/webasto.png"} style={{ width: "138px" }} alt="" />
                          </div>
                          <p className="h4 text-center mb-3 pb-3 pt-2 title_font"> Sign in</p>
                          {/* Body */}
                          <div className="md-form">
                            <i className="fas fa-user prefix white-text" />
                            <input type="text" id="email" className="form-control pl-0 text_lowercase" onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]} />
                            <label htmlFor="email" className={(this.state.fields['email'] != "" && this.state.fields["email"] != null) ? "active" : ""}>E-mail</label>
                            <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["emailempty"]}</span></p></span>
                            <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["entervalidemail"]}</span></p></span>
                          </div>
                          <div className="md-form">
                            <i className="fas fa-lock prefix white-text" />
                            <input type="password" id="password" className="form-control" onChange={this.handleChange.bind(this, "pwd")} value={this.state.fields["pwd"]} autoComplete="off" />
                            <label htmlFor="password" className={(this.state.fields['password'] != "" && this.state.fields["password"] != null) ? "active" : ""}>Password</label>
                            <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }} >{this.state.errors["passwordempty"]}</span></p></span>
                            <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["entervalidpwd"]}</span></p></span>
                            <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["active"]}</span></p></span>
                            <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["invalidEmailPass"]}</span></p></span>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-12 mb-2"><span htmlFor="" className="capcha_label">Captcha</span></div>
                            <div className=" col-md-12  col-xs-6" >
                              <input type="text" id="txtCaptcha" readOnly={true} className="input_captcha" />
                              <i className="fa fa-refresh cursor" title="Refresh Captcha" aria-hidden="true" onClick={this.RefreshNewCapcha} className="fas fa-refresh refresh" ></i>
                              <input type="text" id="password" autoComplete="off" className="form-control refres_lbl" name="cat" id="cat" maxLength={5} placeholder="Enter the Captcha" onChange={this.handleChangeCapcha.bind(this, "captch")} value={this.state.Usercapcha} />
                              <span className="error_msg1 w3-animate-top mt-2 mb-2" ><p><span style={{ color: "red" }} > {this.state.errors["invalidCapcha"]} </span></p></span>
                            </div>
                          </div>
                          <div className="text-center mt-2">
                            <button type='submit' className="btn login-btn btn-lg waves-effect waves-light" onSubmit={this.handleSubmit}>Sign In</button>
                            <p className="mt-2" />
                            <div className="inline-ul text-center d-flex justify-content-center">
                              <Link className="txt2" to={{
                                pathname: '/cloudUserSignup'
                              }}>
                                Cloud User SignUp
                              </Link>
                              <pre className="txt2" style={{ color: "#007bff" }}> | </pre>
                              <Link className="txt2" to={{
                                pathname: '/forgotPassword',
                                state: this.state.fields["email"]
                              }}>
                                Forgot password?
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Form with header */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
        {/* end  login page  */}

        {/* popup for confirma account activate */}
        <MDBModal isOpen={this.state.modalactivate} toggle={this.toggleactivate} size="md" className="model_active">
          <MDBModalHeader toggle={this.toggleactivate}>Activate Account</MDBModalHeader>
          <MDBModalBody>
            Your account is deactivated, Are you sure you want to activate it?
          </MDBModalBody>
          <MDBModalFooter>
          <button onClick={this.toggleactivate} className="btn_cancel" type="button" data-test="button"> Cancel <i class="fas fa-times ml-2"></i></button>
          <button className="btn_primary" onClick={this.accountActivate} type="button" data-test="button">Yes</button>
          </MDBModalFooter>
        </MDBModal>

        {/* popup for sucessfull activate */}
        <MDBModal isOpen={this.state.modalactivesuccess} toggle={this.toggleactivesuccess} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggleactivesuccess}>Success</MDBModalHeader>
          <MDBModalBody>
            Account activated successfully.
          </MDBModalBody>
          <MDBModalFooter>
          <button className="btn_primary" onClick={this.handleSubmit} type="button" data-test="button">OK</button>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
};

export default Login;