import React from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn } from 'mdbreact';
import { Table } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import moment from 'moment';
import * as baseUrl from './commons/constants';

class InventoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      model: false,
      options: [],
      multiple: false,
      admins: [],
      from_dbdate: "",
      categoryData: "initialData",
      to_dbdate: "",
      admin: [],
      intial_data: [],
      startDate: '',
      toDate: '',
      page_title: "Inventory",
      mac_total: "",
      items: [],
      macIdData: [],
      noOfRecords: '',
      data: new Map(),
      pageSize: "10",
      activePage: 1,
      modal: false
    }
    this.setStartDate = this.setStartDate.bind(this);
    this.setToDate = this.setToDate.bind(this);
    this.serialnoChange = this.serialnoChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.toggle = this.toggle.bind(this);
    this.tagOk = this.tagOk.bind(this);
  }

  tagOk() {
    this.props.history.push('/');
    window.location.reload();
  }

  toggle = (boolean) => {
    this.setState({
      modal: boolean
    });
  }

  serialnoChange(mac_address) {
    if (mac_address.length == 0) {
      this.setState({ admins: this.state.admin });
      this.setState({
        mac_total: "",
      })
      this.setState({
        startDate: "",
        toDate: "",
        from_dbdate: "",
        to_dbdate: "",
        options: []
      })
      this.getCount();
      this.setState({
        activePage: 1
      })
      this.getAdmin(1, this.state.pageSize);
    } else {
      this.setState({
        startDate: "",
        toDate: ""
      })
      this.getSearchMacIdData(mac_address);
    }
  }

  handlePageChange(pageNumber) {
    if (pageNumber != this.state.activePage) {
      var data = this.state.data;
      var pageData = data.get(pageNumber);
      if (pageData == undefined) {
        this.setState({ activePage: pageNumber });
        if (this.state.categoryData == "initialData") {
          this.getAdmin(pageNumber, this.state.pageSize);
        } else {
          this.macData(pageNumber, this.state.pageSize);
        }
      } else {
        this.setState({ activePage: pageNumber });
        this.setState({ admins: pageData });
      }
    }
  }

  setStartDate(date) {
    var date_s = moment(date).format("YYYY-MM-DD");
    this.state.from_dbdate = date_s;
    this.setState({
      startDate: date,
      from_dbdate: date_s,
      activePage: 1
    })
    this.macData(1, this.state.pageSize);
  }

  setToDate(date) {
    var date_s = moment(date).format("YYYY-MM-DD");
    this.state.to_dbdate = date_s;
    this.setState({
      toDate: date,
      to_dbdate: date_s,
      activePage: 1
    })
    this.macData(1, this.state.pageSize);
  }

  macData(pageNo, pageSize) {
    var startDate = this.state.from_dbdate;
    var toDate = this.state.to_dbdate;
    if (toDate != "" && startDate != "" && toDate != "Invalid date" && startDate != "Invalid date") {
      var url = baseUrl.APIPath + "inventory?startDate=" + startDate + "&endDate=" + toDate + "&pageNo=" + pageNo + "&pageSize=" + pageSize;
      fetch(url, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization")
        }
      })
        .then((resp) => {
          if (resp.status == 401) {
            this.toggle(true);
          }
          if (resp.status == 200) {
            return resp.json();
          }
        })
        .then((response) => {
          if (response != undefined) {
            this.setState({ admins: response });
            const array_count = this.state.admins.length;
            this.setState({
              mac_total: array_count,
              categoryData: "filterData",
            })
            this.getFilterCount(startDate, toDate);
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop");
        })
    }
  }

  // getDateFilter Count
  getFilterCount(startDate, toDate) {
    var url = baseUrl.APIPath + "inventory/count?startDate=" + startDate + "&endDate=" + toDate;
    fetch(url, {
      method: "get",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle(true);
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({
            noOfRecords: response.count
          })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop");
      })
  }

  clearFilter(ref, event) {
    this.setState({
      mac_total: "",
    })
    this.setState({
      startDate: "",
      toDate: "",
      from_dbdate: "",
      to_dbdate: "",
      options: []
    })
    ref.current.clear();
    this.getCount();
    this.setState({
      activePage: 1
    })
    this.getAdmin(1, this.state.pageSize);
  }

  handleKeyDown(e) {
    var serial_no = e.target.value;
    var key = e.key;
    var list = this.state.intial_data;
    if (key == "Backspace" || key == "Delete") {
      var serial_no = e.target.value;
      if (serial_no.length <= 1) {
        list = [];
        this.setState({
          options: list,
        });
      }
    } else {
      if (serial_no.length >= 0) {
        if (serial_no.length > 2) {
          this.getMacIdDataList(serial_no)
        }
      }
    }
  }

  getMacIdDataList(macId) {
    var url = baseUrl.APIPath + "inventory/search?macId=" + macId;
    fetch(url, {
      method: "get",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle(true);
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ macIdData: response });
          var option = [];
          for (var i = 0; i < this.state.macIdData.length; i++) {
            option.push(this.state.macIdData[i].mac_address);
          }
          this.setState({
            intial_data: option,
            options: option
          });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  getSearchMacIdData(macId) {
    var url = baseUrl.APIPath + "inventory/macid/" + macId;
    fetch(url, {
      method: "get",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle(true);
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          var data = [];
          data.push(response)
          this.setState({ admins: data, noOfRecords: 0 });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  async getAdmin(pageNo, pageSize) {
    var url = baseUrl.APIPath + "inventory?pageNo=" + pageNo + "&pageSize=" + pageSize;
    await fetch(url, {
      method: "get",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle(true);
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ admins: response });
          this.setState({
            admin: response,
            categoryData: "initialData"
          });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop");
      })
  }

  componentDidMount() {
    document.getElementById("loader_image_div").style.display = "block";
    this.getCount();
    this.getAdmin(this.state.activePage, this.state.pageSize);
    setTimeout(() => {
      document.getElementById("loader_image_div").style.display = "none";
    }, 1000);
  }

  getCount() {
    var url = baseUrl.APIPath + "inventory/count"
    fetch(url, {
      method: "get",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle(true);
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ noOfRecords: response.count });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  // get the all list of inventory page 
  render() {
    const ref = React.createRef();
    var usersoption = "";
    if (this.state.intial_data.length != 0) {
      usersoption = this.state.intial_data.map((item, index) => {
        console.log(item);
        return (
          <option value={index} data-id={item.id}> {item.mac_address} </option>
        )
      })
    }
    var count_mac = "";
    if (this.state.mac_total != "") {
      count_mac = "Count: " + this.state.mac_total;
    }
    return (
      <>
        <main class="content-div">
            <p>Inventory</p>
          <div className="page-outerdiv">
            <div className="row mt-4">
              <div className="col-md-3 pull-left">
                <Typeahead
                  labelKey="name"
                  className="auto_suggestion"
                  multiple={this.state.multiple}
                  options={this.state.options}
                  placeholder="Choose a MAC Id..."
                  onChange={this.serialnoChange.bind(this)}
                  ref={ref}
                  onKeyDown={this.handleKeyDown.bind(this)}
                />
              </div>
              <div className="col-md-9">
                <div className="pull-left">
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={date => this.setStartDate(date)}
                    timeIntervals={1}
                    className="cal_margin mt-2 mr-2 text-capitalize"
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy"
                    placeholderText="From Date"
                    maxDate={this.state.toDate}
                  />
                </div>
                <div className="pull-left cal_space" style={{ position: "relative", top: "14px", left: "3px" }}> : </div>
                <div className="pull-left">
                  <DatePicker
                    selected={this.state.toDate}
                    onChange={date => this.setToDate(date)}
                    timeIntervals={1}
                    className="cal_margin mt-2 mr-2 pull-left text-capitalize"
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy"
                    placeholderText="To Date"
                    minDate={this.state.startDate}
                  />
                </div>
                <MDBBtn color="blue-grey" className="mt-2 clr_w" onClick={this.clearFilter.bind(this, ref)} >Clear</MDBBtn>
              </div>
            </div>
            {/* end page menu bar  */}

            {/* table start */}
            <div className="row mt-2 ">
              <div className="col-md-12">
                <label className="pull-right count_label"> {count_mac} </label>
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th>MAC Id</th>
                      <th>PIN</th>
                      <th>Updated Date</th>
                    </tr>
                  </thead>
                  <tbody className="vertical-center" >
                    {this.state.admins.map((admins, index) => {
                      if (admins.mac_address != "") {
                        var date = moment(admins.createdTime).format("DD-MMM-YYYY HH:mm").toUpperCase();
                        return (
                          <tr>
                            <td>{admins.mac_address}</td>
                            <td>{admins.pin}</td>
                            <td>{date}</td>
                          </tr>
                        )
                      }
                    })}
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12">
                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
              {/* End table  */}
            </div>
          </div>
        </main>
        <div className="loader_img" id="loader_image_div" style={{ display: "none" }}>
          <img src={process.env.PUBLIC_URL + "/assets/img/ajax-loading-gif-transparent-background-1.gif"} style={{ width: "138px" }} alt="" />
        </div>
        <MDBModal isOpen={this.state.modal} size="md" className="model_top">
          <MDBModalHeader>Session has expired</MDBModalHeader>
          <MDBModalBody>
            Please login again.
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={this.tagOk}>OK</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default InventoryList;