import React from 'react';
import { MDBBtn,MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter} from 'mdbreact';
import * as baseUrl from './commons/constants';
import Datetime from 'react-datetime';
import {Link} from 'react-router-dom';
import { hasPermission } from './commons/auth';

class viewOrganization extends React.Component{  
    constructor (props) {
        super(props);
        this.state = {
            fields:{},
            expdate:'',
            sucess:{},
            errors:{},
            disabled:"true",
            id:"",
            activeItem:"1",
            isChecked:"1",
            startDate1:"",
            startDate:new Date(),
            model:"false",
            model1:"false",
            par_id_tag:"",
            page_title:"View Organization",
            modalsession:false,
            user:{
              permissions: [
                  
              ],
          }

        }
         this.editOrgnization = this.editOrgnization.bind(this)
         this.backToList = this.backToList.bind(this)
         this.deleteOrgnization = this.deleteOrgnization.bind(this)
         this.toggle = this.toggle.bind(this)
         this.toggleConfirmation = this.toggleConfirmation.bind(this)
         this.closeOkPopup = this.closeOkPopup.bind(this)
         this.togglesession = this.togglesession.bind(this)
         
}

toggle = () => {
  this.setState({
    modal: !this.state.modal
  });
  
}

toggleConfirmation = () => {
  this.setState({
    modal1: !this.state.modal1
  });
}

async togglesession(){
  
  this.setState({
  modalsession: !this.state.modalsession
  });
}


sessiontagOk(){
  this.props.history.push('/');
  window.location.reload();
}

closeOkPopup(){
  this.setState({
    modal: !this.state.modal
  });
  this.props.history.push('/organisationList');
}

 backToList(){
  this.props.history.push('/organisationList');
}
//set date to input of datetime picker 
setStartDate1(date,event){
  // 
  // let fields1 = this.state;
  // fields1.expdate = event;
  // var tempDate = new Date(event);
  // var date1 = (tempDate.getMonth()+1) + '-' + tempDate.getDate() + '-' + tempDate.getFullYear() +' '+ tempDate.getHours()+':'+ tempDate.getMinutes()+':'+ tempDate.getSeconds();
  // const currDate = "Current Date= "+date1;
  // this.state.startDate1 = date1; 
  // this.setState({expdate:fields1.expdate})
}

setStartDate(date){
  // this.setState({startDate:date});
  // var tempDate = new Date(date);
  // var date1 = (tempDate.getMonth()+1)  + '-' + tempDate.getDate() + '-' + tempDate.getFullYear() +' '+ tempDate.getHours()+':'+ tempDate.getMinutes();
  // this.setState({startDate1:date1});
 }

setValue(){

}
handleChange = date => {
this.setState({startDate:date});
  //var tempDate = new Date(date);
var currentDate = new Date(date);
var twoDigitMonth=((currentDate.getMonth()+1)>=10)? (currentDate.getMonth()+1) : '0' + (currentDate.getMonth()+1);  
var twoDigitDate=((currentDate.getDate())>=10)? (currentDate.getDate()) : '0' + (currentDate.getDate());
var twominDate=((currentDate.getMinutes())>=10)? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
var twohoursDate=((currentDate.getHours())>=10)? (currentDate.getHours()) : '0' + (currentDate.getHours());
var createdDateTo =   twoDigitMonth + "-" + twoDigitDate+ "-" +currentDate.getFullYear() +' '+ twohoursDate +':'+ twominDate;
this.setState({startDate1:createdDateTo});
};

componentDidMount(){
  
  this.state.user.permissions = localStorage.getItem("roleAccess");

  var timeZone=  localStorage.getItem("timezone");
    var orgid = this.props.location.state;
   // var array = url.split('/');
    this.state.id = orgid;
     var url = baseUrl.URLPath + "admin/v1.0/account/" + orgid;
     //localhost:8080/cpms/ocpptags/{ocppTagId}
    fetch(url,{
        method: "GET",
        headers: {
          "content-type":"application/json",
          "Authorization":localStorage.getItem("Authorization")
         }
        })
        .then((resp) => {
          if(resp.status==401){
            // alert("Access token is expired, please login again")
            this.togglesession();
      
            localStorage.clear();
           
          }
          if(resp.status==200){
            return resp.json();
          }
        }) 
        .then((response) =>{
          if(response!=undefined){
          var data1 = [] ;
          data1= response.organisationDTOS;
          this.setState({
            fields:data1[0]
          })
        }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
     })
    }
    redirectToListPage(){
    this.props.history.push('/organisationList')
    }

    editOrgnization(){
        this.props.history.push('./organisationEdit');
    }

   // Delete Orgnization 
   deleteOrgnization(){
     
    this.setState({
      modal1: !this.state.modal1
    });
    let errors = {};
    let url = baseUrl.URLPath + "admin/v1.0/account/" + this.state.id; 
    fetch(url, 
          {
            method: 'DELETE', // or 'PUT'
            body: JSON.stringify("NULL"), // data can be `string` or {object}!
            headers:{
                  'Content-Type': 'application/json',
                  "Authorization":localStorage.getItem("Authorization"),
                }
          }
        ).then(resp =>{
          
          if(resp.status==401){
            this.togglesession();
            // alert("Access token is expired, please login again")
            localStorage.clear();
          }
          else if(resp.status==404){
              this.setState({errors: errors});
              return resp.json();
            }
            else if(resp.status==500){
              alert("internal server error")
            } else if(resp.status==400){
              alert("Bad request")
            }else{
              this.setState({
                modal: !this.state.modal
              });
             // this.props.history.push('/organisationList');
              // $("#suc_msg").html(' <strong>Success!</strong>Charge Point Tag Id deleted successfully.').slideDown();
              // setTimeout(this.redirectToListPage, 3000);
            }
      })
       .catch(error => alert('Error:' + error));
       this.setState({errors: errors});
  }


render() {
        let user = this.state.user;

  var yesterday = Datetime.moment().subtract( 1, 'day' );
  var valid = function( current ){
      return current.isAfter( yesterday );
  };
return(
  <>
   
<main class="content-div" >
<p> Organization
              <div className="breadcrumb_div">
              Settings &gt; <Link to="/organisationList">Organization</Link > &gt; <span className="breadcrumb_page">View</span>
              </div>
          </p>
      <div className="page-outerdiv">
        {/* breadCrumb */}
        {/* <div className="breadcrumb_div"><a>Settings > <Link to="/organisationList" > <span className="">Organization</span></Link> ></a>View</div> */}
        {/* end breadCrumb */}
        <div className="row">
          <div className="col-md-12">
            <div className="pull-left">
            <button className="btn_primary" onClick={this.backToList}><i class="fa fa-angle-left mr-2" aria-hidden="true"></i> Back</button>
            </div>
          </div>
        </div>
        <form
          className='needs-validation'
          onSubmit={this.toggle1}
          noValidate
        >
          <div className="row mb-4 mt-3">
            <div className="col-sm-6">
            <div className="content-innerdiv">
            <div className="" >
                 <label>
                 Organization:
                 </label>
                 <label >
                 {this.state.fields["company"]}
                 {/* onChange={this.changeHandler.bind(this, "idTag")} */}

                 </label>
               </div>
               <div className="">
                 <label>
                 Office Address:
                 </label>
                 <label>
                 {this.state.fields["officeAddress"]}
                 </label>               
               </div>
               <div className=" ">
                 <label>
                 State
                 </label>
                 <label>
                   {this.state.fields.state}
                 </label>
               </div>
               <div className="">
                 <label>
                 Url:
                 </label>
                 <label>
                 {this.state.fields["url"]}
                 </label>
               </div>
            </div>
            </div>
            <div className="col-sm-6">
              <div className="content-innerdiv">
              <div className="" >
                 <label>
                 Short Name:
                 </label>
                 <label >
                 {this.state.fields["identifier"]}
                 {/* onChange={this.changeHandler.bind(this, "idTag")} */}
                 </label>
               </div>
               <div className=" ">
                 <label>
                 City:
                 </label>
                 <label>
                 {this.state.fields.city}
                 </label>
               </div>
               <div className="">
                 <label>
                 Country
                 </label>
                 <label>
                 {this.state.fields.country}
                 </label>
               </div>
              </div>
            </div>
          <div className="col-md-12 text-right">
          { hasPermission(user, ['Organizations_W']) &&
              <Link to={{
              pathname:'/organisationEdit', 
              state: this.state.id, 
              }}>
                <button className="refresh-btn mt-5 mr-3" onClick={this.editOrgnization}  id="editBtn">Edit <i class="fas fa-pencil-alt ml-2"></i></button>
            {/* <MDBBtn color="primary" className="mt-5 mr-3" onClick={this.editOrgnization}  id="editBtn" >
           Edit
        </MDBBtn> */}

        </Link>}
          </div>
          </div>
        </form>
      </div>
    </main>


    <MDBModal isOpen={this.state.modal1} toggle={this.toggleConfirmation} size="md" className="model_top">
      <MDBModalHeader toggle={this.toggleConfirmation}>Confirmation</MDBModalHeader>
      <MDBModalBody>
      Are you sure you want to delete?
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn> */}
      <MDBBtn color="danger" onClick={this.toggleConfirmation}>CLOSE</MDBBtn>
      <MDBBtn color="primary" onClick={this.deleteOrgnization}>YES</MDBBtn>
      </MDBModalFooter>
      </MDBModal>


    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
      <MDBModalHeader toggle={this.toggle}>Success</MDBModalHeader>
      <MDBModalBody>
        Organization deleted successfully.
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn> */}
      <MDBBtn color="primary" onClick={this.closeOkPopup}>OK</MDBBtn>
      </MDBModalFooter>
      </MDBModal>

      {/* popup for session expire */}

 <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
      <MDBModalHeader toggle={this.togglesession}>Session has expired</MDBModalHeader>
      <MDBModalBody>
       Please login again.
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
      <MDBBtn color="primary" onClick={this.sessiontagOk}>OK</MDBBtn>
      </MDBModalFooter>
      </MDBModal>
    </>
  );
}
}

export default viewOrganization;




