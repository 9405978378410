import React from 'react';
import { Alert } from 'reactstrap';
import * as baseUrl from './commons/constants';
import '../App.css';

class forgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: { email: props.location.state },
            data: "",
            errors: {},
            message: "",
            sucess: false,
            colour: 'sucess',
            activeItem: "1"
        }
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    /* handleSubmit:Used to perform Validations*/
    handleSubmit(event) {
        event.preventDefault();
        let errors = {};
        let data_1 = { "email": this.state.fields.email.toLowerCase() }
        var email = this.state.fields['email'];

        if (email == '') {
            errors["emailempty"] = "E-mail is required";
        }

        if (email != '') {
            if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
                errors["entervalidemail"] = "Please enter valid e-mail";
            }
        }

        if (email != '' && email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            var url = baseUrl.LoginPath + "/system/authentication/forgotpassword";
            fetch(url, {
                method: "POST",
                body: JSON.stringify(data_1),
                headers: {
                    "content-type": "application/json"
                }
            })
                .then((resp) => {
                    if (resp.status == 404) {
                        errors["emailempty"] = "Invalid E-mail Id";
                        this.setState({ errors: errors });
                    } else {
                        return resp.json();
                    }
                })
                .then((response) => {
                    if (response != undefined) {
                        if (response.message == "Success") {
                            this.setState({ data: response });
                            this.setState({ message: "An email has been sent to the supplied email address.Follow the instructions in the email to reset your password.", colour: 'success' })
                            this.onShowAlert();
                        }
                    }
                })
                .catch((error) => {
                    console.log(error, "catch the loop")
                })
        }
        this.setState({ errors: errors });
    }

    /* handleChange:Used to keep fields in state*/
    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    onShowAlert() {
        this.setState({ sucess: true }, () => {
            window.setTimeout(() => {
                this.setState({ sucess: false })
            }, 7000)
        });
    }

    componentDidMount() { 
        window.addEventListener("beforeunload", ()=>{
            this.props.history.push("/");
        })
    }

    render() {
        var activeclass = "";
        if (this.state.fields['email'] != "") {
            activeclass = "active";
        }
        return (
            <>
                <script type="text/javascript" >
                    function preventBack(){window.history.forward()}
                    setTimeout("preventBack()", 0);
                    window.onunload=function(){null};
                </script>

                <script language="JavaScript">
                    javascript:window.history.forward(1);
                </script>
                <div className="login-page">
                    <header>
                        {/* Navbar */}

                        {/* Intro Section */}
                        <section className="view intro-2">
                            <div className="mask rgba-stylish-strong h-100 d-flex justify-content-center align-items-center">
                                <div className="container">
                                    <form class="form-login" onSubmit={this.handleSubmit}>
                                        <div className="row">
                                            <div className="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5">
                                                {/* Form with header */}
                                                <div className="card wow fadeIn animated" data-wow-delay="0.3s" style={{ visibility: 'visible', animationName: 'fadeIn', animationDelay: '0.3s' }}>
                                                    <div className="card-body">
                                                        {/* Header */}
                                                        {/* Header */}
                                                        <div className="col-md-12 login_icon">
                                                            <img src={process.env.PUBLIC_URL + "/assets/img/webasto-logo-png-transparent.png"} style={{ width: "138px" }} alt="" />
                                                        </div>
                                                        <p class="h4 text-center mb-3 pb-3 pt-2 title_font">Forgot Password</p>
                                                        {/* Body */}
                                                        <Alert color={this.state.colour} isOpen={this.state.sucess} className="mt-2" >{this.state.message}</Alert>
                                                        <div className="md-form" >
                                                            <i className="fas fa-user prefix white-text" />
                                                            <input type="text" id="email" className="form-control pl-0 text_lowercase" onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]} />
                                                            <label htmlFor="email" className={activeclass} >E-mail</label>
                                                            <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["emailempty"]}</span></p></span>
                                                            <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["entervalidemail"]}</span></p></span>
                                                        </div>
                                                        <div className="text-center">
                                                            <button type='submit' className="btn login-btn btn-lg waves-effect waves-light" onSubmit={this.handleSubmit}>Submit</button>
                                                            <hr className="mt-4" />
                                                            <div className="inline-ul text-center d-flex justify-content-center">
                                                                <a className="txt2" href="/">
                                                                    Sign In
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Form with header */}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </section>
                        {/* Intro Section */}
                    </header>
                </div>
            </>
        );
    }
};

export default forgotPassword;