//platform users
export const WEBASTO_SUPER_ADMIN_DEVOPS = "WEBASTO_SUPER_ADMIN_DEVOPS";
export const WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE = "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE";
//tenant users
export const WEBASTO_CUSTOMER_ADMIN = "WEBASTO_CUSTOMER_ADMIN";
export const WEBASTO_SITE_ADMIN = "WEBASTO_SITE_ADMIN";
//general users
export const WEBASTO_END_USER_RESIDENTIAL = "WEBASTO_END_USER_RESIDENTIAL";
export const WEBASTO_END_USER_COMMERCIAL = "WEBASTO_END_USER_COMMERCIAL";

export const WEBASTO_POWER_USER = "WEBASTO_POWER_USER";
export const WEBASTO_CHARGER_ADMIN = "WEBASTO_CHARGER_ADMIN";
export const WEBASTO_WALLBOX_USER = "WEBASTO_WALLBOX_USER";
export const WEBASTO_CLOUD_USER = "WEBASTO_CLOUD_USER";
