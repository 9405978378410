import React from 'react';
import { Link } from 'react-router-dom';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRow, MDBCol, MDBInput, MDBBtn } from "mdbreact";
import { Form, Table } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import Pagination from "react-js-pagination";
import $ from "jquery";
import * as baseUrl from './commons/constants';
import * as Roles from './commons/roles';
import { hasPermission } from './commons/auth';

class chargePoint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onClickCheckBox: false,
      cpIdSorting: false,
      selectFindStatus: false,
      chargePoint: "",
      saveSelectedCpid: "",
      options: [],
      multiple: false,
      hideActionBtn: "none", // show hide btn varible based on permission 
      writePermission: this.props.ChargePoint_W, // ChargePoint_W conndition
      updatePermission: "", // ChargePoint_U conndition
      activePage: 1,
      active: "active",
      activeArray: [],
      checked: false,
      activeDeactiveArray: '',
      list: [],
      cpid1: '',
      filterData: [],
      dataArray: [],
      selectDataArray: [],
      noOfRecords: '',
      data: new Map(),
      intial_data: [],
      pageSize: "10",
      modalsession: false,
      textdisplay: "",
      connectionoutTime: "10h",
      errors: {},
      isCloudWallbox: true,
      colour: 'sucess',
      sucess: false,
      message: "",
      modal1: false,
      saveRate: "",
      saveSno: "",
      modalLogout: false,
      successRate: false,
      openGateway: false,
      gatewaySNO: '',
      environment: '',
      LoginId: '',
      transactionKey: '',
      confirmGatewayUpdate: false,
      successGateway: false,
      gatewaySuccessMessage: '',
      dxaccessEnable: false,
      accountId: "",
      isStripePaymentGatewayEnabled: false
    }

    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.getChargePoints = this.getChargePoints.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.togglesession = this.togglesession.bind(this);
    this.chargepointIDChange = this.chargepointIDChange.bind(this);
    this.getSearchInventoryList = this.getSearchInventoryList.bind(this);
    this.FindCpName = this.FindCpName.bind(this);
    this.getChargePoint = this.getChargePoint.bind(this);
    this.ClearChargepoint = this.ClearChargepoint.bind(this);
    this.ClearChargepoints = this.ClearChargepoints.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onBlurEvent = this.onBlurEvent.bind(this);
    this._onFocus = this._onFocus.bind(this);
    this.dynamicSort = this.dynamicSort.bind(this);
    this.backToList = this.backToList.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.toggleGateway = this.toggleGateway.bind(this);
    this.numberHandler = this.numberHandler.bind(this);
    this.toggleLogout = this.toggleLogout.bind(this);
    this.toggleGatewayUpdate = this.toggleGatewayUpdate.bind(this);
    this.CloseLogOutArchive = this.CloseLogOutArchive.bind(this);
    this.updateRate = this.updateRate.bind(this);
    this.toggleUpdateSuccess = this.toggleUpdateSuccess.bind(this);
    this.closeSuccess = this.closeSuccess.bind(this);
    this.onchangeInput = this.onchangeInput.bind(this);
    this.updateGateway = this.updateGateway.bind(this);
    this.getGatewayDetails = this.getGatewayDetails.bind(this);
    this.toggleGatewaySuccess = this.toggleGatewaySuccess.bind(this);
    this.closeGatewaySuccess = this.closeGatewaySuccess.bind(this);
  }

  ClearChargepoints(ref, event) {

    if (this.state.onClickCheckBox == true) {
      this.handlePageChange(this.state.activePage);
      window.location.reload();
      this.state.onClickCheckBox = false;
    }
  }

  ClearChargepoint(ref, event) {
    this.state.selectFindStatus = false;
    this.state.activeArray = "";
    let errors = {};
    errors["chargePointNotExists"] = "";
    this.setState({
      errors: errors
    });
    this.state.saveSelectedCpid = "";
    this.state.pageSize = 10;
    this.state.activePage = 1;
    document.getElementById("showEntries").value = 10;
    this.getChargePoints(this.state.pageSize, this.state.activePage, this.state.saveSelectedCpid);
    this.setState({
      dataArray: this.state.dataArray, errors: errors
    });
    ref.current.clear();
    this.setState({
      chargePoint: ""
    })
  }

  getChargePoint(event) {

    var chargePoint = event.target.value;
    this.setState({
      chargePoint: chargePoint
    })
    if (chargePoint.length == 1) {
      this.getChargePoints(this.state.pageSize, this.state.activePage)
      this.setState({
        dataArray: this.state.dataArray,
      });
    }
  }

  // api to get chargepointId based on search filter
  chargepointIDChange(cpid) {
    this.state.selectFindStatus = true;

    this.state.saveSelectedCpid = cpid
    this.setState({ saveSelectedCpid: cpid })
    if (cpid.length == 0) {

      this.getChargePoints(this.state.pageSize, this.state.activePage)
      this.setState({
        dataArray: this.state.dataArray,
      });
    } else {
      let errors = {};
      errors["chargePointNotExists"] = "";
      errors["selectChargepoint"] = "";
      this.setState({
        errors: errors
      });

      this.getInventorybasedonchargepoint(this.state.pageSize, this.state.activePage, cpid);
    }

  }

  onBlurEvent(event) {
    var chargePoint = event.target.value;
    if (chargePoint.length == 0) {
      this.getChargePoints(this.state.pageSize, this.state.activePage, this.state.saveSelectedCpid)
      this.setState({
        dataArray: this.state.dataArray,
      });
    }
    let errors = {};
    errors["chargePointNotExists"] = "";
    errors["selectChargepoint"] = "";
    this.setState({
      errors: errors
    });
  }

  getInventorybasedonchargepoint(pageSize, pageNo, saveSelectedCpid) {

    var errors = {};
    if (this.state.saveSelectedCpid == null || this.state.saveSelectedCpid == "") {
      errors["selectChargepoint"] = "Please select the serial number.";
      this.setState({
        errors: errors
      });
    }
    else {
      if (this.state.selectFindStatus == true) {
        var url = baseUrl.WebPath + "chargers/search?pageSize=" + pageSize + "&pageNo=" + pageNo + "&chargePointId=" + this.state.saveSelectedCpid;

      }
      else {
        // var url = baseUrl.URLPath + "chargepoints/getbycp/"+cpid;
        var url = baseUrl.WebPath + "chargers/search?pageSize=" + pageSize + "&pageNo=" + pageNo + "&chargePointId=" + this.state.saveSelectedCpid;

      }
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization")
        }
      })
        .then((resp) => {

          if (resp.status == 401) {
            this.togglesession();
            localStorage.clear();
          }
          if (resp.status == 404) {
            errors["chargePointNotExists"] = "Serial Number not exists.";
            this.setState({
              errors: errors
            });
          }
          if (resp.status == 200) {
            return resp.json();
          }
        })
        .then((response) => {

          if (response != undefined) {
            if (response.count.count == 0) {
              errors["chargePointNotExists"] = "Serial Number not exists.";
              this.setState({
                errors: errors
              });
              $("#nochargeexists").show();
            } else {
              this.state.activeArray = "";
              $("#nochargeexists").hide();
              // this.setState({selectDataArray : []});
              // var dataArr = [];
              // dataArr.push(response);
              // this.setState({selectDataArray : dataArr});
              this.setState({
                selectDataArray: response.cpList,
                noOfRecords: response.count.count

              });


              var data = this.state.data;
              data.set(pageNo, response.count.count)

            }
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
    }
  }

  async togglesession() {

    this.setState({
      modalsession: !this.state.modalsession
    });
  }

  sessiontagOk() {
    window.location.replace("/")
  }

  handlePageChange(pageNumber) {

    if (pageNumber != this.state.activePage) {
      var data = this.state.data;
      var pageData = data.get(pageNumber);
      var pageData = undefined;
      if (pageData == undefined) {

        var pageData = data.get(pageNumber);
        var pageData = undefined;
        if (pageData == undefined) {
          this.setState({ activePage: pageNumber });
          this.getChargePoints(this.state.pageSize, pageNumber);

        } else {
          this.setState({ activePage: pageNumber });
          this.setState({ dataArray: pageData })
        }
      }
    }
  }

  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    // this.state.data.clear();
    // this.setState({pageSize:entries});
    this.state.activePage = 1;
    this.state.pageSize = entries;
    this.getChargePoints(this.state.pageSize, this.state.activePage);
  }

  async getChargePoints(pageSize, pageNo) {
    if (this.state.isCloudWallbox != undefined && this.state.isCloudWallbox == "true") {
      var url = baseUrl.WebPath + "chargers/" + this.state.userId + "?pageNo=" + pageNo + "&pageSize=" + pageSize;
    } else if (localStorage.getItem("role") == Roles.WEBASTO_CLOUD_USER) {
      var url = baseUrl.WebPath + "chargers/" + localStorage.getItem("userId") + "?pageNo=" + pageNo + "&pageSize=" + pageSize;
    } else {
      var timeZone = localStorage.getItem("timezone");
      if (this.state.selectFindStatus == true) {
        var url = baseUrl.WebPath + "chargers/search?pageSize=" + pageSize + "&pageNo=" + pageNo + "&chargePointId=" + this.state.saveSelectedCpid;
      }
      else {
        var url = baseUrl.WebPath + "chargers/search?pageSize=" + pageSize + "&pageNo=" + pageNo + "&chargePointId=" + this.state.saveSelectedCpid;
      }
    }


    await fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          // alert("Access token is expired, please login again")
          this.togglesession();

          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {

          this.setState({
            dataArray: response.cpList,
            noOfRecords: response.count.count,
            selectDataArray: response.cpList
          })

          var data = this.state.data;
          data.set(pageNo, response.count.count)

          //  var option = [];
          // for(var i = 0; i < this.state.dataArray.length; i++)
          // {
          //   //alert(this.state.items[i].serial_number);
          //   option.push(this.state.dataArray[i].chargePointId);

          // }

          // this.setState({options:option});

        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  componentDidMount() {
    let path = window.location.href.split("/")[3];

    if (path == "wallboxList") {
      this.state.isCloudWallbox = "false";
    } else {
      this.state.isCloudWallbox = localStorage.getItem("isCloudUsersPage");
      this.state.userId = localStorage.getItem("selectedUserId");
    }

    var user = {
      permissions: []
    }
    user.permissions = localStorage.getItem("roleAccess");
    this.state.updatePermission = hasPermission(user, ['updatePermission']);
    // permission related code
    if (this.state.writePermission == true) {
      this.setState({
        hideActionBtn: "inline-block"
      })
    }

    this.getChargePoints(this.state.pageSize, this.state.activePage);



  }

  redirectViewPage(id, e) {
    var url = "/viewChargePoint/" + id;

    this.props.history.push(url);
  }

  //  function after clicking on checkbox to stored in an array 
  handleChange(id, cpid, event) {
    var fdf = this.state.activeArray;
    var checkedValue = document.getElementById(id).checked;
    if (checkedValue == true) {
      this.state.onClickCheckBox = true;
      this.state.list.push(cpid);
      this.setState({
        activeArray: this.state.list
      });
    } else {
      var fdf = this.state.list.index;
      const index = this.state.list.indexOf(cpid);
      if (index > -1) {
        this.state.list.splice(index, 1);
      }
      this.setState({
        activeArray: this.state.list
      });
    }
  }

  // search filter 
  handleKeyDown(ref, e) {

    var serial_no = e.target.value;

    $("#nochargeexists").hide();

    if (serial_no.length > 1) {
      $("#selectcharge").hide();
    } else {
      $("#selectcharge").show();

    }

    var key = e.key;
    var ctrl = e.ctrlKey ? e.ctrlKey : ((key == 17) ? true : false);
    var getList = this.state.intial_data;


    var sno = serial_no.slice(0, serial_no.length - 1)


    this.getSearchInventoryList(sno)

    if (key == "Backspace" || key == "Delete") {

      var x = e.target.value
      var sno = x.slice(0, x.length - 1)
      if (sno == "") {

        this.state.saveSelectedCpid = sno;

        this.getChargePoints(this.state.pageSize, this.state.activePage)
      }
    }

    if (e.keyCode == 13) {
      $("#selectcharge").hide();
      this.FindCpName(ref, e);
      $("#rbt_id").hide();

    }

    if ((e.keyCode == 86 && ctrl)) {

      $("#selectcharge").hide();
      this.getSearchInventoryList(serial_no)
    }

    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9 -]");
        var key = e.key;
        if (!regex.test(key)) {

          e.preventDefault();

          return false;


        }
        break;

    }

  }

  //function for sorting table data on click
  ascendingOrder() {
    if (this.state.cpIdSorting == false) {
      this.state.selectDataArray.sort(this.dynamicSort("chargePointId"));
      this.state.cpIdSorting = true;
      this.setState({ selectDataArray: this.state.selectDataArray.sort(this.dynamicSort("chargePointId")) })
    }
    else {
      this.state.selectDataArray.sort(this.dynamicSort("chargePointId"));
      this.state.cpIdSorting = false;
      this.setState({ selectDataArray: this.state.selectDataArray.reverse() });
    }

  }

  dynamicSort(property) {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {

        return a[property].localeCompare(b[property]);
      }
    }
  }

  getSearchInventoryList(cpid) {

    // var de = $(".rbt-input-main").val();
    // alert(de)


    var cp_id = [];
    // if( this.state.selectFindStatus == true){
    //   var url = baseUrl.URLPath + "chargepoints/search?pageSize="+pageSize+"&pageNo="+1+"&chargePointId="+this.state.saveSelectedCpid;

    // }
    // else{
    var url = baseUrl.WebPath + "chargers/search/partial?cpid=" + cpid;
    //   var url = baseUrl.URLPath + "chargepoints/search?pageSize="+pageSize+"&pageNo="+pageNo+"&chargePointId="+this.state.saveSelectedCpid;
    // }

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {

        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        } else if (resp.status == 500) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response.status == 500) {
          this.setState({
            options: [],
            intial_data: []
          });
        } else if (response != undefined) {
          this.setState({
            options: response,
            intial_data: response
          });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop");
      })
  }

  FindCpName(ref, event) {
    // this.state.activeArray="";
    this.state.saveSelectedCpid = ref.current.inputNode.value;
    var errors = {};
    if (this.state.saveSelectedCpid == null || this.state.saveSelectedCpid == "") {
      errors["selectChargepoint"] = "Please select the serial number.";
      this.setState({
        errors: errors
      });
      this.state.pageSize = 10;
      this.state.activePage = 1;
      this.getChargePoints(this.state.pageSize, this.state.activePage)

    } else {
      errors["selectChargepoint"] = "";
      this.state.selectFindStatus = true;
      this.state.pageSize = 10;
      this.state.activePage = 1;
      this.getInventorybasedonchargepoint(this.state.pageSize, this.state.activePage);
    }


    var serial_no = event.target.value;



    if (serial_no.length > 1) {
      $("#selectcharge").hide();
    } else {
      $("#selectcharge").show();
    }

  }

  _onFocus() {
    $("#clearInput").click();
  }

  backToList = () => {
    localStorage.setItem("isCloudUsersPage", false);
    this.props.history.push("/userManagementController");
  }

  toggleEdit(rate, serial_number, dxaccess) {
    this.state.saveRate = rate;
    this.setState({
      modal1: !this.state.modal1,
      saveSno: serial_number,
      dxaccessEnable: dxaccess
    });
  }

  toggleGateway(serial_number) {
    this.setState({
      openGateway: !this.state.openGateway,
      gatewaySNO: serial_number,
      errors: {}
    });
  }

  //This is for making user should enter only numbers
  numberHandler(field, e) {
    e.preventDefault();
    let fields = this.state;
    let value = e.target.value.split('.')[1];
    let valid = true;
    if (value && value.length > 2) {
      valid = false
    }

    if (e.target.validity.valid && e.target.value < 1000000 && valid) {
      fields[field] = e.target.value;
      this.setState({ fields: fields });
    } else if (e.target.value == "") {
      fields[field] = e.target.value;
      this.setState({ fields: fields });
    }
  }

  onchangeInput(field, e) {
    e.preventDefault();
    let fields = this.state;
    fields[field] = e.target.value;
    this.setState({ fields: fields });
  }

  toggleLogout() {
    this.setState({
      modalLogout: !this.state.modalLogout,
      modal1: !this.state.modal1
    });
  }

  toggleGatewayUpdate() {
    let fields = this.state;
    let errors = this.state.errors;

    // if(fields.isStripePaymentGatewayEnabled === true){
    //   if (fields.accountId == "") {
    //     errors["selectAccountId"] = "Please enter account Id";
    //   } else {
    //     errors["selectAccountId"] = "";
    //   }
    // }

    if(fields.isStripePaymentGatewayEnabled === false){
      if (fields.LoginId == "") {
        errors["selectLoginId"] = "Please enter Login Id";
      } else {
        errors["selectLoginId"] = "";
      }

      if (fields.transactionKey == "") {
        errors["selectTransactionKey"] = "Please enter transaction key"
      } else {
        errors["selectTransactionKey"] = "";
      }
    }

    if (fields.environment == "") {
      errors["selectEnvironment"] = "Please select Environmnt";
    } else {
      errors["selectEnvironment"] = "";
    }

    this.setState({
      errors
    });

    if ((fields.isStripePaymentGatewayEnabled && fields.environment) || (!fields.isStripePaymentGatewayEnabled && fields.environment && fields.transactionKey && fields.LoginId)) {
      this.setState({
        confirmGatewayUpdate: true,
        openGateway: false
      });
    }
    // old condition with accountId mandatory
    // if ((fields.isStripePaymentGatewayEnabled && fields.environment && fields.accountId) || (!fields.isStripePaymentGatewayEnabled && fields.environment && fields.transactionKey && fields.LoginId)) {
    //   this.setState({
    //     confirmGatewayUpdate: true,
    //     openGateway: false
    //   });
    // }
  }

  toggleUpdateSuccess() {
    this.setState({
      successRate: !this.state.successRate
    });
  }

  toggleGatewaySuccess() {
    this.setState({
      successGateway: !this.state.successGateway
    });
  }

  closeGatewaySuccess() {
    this.setState({
      successGateway: !this.state.successGateway,
      confirmGatewayUpdate: false
    })
  }

  closeSuccess() {
    window.location.reload();
  }

  CloseLogOutArchive = () => {
    this.setState({
      modalLogout: !this.state.modalLogout
    });
  }

  closeConfirmGateway = () => {
    this.setState({
      confirmGatewayUpdate: false
    });
  }

  onShowAlert() {
    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });
  }

  updateRate() {
    this.setState({
      modalLogout: !this.state.modalLogout
    });
    var rate = this.state.saveRate;
    var getSno = this.state.saveSno;
    let errors = {};
    var url = baseUrl.WebPath + "chargers/rate";
    const getRole = localStorage.getItem('role');
    var data = { "serial_number": getSno, "rate": rate };

    if (getRole == Roles.WEBASTO_CHARGER_ADMIN || getRole == Roles.WEBASTO_POWER_USER) {
      data = { "serial_number": getSno, "rate": rate, "dxaccessEnable": this.state.dxaccessEnable };
    }

    // let data = { "serial_number": getSno, "rate": rate, "dxaccessEnable": this.state.dxaccessEnable };
    fetch(url, {
      method: 'PUT', // or 'PUT'
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem('Authorization')
      }
    }).then((resp) => {
      if (resp.status == 200) {
        this.setState({ modal1: false, message: "Rate Updated Successfully" })
        this.toggleUpdateSuccess();
      }
      else if (resp.status == 400) {
      }
      else if (resp.status == 403) {
      }
      else if (resp.status == 401) {
        this.togglesession();
        localStorage.clear();
      } else if (resp.status == 404) {
        return resp.json();
      } else {
        return resp.json();
      }
    }).then((response) => {
      this.setState({ errors: errors })
    })
      .catch(error => console.log('Error:' + error));

    this.setState({ errors: errors });
  }

  getGatewayDetails(serialNumber) {
    var url = baseUrl.URLPath + "charger/gateway/" + serialNumber;

    fetch(url, {
      method: 'GET',
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem('Authorization')
      }
    }).then((resp) => {
      if (resp.status == 200) {
        return resp.json();
      } else if (resp.status == 401) {
        this.togglesession();
        localStorage.clear();
      } else {
        return resp.json();
      }
    }).then((response) => {
      if (response != undefined) {
        let fields = this.state;
        fields.LoginId = response?.loginId ?? '';
        fields.environment = response?.environment ?? '';
        fields.transactionKey = response?.transactionKey ?? '';
        fields.gatewaySNO = serialNumber;
        fields.accountId = response?.accountId;
        fields.isStripePaymentGatewayEnabled = response?.isStripePaymentGatewayEnabled;

        this.setState({
          ...this.state,
          fields
        }, () => {
          this.toggleGateway(serialNumber);
        });
      }
    }).catch(error => console.log('Error:' + error));
  }

  updateGateway() {
    var url = baseUrl.URLPath + "charger/gateway";

    let payload = {
      "accountId": this.state.accountId,
      "environment": this.state.environment,
      "isStripePaymentGatewayEnabled": this.state.isStripePaymentGatewayEnabled,
      "loginId": this.state.LoginId,
      "serialNo": this.state.gatewaySNO,
      "transactionKey": this.state.transactionKey
    }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem('Authorization')
      }
    }).then((resp) => {
      if (resp.status == 200) {
        return resp.json();
      } else if (resp.status == 401) {
        this.togglesession();
        localStorage.clear();
      } else {
        return resp.json();
      }
    }).then((response) => {
      if (response != undefined) {
        this.setState({
          gatewaySuccessMessage: 'Gateway Details Updated Successfully'
        }, () => {
          this.toggleGatewaySuccess();
        })
      }
    }).catch(error => console.log('Error:' + error));

  }

  render() {

    const ref = React.createRef();

    // condition for enableAction
    const getRole = localStorage.getItem('role');

    let enableAction = "true";

    if (getRole == "WEBASTO_WALLBOX_USER") {
      enableAction = "none";
    } else {
      enableAction = "display:block";
    }

    var data = this.state.selectDataArray.map((dataArray, index) => {
      if (dataArray.serial_number != "") {
        return (
          <tr key={index}>
            {
              (this.state.isCloudWallbox == "true" || getRole == "WEBASTO_CLOUD_USER") ?
                <td>{dataArray.serial_number}</td> :
                <td>  <Link to={{
                  pathname: '/wallBoxUserList',
                  state: dataArray.serial_number,
                }} onClick={() => {
                  localStorage.setItem("selectedSerialNo", dataArray.serial_number)
                }}>{dataArray.serial_number}</Link></td>
            }
            {
              (getRole == "WEBASTO_CLOUD_USER") ?
                <></> : <td>{dataArray.puk}</td>
            }
            <td>
              Software Version: {dataArray.software_version}<br></br>
              Power Version: {dataArray.power_version}
            </td>
            <td>$ {dataArray.rate}</td>
            {
              (this.state.isCloudWallbox == "true" || getRole == "WEBASTO_CLOUD_USER") ?
                <td style={{ display: enableAction }}>
                  <Link to={{ pathname: '/cloudUserHistory' }} onClick={() => {
                    localStorage.setItem("selectedSerialNo", dataArray.serial_number)
                  }}>View</Link>
                </td> :
                <td style={{ display: enableAction }}>
                  <i className="fas fa-pencil-alt pr-2 action-icon edit_usericon" title="Edit" onClick={this.toggleEdit.bind(this, dataArray.rate, dataArray.serial_number, dataArray?.dxaccessEnable)}></i>
                </td>
            }
            {
              (getRole == "WEBASTO_POWER_USER") && <td>
                <i className="fas fa-pencil-alt pr-2 action-icon edit_usericon" title="Gateway Details" onClick={this.getGatewayDetails.bind(this, dataArray.serial_number)}></i>
              </td>
            }
            {
              (getRole == Roles.WEBASTO_CHARGER_ADMIN || getRole == Roles.WEBASTO_POWER_USER) && <td>
                {
                  dataArray?.dxaccessEnable ? 'Enabled' : 'Disabled'
                }
              </td>
            }
          </tr>
        );
      }
    });

    return (
      <>
        <main class="content-div" >
          {/* page title */}
          <p>Overview
            <div className="breadcrumb_div">Wall Box &gt; <span className="breadcrumb_page">Overview</span></div>
          </p>
          {/* end page title */}
          <div className="page-outerdiv">
            {
              (this.state.isCloudWallbox == "true") ?
                <>
                  <div className='col-md-12'>
                    <div className="pull-right">
                      <button class="refresh-btn" type="button" data-test="button" onClick={this.backToList} ><i className="fas fa-angle-left mr-2"></i> Back</button>
                    </div>
                  </div>
                  <br />
                  <br />
                </> :
                <></>
            }

            {/* end page menu bar  */}

            {/* search filter */}
            <div className="row ">
              <div className="col-md-4 pull-right ">
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <Typeahead
                    labelKey="name"
                    className="w_80"
                    multiple={this.state.multiple}
                    options={this.state.options}
                    id="rbt_id"
                    minLength={3}
                    placeholder="Choose a Serial Number..."
                    onKeyDown={this.handleKeyDown.bind(this, ref)}
                    onChange={this.chargepointIDChange.bind(this)}
                    onBlur={this.onBlurEvent}
                    onFocus={this.ClearChargepoints.bind(this, ref)}
                    ref={ref}
                  />
                  <button type="button" className="close close_icon" id="clearInput" aria-label="Close" onClick={this.ClearChargepoint.bind(this, ref)}  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.FindCpName.bind(this, ref)}>Find</button>
                  </div>
                  <span className="pull-left error_msg w3-animate-top mt-1" id="nochargeexists"><span style={{ color: "red" }}>{this.state.errors["chargePointNotExists"]}</span></span>
                  <span className="pull-left error_msg w3-animate-top mt-1" id="selectcharge"><span style={{ color: "red" }}>{this.state.errors["selectChargepoint"]}</span></span>
                </div>
              </div>
            </div>
            <div className="row menubar">
              <div className="col-md-12 ">
                <div className="">
                  <div className="pull-right">
                    <label className="sub-text pull-left" >Show entries</label>
                    <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                      <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange} value={this.state.pageSize}>
                        <option>10</option>
                        <option>20</option>
                        <option>30</option>
                        <option>40</option>
                        <option>50</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
            {/* table start */}
            <div className="row ">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th>Serial No</th>
                      {
                        (getRole == "WEBASTO_CLOUD_USER") ?
                          <></> : <th>PIN</th>
                      }
                      <th>Version</th>
                      <th>Rate / Hour</th>
                      {
                        (this.state.isCloudWallbox == "true" || getRole == "WEBASTO_CLOUD_USER") ?
                          <th style={{ display: enableAction }}>History</th> :
                          <th style={{ display: enableAction }}>Actions</th>
                      }
                      {
                        (getRole == "WEBASTO_POWER_USER") && <th>Gateway Details</th>
                      }
                      {
                        (getRole == Roles.WEBASTO_CHARGER_ADMIN || getRole == Roles.WEBASTO_POWER_USER) && <th>DX Access</th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {data}
                    {
                      (this.state.selectDataArray.length == 0 && (getRole == "WEBASTO_CLOUD_USER" || getRole == "WEBASTO_CHARGER_ADMIN")) ?
                        <tr>
                          <td colSpan="5" style={{ textAlign: "center" }}>There are no wallboxes connected to this user</td>
                        </tr> : (this.state.selectDataArray.length == 0 && getRole == "WEBASTO_POWER_USER" && localStorage.getItem("isCloudUsersPage") == "true") ?
                          <tr>
                            <td colSpan="5" style={{ textAlign: "center" }}>There are no wallboxes connected to this user</td>
                          </tr> : null
                    }
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 mt-2 mb-4 text-right">
                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
              {/* End table  */}
            </div>
          </div>
          {/* popup for session expire */}
          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>Session has expired</MDBModalHeader>
            <MDBModalBody>
              Please login again.
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk}>OK</button>
            </MDBModalFooter>
          </MDBModal>

          {/* confirmation message popup for edit  */}
          <MDBModal isOpen={this.state.modal1} toggle={this.toggleEdit} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggleEdit}>Edit Rate</MDBModalHeader>
            <MDBModalBody className="needs-validation">
              <MDBRow>
                <MDBCol md="6">
                  <MDBInput
                    name="saveRate"
                    value={this.state.saveRate ? Math.floor(this.state.saveRate) : ""}
                    min="0"
                    pattern="[0-9]*"
                    id="saveRate"
                    label="Rate ($)"
                    autoComplete="off"
                    onInput={this.numberHandler.bind(this, "saveRate")}
                  >
                  </MDBInput>
                </MDBCol>
                {
                  (getRole == Roles.WEBASTO_CHARGER_ADMIN || getRole == Roles.WEBASTO_POWER_USER) && <div className="col-md-3 pl-0">
                    <Form.Check
                      className=""
                      custom
                      id={`dxaccess`}
                      type="checkbox"
                      checked={this.state.dxaccessEnable}
                      onChange={() => { this.setState({ dxaccessEnable: !this.state.dxaccessEnable }) }}
                      label={'DX Access'}
                    />
                  </div>
                }
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary mt-2 mb-2" onClick={this.toggleEdit}>Close</button>
              <button type="button" className="btn_primary mt-2 mb-2" onClick={this.toggleLogout}>Update</button>
            </MDBModalFooter>
          </MDBModal>

          {/* confirmation message for update */}
          <MDBModal isOpen={this.state.modalLogout} toggle={this.toggleLogout} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggleLogout}>Confirmation</MDBModalHeader>
            <MDBModalBody>
              Do you want to update?
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary mt-2 mb-2" onClick={this.CloseLogOutArchive}>Close</button>
              <button type="button" className="btn_primary mt-2 mb-2" onClick={this.updateRate}>Yes</button>
            </MDBModalFooter>
          </MDBModal>

          {/* Edit Rate update success message */}
          <MDBModal isOpen={this.state.successRate} toggle={this.toggleUpdateSuccess} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggleUpdateSuccess}>Success</MDBModalHeader>
            <MDBModalBody>
              {this.state.message}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary mt-2 mb-2" onClick={this.closeSuccess}>Ok</button>
            </MDBModalFooter>
          </MDBModal>

          {/* confirmation message popup for edit gateway details */}
          <MDBModal isOpen={this.state.openGateway} size="md" className="model_top">
            <MDBModalHeader>Update Gateway Details</MDBModalHeader>
            <MDBModalBody className="needs-validation">
              <div className='row'>
                <div className="col-md-6 mt-1 mb-3">
                  <select className="browser-default custom-select select_height" id="environment" value={this.state.environment} onChange={this.onchangeInput.bind(this, "environment")}>
                    <option value="">Select Environment *</option>
                    <option value="PRODUCTION">PRODUCTION</option>
                    <option value="SANDBOX">SANDBOX</option>
                  </select>
                  <span className="pull-left error_msg w3-animate-top mt-1" style={{ color: "red" }}>{this.state.errors["selectEnvironment"]}</span>
                </div>
              </div>
              {
                this.state.isStripePaymentGatewayEnabled ?
                <>
                  <MDBRow>
                    <MDBCol md="6">
                      <MDBInput
                        name="accountId"
                        value={this.state.accountId ?? ''}
                        id="accountId"
                        label="Account ID"
                        autoComplete="off"
                        onInput={this.onchangeInput.bind(this, "accountId")}
                      >
                      </MDBInput>
                      <span className="pull-left error_msg w3-animate-top mt-1" style={{ color: "red" }}>{this.state.errors["selectAccountId"]}</span>
                    </MDBCol>
                  </MDBRow>
                  <div className="row">
                    <div className="col-md-12" style={{marginTop: "-15px"}}>
                    <span style={{fontSize: "11px"}}>(If accout id is empty, the default account id will be displayed)</span>
                    </div>
                  </div>                 
                  </>
                  :
                  <>
                    <MDBRow>
                      <MDBCol md="6">
                        <MDBInput
                          name="LoginId"
                          value={this.state.LoginId ?? ''}
                          id="LoginId"
                          label="Login ID *"
                          autoComplete="off"
                          onInput={this.onchangeInput.bind(this, "LoginId")}
                        >
                        </MDBInput>
                        <span className="pull-left error_msg w3-animate-top mt-1" style={{ color: "red" }}>{this.state.errors["selectLoginId"]}</span>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="6">
                        <MDBInput
                          name="transactionKey"
                          value={this.state.transactionKey ?? ''}
                          id="transactionKey"
                          label="Transaction Key *"
                          autoComplete="off"
                          onInput={this.onchangeInput.bind(this, "transactionKey")}
                        >
                        </MDBInput>
                        <span className="pull-left error_msg w3-animate-top mt-1" style={{ color: "red" }}>{this.state.errors["selectTransactionKey"]}</span>
                      </MDBCol>
                    </MDBRow>
                  </>
              }
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary mt-2 mb-2" onClick={this.toggleGateway}>Close</button>
              <button type="button" className="btn_primary mt-2 mb-2" onClick={this.toggleGatewayUpdate}>Update</button>
            </MDBModalFooter>
          </MDBModal>

          {/* confirmation message for gateway update */}
          <MDBModal isOpen={this.state.confirmGatewayUpdate} size="md" className="model_top">
            <MDBModalHeader>Confirmation</MDBModalHeader>
            <MDBModalBody>
              Do you want to update?
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary mt-2 mb-2" onClick={this.closeConfirmGateway}>Close</button>
              <button type="button" className="btn_primary mt-2 mb-2" onClick={this.updateGateway}>Yes</button>
            </MDBModalFooter>
          </MDBModal>

          {/* Edit Rate update success message */}
          <MDBModal isOpen={this.state.successGateway} toggle={this.closeGatewaySuccess} size="md" className="model_top">
            <MDBModalHeader toggle={this.closeGatewaySuccess}>Success</MDBModalHeader>
            <MDBModalBody>
              {this.state.gatewaySuccessMessage}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary mt-2 mb-2" onClick={this.closeGatewaySuccess}>Ok</button>
            </MDBModalFooter>
          </MDBModal>
        </main>
      </>
    );
  }
}
export default chargePoint;
