import React from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from 'mdbreact';
import { Form, Table } from 'react-bootstrap'
import * as baseUrl from './commons/constants';
import Pagination from "react-js-pagination";
import { Link } from 'react-router-dom';
import $ from "jquery";
import Calendar from 'react-calendar';
import moment from 'moment';


class inventoryList extends React.Component {
    constructor(props) {
        super(props);
        let buildinfo = this.props;
        this.state = {
            calenderFrom: "none",
            calenderTo: "none",
            startDate: '',
            toDate: '',
            startDate1: '',
            startDate2: '',
            buildName: buildinfo.location.state.build, // for publish build
            modelName: buildinfo.location.state.model, // for publish model
            model: false,
            list: [],
            activeItem: "1",
            dataArray: [],
            organizationList: [],
            modal2: false,
            page_title: "Publish",
            org_name: "",
            fields: {
                productName: "",
                description: "",
                buildType: "",
                productCode: ""
            },
            orgId: "",
            sucess: false,
            colour: 'sucess',
            message: "",
            errors: {},
            activeArray: [],
            checked: false,
            activeDeactiveArray: '',
            disabledAttr: true,
            logmodel: "",
            pageNo: 1,
            pageSize: "10",
            data: new Map(),
            activePage: 0,
            noOfRecords: '',
            modelArray: [],
            publishlogData: [],
            identifier: "",
            selected_org: "",
            activePage: 1,
            modalsession: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.sendNotification = this.sendNotification.bind(this);
        this.toggle = this.toggle.bind(this)
        this.tagOk = this.tagOk.bind(this)
        this.tabToggle = this.tabToggle.bind(this)
        this.GetFromDate = this.GetFromDate.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.onChangeModelSatus = this.onChangeModelSatus.bind(this);
        this.showEntriesChange = this.showEntriesChange.bind(this);
        this.handleOrgChange = this.handleOrgChange.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.sessiontagOk = this.sessiontagOk.bind(this);
        this.closeCalender = this.closeCalender.bind(this);
        this.changeScheduleFrom = this.changeScheduleFrom.bind(this);
        this.changeScheduleTo = this.changeScheduleTo.bind(this);
        this.keyPress1 = this.keyPress1.bind(this);
        this.keyPress2 = this.keyPress2.bind(this);

    }

    changeScheduleFrom(e) {
        this.setState({ startDate: e.target.value });
    }
    changeScheduleTo(e) {
        this.setState({ toDate: e.target.value });
    }

    keyPress1(e) {
        var key = e.key;
        if (e.keyCode == 13) {
            if (new Date() >= e.target.value) {
                this.setState({ startDate: e.target.value });
                var dateFormat = moment(this.state.startDate).format("YYYY-MM-DD");
                this.state.startDate1 = dateFormat;
                this.getPublishLogData(this.state.pageSize, 1);
            } else {
                this.setState({ startDate: "" });
                this.state.startDate1 = "";
                $('.future_err1').text("You can't select future date");
            }
        }
        if (key == "Delete" || key == "Backspace") {
            this.state.startDate1 = "";
            this.getPublishLogData(this.state.pageSize, 1);
        }

    }
    keyPress2(e) {
        var key = e.key;
        if (e.keyCode == 13) {
            if (new Date() >= e.target.value) {
                this.setState({ toDate: e.target.value });
                var dateFormat = moment(this.state.toDate).format("YYYY-MM-DD");
                this.state.startDate2 = dateFormat;
                this.getPublishLogData(this.state.pageSize, 1);
            } else {
                this.setState({ toDate: "" });
                this.state.startDate2 = "";
                $('.future_err2').text("You can't select future date");
            }
        }
        if (key == "Delete" || key == "Backspace") {
            this.state.startDate2 = "";
            this.getPublishLogData(this.state.pageSize, 1);
        }

    }

    closeCalender(type, event) {
        if (type == "from") {
            this.setState({
                calenderFrom: "none"
            })
        } else {
            this.setState({
                calenderTo: "none"
            })
        }
    }


    displayCalender(type, event) {
        var calenderFrom = "";
        var calenderTo = "";
        if (type == "from") {
            calenderFrom = "block";
            calenderTo = "none";
        } else {
            calenderTo = "block";
            calenderFrom = "none";
        }
        this.setState({
            calenderFrom: calenderFrom,
            calenderTo: calenderTo
        })
    }
    // function onclick of tab
    tabToggle(tab, event) {
        var orgname = localStorage.getItem("tenant");
        this.state.identifier = orgname;
        this.state.org_name = orgname;
        this.state.toDate = "";
        this.state.startDate = "";
        localStorage.setItem('org', orgname)
        this.getPublishLogData(this.state.pageSize, this.state.activePage);

        //  alert(this.state.org_name)

        this.setState({
            org_name: orgname
        })

        if (this.state.activeItem !== tab) {
            this.setState({
                activeItem: tab
            });
        }
    }


    //change function for build status filter
    onChangeModelSatus() {
        var Status = document.getElementById("modelId").value;
        //  alert(Status)
        this.setState({ logmodel: Status }, () => {
            this.getPublishLogData(this.state.pageSize, 1);
        });
    }
    async togglesession() {
        
        this.setState({
            modalsession: !this.state.modalsession
        });
    }


    sessiontagOk() {
        this.props.history.push('/');
        window.location.reload();
    }


    handlePageChange(pageNumber) {
        

        if (pageNumber != this.state.activePage) {
            var data = this.state.data;
            var pageData = data.get(pageNumber);
            if (pageData == undefined) {
                this.setState({ activePage: pageNumber });
                this.getPublishLogData(this.state.pageSize, pageNumber);
            } else {
                this.setState({ activePage: pageNumber });
                this.setState({ orgArray: pageData })
            }
        }
    }

    // popup calling function
    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    // After clicking on ok button of popup
    tagOk() {
        window.location.reload();
    }

    GetFromDate = date => {
        var fdate = this.formatCalDate1(date);

        this.setState({ startDate: fdate });
        this.state.startDate1 = this.formatCalDate(date);
        this.getPublishLogData(this.state.pageSize, 1);
        var calenderFrom = "none";
        this.setState({
            calenderFrom: calenderFrom
        })
    };

    dateChange = date => {
        var fdate = this.formatCalDate1(date);

        this.setState({ toDate: fdate });
        var currentDate = date;
        this.state.startDate2 = this.formatCalDate(date);
        this.getPublishLogData(this.state.pageSize, 1);
        var calenderTo = "none";
        this.setState({
            calenderTo: calenderTo
        })
    };
    formatCalDate1(date) {
        var format = date.toISOString();
        var xyz = moment(format).format("DD MMM YYYY")
        return xyz;
    }

    formatCalDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    }

    formatData(date) {
        var currentDate = date;
        var twoDigitMonth = ((currentDate.getMonth() + 1) >= 10) ? (currentDate.getMonth() + 1) : '0' + (currentDate.getMonth() + 1);
        var twoDigitDate = ((currentDate.getDate()) >= 10) ? (currentDate.getDate()) : '0' + (currentDate.getDate());
        var twominDate = ((currentDate.getMinutes()) >= 10) ? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
        var twohoursDate = ((currentDate.getHours()) >= 10) ? (currentDate.getHours()) : '0' + (currentDate.getHours());
        var createdDateTo = twoDigitMonth + "-" + twoDigitDate + "-" + currentDate.getFullYear() + ' ' + twohoursDate + ':' + twominDate;
        var csFormatDate = currentDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate + "T" + twohoursDate + ":" + twominDate + ":00Z";
    }


    componentDidMount() {
        // get org name 
        var orgname = localStorage.getItem("tenant");

        if (this.state.buildName != "") {
            localStorage.setItem("orgBuild", this.state.buildName);
            localStorage.setItem("orgModel", this.state.modelName);
        } else {
            this.setState({
                buildName: localStorage.getItem("orgBuild"),
                modelName: localStorage.getItem("orgModel")
            })
        }


        this.setState({
            org_name: orgname
        })
        // get all org
        var url = baseUrl.URLPath + "admin/v1.0/account/all";
        fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization")
            }
        })
            .then((resp) => {
                
                if (resp.status == 401) {
                    // alert("Access token is expired, please login again")
                    this.togglesession();

                    localStorage.clear();

                }
                if (resp.status == 200) {
                    return resp.json();
                }
            })
            .then((response) => {
                
                if (response != undefined) {
                    this.setState({ organizationList: response.organisationDTOS })
                    var org_name_array = response.organisationDTOS;
                    for (var i = 0; i < org_name_array.length; i++) {

                        if (this.state.org_name.toLowerCase == org_name_array[i].company.toLowerCase) {

                            this.setState({ identifier: org_name_array[i].identifier })
                        }
                    }
                    this.getPublishLogData(this.state.pageSize, this.state.activePage);
                }
            })
            .catch((error) => {
                console.log(error, "catch the loop")
            })

        var url = baseUrl.URLPath + "cpinventory/orgbymodel/" + this.state.modelName;
        fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization")
            }
        })
            .then((resp) => {
                if (resp.status == 401) {
                    this.togglesession();
                    localStorage.clear();
                }
                if (resp.status == 200) {
                    return resp.json();
                }
            })
            .then((response) => {
                if (response != undefined) {
                    this.setState({ dataArray: response })
                }
            })
            .catch((error) => {
                console.log(error, "catch the loop")
            })
        this.getModel();
    }

    handleOrgChange(event) {
        this.state.identifier = event.target.value;
        this.state.org_name = event.target.value;
        localStorage.setItem('org', this.state.identifier);
        this.getPublishLogData(this.state.pageSize, 1);
    }

    getPublishLogData(pageSize, pageNo) {
        var org = localStorage.getItem('org');
        var url = baseUrl.URLPath + "publishnotifications/logs/organization/" + org + "?model=" + this.state.logmodel + "&start_date=" + this.state.startDate1 + "&end_date=" + this.state.startDate2 + "&pageNo=" + pageNo + "&pageSize=" + pageSize;

        fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization")
            }
        })
            .then((resp) => {
                
                if (resp.status == 401) {
                    // alert("Access token is expired, please login again")
                    this.togglesession();

                    localStorage.clear();

                }
                if (resp.status == 200) {
                    return resp.json();
                }
            })
            .then((response) => {
                if (response != undefined) {
                    this.setState({
                        publishlogData: response.logs,
                        noOfRecords: response.count.count

                    })
                }
            })
            .catch((error) => {
                console.log(error, "catch the loop")
            })
    }

    showEntriesChange() {
        var entries = document.getElementById("showEntries").value;
        this.state.activePage = 1;
        this.state.pageSize = entries;
        this.getPublishLogData(entries, 1);
    }

    getModel() {
        var url = baseUrl.URLPath + "cpinventory/evmodel/";
        fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization"),
            }
        })
            .then((resp) => {
                if (resp.status == 401) {
                    this.togglesession();
                    localStorage.clear();
                }
                if (resp.status == 200) {
                    return resp.json();
                }
            })
            .then((response) => {
                if (response != undefined) {
                    this.setState({ modelArray: response });
                }
            })
            .catch((error) => {
                console.log(error, "catch the loop")
            })

    }

    handleChange(id, cpid, event) {
        var checkedValue = document.getElementById(id).checked;
        if (checkedValue == true) {
            this.state.list.push(cpid);
            this.setState({
                activeArray: this.state.list,
                disabledAttr: ""
            });
        } else {
            const index = this.state.list.indexOf(cpid);
            if (index > -1) {
                this.state.list.splice(this.state.list.index, 1);
                this.setState({
                    activeArray: this.state.list
                });
            } else {
                this.setState({
                    activeArray: "",
                    disabledAttr: true
                });
            }
        }

    }

    sendNotification() {
        
        let url = baseUrl.URLPath + "publishnotifications/tenants";
        //localhost:8080/cpms/configurations
        let data = { "build": this.state.buildName, "model": this.state.modelName, "tenants": this.state.activeArray }
        fetch(url, {
            method: 'POST', // or 'POST'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization"),
            }
        }).then(resp => {
            if (resp.status == 401) {
                this.togglesession();
                // alert("Access token is expired, please login again")
                localStorage.clear();
            }
            else if (resp.status == 500) {
                console.log("internal server error")
            } else if (resp.status == 400) {
                console.log("Bad request")
            } else {
                if (resp.status == 200) {
                    this.toggle()
                }
            }

        }).then((response) => {

        })
            .catch(error => alert('Error:' + error));

    }
    //function for sorting table data on click
    ascendingOrder() {
        this.setState({ publishlogData: this.state.publishlogData.reverse() })
    }
    render() {
        //model list 
        const modelArray = this.state.modelArray.map((modelArray, index) => {
            return (
                <option value={modelArray}>{modelArray}</option>
            )
        })

        //organization list 
        const organizationList = this.state.organizationList.map((organizationList, index) => {
            var setOrg = false;
            if (this.state.org_name.toLowerCase() == organizationList.company.toLowerCase()) {
                setOrg = true;
                this.state.identifier = organizationList.identifier;
            }
            return (
                <option value={organizationList.identifier} selected={setOrg} >{organizationList.company}</option>
            )
        })

        //publish log data 
        console.log(this.state.publishlogData);
        //  let publishLogRow = "";
        let publishLogRow = this.state.publishlogData.map((publishlogData, index) => {
            var time = publishlogData.sentDate.split(" ");
            return (
                <tr>
                    <td className="wrap-word">{moment(time[0]).format("DD MMM YYYY") && <div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {moment(time[0]).format("DD MMM YYYY")}</div>} </td>
                    <td className="wrap-word">{moment(time[1]).format("HH:mm") && <div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {moment(time[1]).format("HH:mm")} </div>} </td>
                    <td>{publishlogData.tenantId}</td>
                    <td>{publishlogData.modelName}</td>
                    <td>{publishlogData.buildURL}</td>
                </tr>
            )
        });



        let data = "";
        if (this.state.modelName != "") {
            data = this.state.dataArray.map((dataArray, index) => {
                return (
                    <tr key={index}>

                        <td className="text-center">
                            <Form.Check
                                custom
                                type="checkbox"
                                id={`custom-${dataArray.tenantId}`}
                                label=""
                                //checked = {false}
                                onChange={this.handleChange.bind(this, `custom-${dataArray.tenantId}`, dataArray.tenantId)}
                                key={dataArray.tenantId}
                            />
                        </td>
                        <td>{dataArray.tenantId}</td>
                    </tr>
                )
            })
        }

        return (
            <>
                <main class="content-div" >
                    <p>Publish
                        <div className="breadcrumb_div">
                            FOTA &gt; <Link to="/builds" > <span>Builds</span></Link > {`>`} <span className="breadcrumb_page">Publish</span>
                        </div>
                    </p>
                    <div className="page-outerdiv">
                        <MDBNav className="mt-3 border-0">
                            <MDBNavItem className="nav-first">
                                <MDBNavLink to="#" className={`b-75 ${this.state.activeItem == "1" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this, "1")} role="tab" >
                                    Publish
                                </MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem className="nav-last">
                                <MDBNavLink to="#" className={`b-75 ${this.state.activeItem == "2" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this, "2")} role="tab" >
                                    Publish Logs
                                </MDBNavLink>
                            </MDBNavItem>

                        </MDBNav>
                        <MDBTabContent activeItem={this.state.activeItem} className="pt-2 border-0" >
                            <MDBTabPane tabId="1" role="tabpanel">

                                {/* page menu bar */}
                                <div className="row menubar mt-4">
                                    <div class="col-md-3">
                                        <label className="title_font">Selected Build: {this.state.buildName}</label>
                                    </div>
                                    <div class="col-md-3">
                                        <label className="title_font">Model Name: {this.state.modelName}</label>
                                    </div>
                                </div>

                                {/* end page menu bar  */}
                                {/* table start */}
                                <div className="row mt-2 ">
                                    <div className="col-md-6">
                                        <Table striped hover size="sm" className="page-table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Organization</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div className="col-md-12 text-right mt-3">
                                        <button type="button" data-test="button" onClick={this.sendNotification} disabled={this.state.disabledAttr} className="pull-right refresh-btn" >
                                            Publish
                                        </button>
                                    </div>
                                    {/* End table  */}
                                </div>
                            </MDBTabPane>
                            <MDBTabPane tabId="2" role="tabpanel">
                                <div className="row mt-2">
                                    <div className="col-md-2 p-rel t-8">
                                        <div class="input-group">
                                            <input type="text" class="form-control input-searchBox pl-3" placeholder="From Time" name="srch-term" id="srch-term" value={this.state.startDate} onChange={this.changeScheduleFrom} onKeyDown={this.keyPress1} />
                                            <div class="input-group-btn">
                                                <button class="input-searchBtn" type="submit" onClick={this.displayCalender.bind(this, 'from')} ><i className="fas fa-calendar-alt fa-sm"></i></button>
                                            </div>
                                        </div>
                                        <div style={{ display: this.state.calenderFrom }} className="calenderContainer">
                                            <div onClick={this.closeCalender.bind(this, 'from')}><i class="far fa-times-circle"></i></div>
                                            <Calendar
                                                selected={this.state.startDate}
                                                onChange={date => this.GetFromDate(date)}
                                                timeFormat="HH:mm"
                                                timeIntervals={1}
                                                timeCaption="time"
                                                dateFormat="dd-MMM-yyyy"
                                                onBlur={this.closeCalender.bind(this, 'from')}
                                                maxDate={(this.state.toDate != "" && this.state.toDate != null) ? new Date(this.state.toDate) : new Date()}
                                            />
                                        </div>
                                        <span className="pull-left future_err1 w3-animate-top mt-1" style={{ color: "red" }}></span>
                                    </div>

                                    <div className="col-md-2 p-rel t-8">
                                        <div class="input-group">
                                            <input type="text" class="form-control input-searchBox pl-3" placeholder="To Time" name="srch-term" id="srch-term" value={this.state.toDate} onChange={this.changeScheduleTo} onKeyDown={this.keyPress2} />
                                            <div class="input-group-btn">
                                                <button class="input-searchBtn" type="submit" onClick={this.displayCalender.bind(this, 'to')}> <i className="fas fa-calendar-alt fa-sm"></i></button>                      </div>
                                        </div>
                                        <div style={{ display: this.state.calenderTo }} className="calenderContainer">
                                            <div onClick={this.closeCalender.bind(this, 'to')}><i class="far fa-times-circle"></i></div>
                                            <Calendar
                                                selected={this.state.toDate}
                                                onChange={date => this.dateChange(date)}
                                                timeFormat="HH:mm"
                                                timeIntervals={1}
                                                timeCaption="time"
                                                dateFormat="dd-MMM-yyyy"
                                                minDate={new Date(this.state.startDate)}
                                                maxDate={new Date()}
                                            />
                                        </div>
                                        <span className="pull-left future_err2 w3-animate-top mt-1" style={{ color: "red" }}></span>
                                    </div>
                                    <div className="col-md-3">
                                        <select className="browser-default custom-select select_height" id="organization" onChange={this.handleOrgChange.bind(this)} value={this.state.org_name}>
                                            <option value="">Select Organization  </option>
                                            {organizationList}
                                        </select>
                                    </div>

                                    <div className="col-md-3">
                                        <select className="browser-default custom-select select_height" id="modelId" onChange={this.onChangeModelSatus.bind(this)}>
                                            <option value="" >Select Model</option>
                                            {modelArray}
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <div className="pull-right">
                                            <label className="sub-text pull-left" >Shows entries</label>
                                            <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                                                <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                                                    <option>10</option>
                                                    <option>20</option>
                                                    <option>30</option>
                                                    <option>40</option>
                                                    <option>50</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                {/* table start */}
                                <div className="row ">
                                    <div className="col-md-12">
                                        <Table striped hover size="sm" className="page-table">
                                            <thead>
                                                <tr>
                                                    <th>Date <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                                                    <th>Time <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                                                    <th>Organization</th>
                                                    <th>Model</th>
                                                    <th>URL</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {publishLogRow}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div className="col-md-12 mt-2 mb-4 text-right">
                                        <Pagination
                                            hideDisabled
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.pageSize}
                                            totalItemsCount={this.state.noOfRecords}
                                            onChange={this.handlePageChange.bind(this)}
                                        />
                                    </div>
                                </div>
                            </MDBTabPane>
                        </MDBTabContent>
                    </div>

                    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
                        <MDBModalHeader toggle={this.toggle}>Success</MDBModalHeader>
                        <MDBModalBody>
                            Publish successfully.
                        </MDBModalBody>
                        <MDBModalFooter>
                            <button className="btn_primary" data-test="button" type="button" onClick={this.tagOk}>OK</button>
                        </MDBModalFooter>
                    </MDBModal>

                    {/* popup for session expire */}
                    <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
                        <MDBModalHeader toggle={this.togglesession}>Session has expired</MDBModalHeader>
                        <MDBModalBody>
                            Please login again.
                        </MDBModalBody>
                        <MDBModalFooter>
                            <button className="btn_primary" data-test="button" type="button" onClick={this.sessiontagOk}>OK</button>
                        </MDBModalFooter>
                    </MDBModal>
                </main>
            </>
        );
    }
}

export default inventoryList;