import React from 'react';
import { MDBDataTable, MDBIcon, Select, MDBInput, MDBCol, MDBRow, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { Form, Table, Check } from 'react-bootstrap'
import * as baseUrl from './commons/constants';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import $ from "jquery";
import { Alert } from 'reactstrap';
import Pagination from "react-js-pagination";

import { createBrowserHistory } from 'history'


const history = createBrowserHistory();


class buildtypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      fields: { name: '', description: '', buildTypeCode: '' },

      startDate: new Date(),
      dataArray: [

      ],
      modal2: false,
      page_title: "Build Types ",
      product_name: "DX Lite",
      errors: {},
      sucess: false,
      colour: 'sucess',
      products: [],
      noOfRecords: '',
      data: new Map(),
      pageSize: "10",
      activePage: 1



    }
    this.addBuildtype = this.addBuildtype.bind(this)
    this.changeHandler = this.changeHandler.bind(this)
    this.onShowAlert = this.onShowAlert.bind(this)
    this.getbuildTypes = this.getbuildTypes.bind(this);
    this.backToList = this.backToList.bind(this);
    this.sessiontoggle = this.sessiontoggle.bind(this);
    this.tagOk = this.tagOk.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.getBuildCount = this.getBuildCount.bind(this);
  }
  tagOk() {
    // this.props.history.push('/');
    history.push('/');
    window.location.reload();
  }
  sessiontoggle() {
    
    this.setState({
      modal: true
    });
  }
  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    this.state.data.clear();
    this.setState({ pageSize: entries });
    this.getbuildTypes(1, entries);
  }
  handlePageChange(pageNumber) {
    
    if (pageNumber != this.state.activePage) {
      var data = this.state.data;
      var pageData = data.get(pageNumber);
      if (pageData == undefined) {
        this.setState({ activePage: pageNumber });
        this.getbuildTypes(this.state.productId, pageNumber, this.state.pageSize);
      } else {
        this.setState({ activePage: pageNumber });
        this.setState({ dataArray: pageData })
      }
    }
  }

  onShowAlert() {
    

    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });

  }

  addBuildtype() {
    var productId = this.state.productId;
    var errors = {};
    if (this.state.fields.name == "") {

      errors["nameempty"] = "Build name should not be null or empty."
      this.setState({ errors: errors })
    }
    if (productId == "") {
      errors["productEmpty"] = "Please select the product."
    }
    if (this.state.fields.buildTypeCode == "") {

      errors["buildTypeCodeError"] = "Build type code should not be null or empty."
      this.setState({ errors: errors })
    }

    if (this.state.fields.name != "" && productId != "" && this.state.fields.buildTypeCode != "") {
      this.setState({ errors: errors })
      
      let url = baseUrl.WebPath + "dashboard/buildtypes/" + productId;

      let data = { "name": this.state.fields.name, "description": this.state.fields.description, "build_type_code": this.state.fields.buildTypeCode };
      fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization")
        }
      })
        .then((resp) => {
          
          if (resp.status == 201) {
            this.setState({ message: "Build Type is added successfully.", colour: 'success' })
            this.setState({ modal2: false })
            this.onShowAlert();
            this.getBuildCount(this.state.productId);
            this.getbuildTypes(this.state.productId, 1, this.state.pageSize)
            this.setState({ modal2: false })

          }
          else if (resp.status == 401) {
            this.sessiontoggle();
            // alert("Access token is expired, please login again")
            localStorage.clear();

          }

          else if (resp.status == 500) {
            // this.setState({message:"Build Type already exist.",colour:'danger'})
            // this.onShowAlert();
            //  return resp.json();
            errors["nameexists"] = "Build Type already exist"
            this.setState({ modal2: true })
            this.setState({
              errors: errors
            });
          } else if (resp.status == 404) {
            errors["buildTypeCodeExists"] = "Build type code already exist.";
            this.setState({
              errors: errors
            });
          }
          else {
            return resp.json();
          }
        }).then((response) => {
          
          if (response != undefined) {
            this.setState({ message: response[0].recommandation, colour: 'danger' })
            this.onShowAlert();
            var fields = this.state.fields;
            fields.name = '';
            fields.description = '';
          }
        })

        .catch((error) => {
          console.log(error, "catch the loop")
        })
    } else {
      this.setState({ errors: errors })
    }
  }

  backToList() {
    this.props.history.push('/productList')
  }
  changeHandler(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  getbuildTypes(productId, pageNo, pageSize) {
    

    if (productId != '') {
      var url = baseUrl.WebPath + "dashboard/buildtypes/" + productId + "?pageNo=" + pageNo + "&pageSize=" + pageSize;
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization")
        }
      })
        .then((resp) => {
          
          if (resp.status == 401) {
            this.sessiontoggle();
            // alert("Access token is expired, please login again")
            localStorage.clear();

          }
          if (resp.status == 200) {
            return resp.json();
          }
        })
        .then((response) => {
          if (response != undefined) {

            // alert(JSON.stringify(response));
            this.setState({ dataArray: response });
          }


        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })

    } else {
      this.setState({ dataArray: [] });
    }
  }
  componentDidMount() {
    
    var productId = this.props.location.state;
    this.setState({ productId: productId })
    this.getBuildCount(productId);
    this.getbuildTypes(productId, this.state.activePage, this.state.pageSize);

  }
  getBuildCount(productId) {
    
    var url = baseUrl.WebPath + "dashboard/buildtypes/" + productId + "/count"
    var header = {

    }
    fetch(url, {
      method: "get",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        
        if (resp.status == 401) {
          // alert("Access token is expired, please login again")
          this.toggle();

          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        
        if (response != undefined) {
          this.setState({ noOfRecords: response.count });

        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
    // this.getProductid(this.state.activePage,this.state.pageSize);

  }


  toggle = nr => () => {
    let modalNumber = 'modal' + nr
    var fields = this.state.fields;
    fields.name = '';
    fields.description = '';
    fields.buildTypeCode = '';
    this.setState({ errors: {} })
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  }

  render() {
    const products = this.state.products.map((product, index) => {
      return (
        <option value={product.product_id}>{product.product_name}</option>
      )
    })
    const data = this.state.dataArray.map((dataArray, index) => {
      var trsactionData = {
        date: this.state.startDate,
        dataArray
      }


      return (
        <tr key={index}>
          <td>{dataArray.name}</td>
          <td>{dataArray.build_type_code}</td>
          <td style={{ width: "40%" }}>{dataArray.description}</td>
          <td><i class="fas fa-archive action-icon" title="Archive"></i></td>
        </tr>
      )
    })
    return (
      <>
        <main class="content-div">
        <p>Products
            <div className="breadcrumb_div">FOTA &gt; <Link to="/productList"> Products </Link> &gt; Build Types</div>
          </p>
          <div className="page-outerdiv">
            {/* page title */}
            {/* end page title */}
            {/* page menu bar */}
            <div className="row menubar">
              <div className="col-md-9 ">
                <div className="mt-3">
                  <button onClick={this.toggle(2)}>
                    <i className="fa fa-plus">
                    </i>
                    <span>Add</span>
                  </button>
                </div>
              </div>
            </div>
            <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
            {/* table start */}
            <div className="row mt-4">
              <div className="col-md-12">
                <label className="pull-left sel_lbl">Show entries</label>
                <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left " >
                  <Form.Control as="select" className="form_select showentry_sel" id="showEntries" onChange={this.showEntriesChange}>
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                    <option>50</option>
                  </Form.Control>
                </Form.Group>
              </div>
            </div>
            <div className="row mt-2 ">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th>Build Name</th>
                      <th>Build Type Code</th>
                      <th>Description</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data}
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 mt-4">
                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
              {/* End table  */}
            </div>
          </div>
        </main>
        <MDBModal isOpen={this.state.modal2} toggle={this.toggle(2)}>
          <MDBModalHeader toggle={this.toggle(2)}>Add Build Type</MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol md="6" >
                <MDBInput
                  name="name"
                  id="nameID"
                  label="Build Name*"
                  autoComplete="off"
                  value={this.state.fields["name"]}
                  onChange={this.changeHandler.bind(this, "name")}
                />
                <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["nameexists"]}</span></span>
                <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["nameempty"]}</span></span>
              </MDBCol>
              <MDBCol md="6" >
                <select className="browser-default custom-select select_height" id="codeID" value={this.state.fields["buildTypeCode"]} onChange={this.changeHandler.bind(this, "buildTypeCode")}>
                  <option value="">-- Select Build Type Code* --</option>
                  <option value="COM">COM</option>
                  <option value="PWR">PWR</option>
                </select>
                {/* <MDBInput
                  name="code"
                  id="codeID"
                  label="Build Type Code*"
                  autoComplete="off"
                  value={this.state.fields["buildTypeCode"]}
                  onChange={this.changeHandler.bind(this, "buildTypeCode")}
                /> */}
                <span className="error_msg w3-animate-top mt-2">  <span style={{ color: "red" }}>{this.state.errors["buildTypeCodeExists"]}</span></span>
                <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["buildTypeCodeError"]}</span></span>
              </MDBCol>
              <MDBCol md="6" >
                <MDBInput
                  name="description"
                  id="descriptionID"
                  label="Description"
                  autoComplete="off"
                  value={this.state.fields["description"]}
                  onChange={this.changeHandler.bind(this, "description")}
                />
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="danger" onClick={this.toggle(2)}>Close</MDBBtn>
            <MDBBtn color="primary" onClick={this.addBuildtype}>Save</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.modal} toggle={this.sessiontoggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.sessiontoggle}>Session has expired</MDBModalHeader>
          <MDBModalBody>
            Please login again.
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={this.tagOk}>OK</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default buildtypes;