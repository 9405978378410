import React from 'react';
import {Link} from 'react-router-dom';
import { MDBBtn,MDBCol, MDBInput ,MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter} from 'mdbreact';
import * as baseUrl from './commons/constants';

class editOrganization extends React.Component{  
    constructor (props){
        super(props);
        this.state = {
          fields: {id: '', active:'', city:'', company:'', country:'', createdBy:'', createdDate:'', identifier:'',latitude:'', longitude:'', officeAddress:'',organisationType:'',root:'',state:'',updatedBy:'',updatedDate:'',url:''},
          sucess:{},
          errors: {},
          activeItem: "1",
          model:"false",
          page_title:"Edit Organization",
          dataArray:[],
          disabledAttr:true,
          modalsession:false,
          disabled:'true',
        }
      
        this.backToList = this.backToList.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.redirectToListPage = this.redirectToListPage.bind(this)
        this.toggle = this.toggle.bind(this)
        this.tagOk = this.tagOk.bind(this);
        this.getProducts=this.getProducts.bind(this);
        this.togglesession = this.togglesession.bind(this);
}

tagOk(){
  this.props.history.push('/organisationList');
}

toggle = () => {
  this.setState({
  modal: !this.state.modal
  });
  }

  async togglesession(){
    
    this.setState({
    modalsession: !this.state.modalsession
    });
  }
  
  
  sessiontagOk(){
    this.props.history.push('/');
    window.location.reload();
  }

 //This is for making user should enter only numbers
 numberHandler(field, e){ 
    let fields = this.state;
    if(e.target.validity.valid){
      fields[field] = e.target.value; 
      this.setState({fields});
    }else if(e.target.value==""){
      fields[field] = e.target.value; 
      this.setState({fields});
    }

  }
  organizationChange(){
    this.getProducts(this);
  }
  getProducts(e){
    var orgId=document.getElementById("organization").value;
    this.setState({orgId:orgId})
    
    if(orgId!=''){
      var url = baseUrl.URLPath + "dashboard/"+orgId+"/products";
      fetch(url,{
        method: "GET",
        headers: {
          "content-type":"application/json",
          "Authorization":localStorage.getItem("Authorization"),
          "tenant":localStorage.getItem("tenant")
        }
        })
        .then((resp) => {
          
          if(resp.status==401){
            // this.toggle();
           // alert("Access token is expired, please login again")
           localStorage.clear();
        
         }
         if(resp.status==200){
           return resp.json();
         }      }) 
        .then((response) =>{
          if(response!=undefined){
              
            // alert(JSON.stringify(response));
           this.setState({ dataArray: response});
             } 
        // alert("data"+JSON.stringify(this.state.data));
        // this.setState({ total_charge_point:response.data.chargePointCount })
  
        })
        .catch((error) => {
            console.log(error, "catch the loop")
        })
      }else{
        this.setState({ dataArray:[]});
      }
    }
 
  redirectToListPage(){
    this.props.history.push('/chargepoint')
  }


  validateURL(textval) {
    var urlregex = new RegExp( "^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");
    var var_name = urlregex.test(textval);
    return var_name;
  }

  
  async handleSubmit(event) {
    event.preventDefault();
        
        let errors = {};
        var company= this.state.fields['company'];
        var identifier= this.state.fields['identifier'];
        var officeAddress = this.state.fields['officeAddress'];
        var city= this.state.fields['city'];
        var state= this.state.fields['state'];
        var country= this.state.fields['country'];
        // alert(country);
        var orgurl= this.state.fields['url'];
         if(company==''){
             errors["companyempty"] = "Please enter a organization.";
           }
           if(city==''){
              errors["cityempty"] = "Please enter a city.";
            }
            if(state==''){
             errors["stateempty"] = "Please enter a state.";
            }
            if(orgurl==''){
              errors["urlempty"] = "Please enter a url.";
             }
             if(this.validateURL(orgurl)==false){
              errors["urlempty"] = "Please enter a valid url.";
             }
           if(identifier==""){
                errors["identifierempty"] = "Please enter a identifier.";
           }
           if(officeAddress==""){
                errors["officeAddressempty"] = "Please enter a office address.";
           }
           if(identifier!==''){
             if(!identifier.match(/^[a-zA-Z]+$/)){
              errors["identifierempty"] = "Please enter a valid identifier. It should be alphabetical without space ";
             }
           }
           if(country=='Country'){
             errors["countryempty"] = "Please select the country." ;
          }
   
           if(errors.hasOwnProperty("companyempty")||errors.hasOwnProperty("cityempty")||errors.hasOwnProperty("stateempty") || errors.hasOwnProperty("urlempty")|| errors.hasOwnProperty("identifierempty")|| errors.hasOwnProperty("officeAddressempty")|| errors.hasOwnProperty("countryempty"))
           {}
      
         else{
         let url = baseUrl.URLPath+"admin/v1.0/account/"+this.state.id;
         //localhost:8080/cpms/chargepoints
         let data = {"company":company.toLowerCase(),"identifier":identifier,"officeAddress":officeAddress,"city": city, "state":state, "country":country, "url":orgurl}; 
      fetch(url, {
        method: 'PUT', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          "content-type":"application/json",
          "Authorization":localStorage.getItem("Authorization"),
        }
      
        }).then(resp =>{
          
          if(resp.status==401){
            this.togglesession();
            // alert("Access token is expired, please login again")
            localStorage.clear();
          }
          else if(resp.status==404){
              // errors["entervalidID"]="Please enter a valid charge point id."
            this.setState({errors: errors});
            
           }else if(resp.status==500){
            //  alert("internal server error")
            }
        
         else if(resp.status==409){
            // errors["chargepointexist"]="Charge Point already exists."
            this.setState({
              errors: errors
              });
           }
           else if(resp.status==400){
          //  alert("Bad request")
        }else if(resp.status==200){
          this.toggle()
          //  alert("Charge point added successfully");
          // $("#suc_msg").html(' <strong>Success!</strong> Charge point added successfully.').slideDown();
            //  setTimeout(this.redirectToListPage, 1000);
          }
        })
        .catch(error => alert('Error:' + error));
  }
    this.setState({errors: errors});
}

  changeHandler(field, e){ 
    var company= document.getElementById("companyId").value;
   var officeAddress=document.getElementById("officeAddressIid").value;
    var city=document.getElementById("cityId").value;
    var state=document.getElementById("stateId").value;
    var url=document.getElementById("urlId").value;
  let fields = this.state.fields;
  fields[field] = e.target.value; 
  this.setState({fields,disabledAttr:""});
  if (fields["companyId"] = company){
    this.state.errors["companyempty"] = " ";
 }

if (fields["officeAddressIid"] = officeAddress){
  this.state.errors["officeAddressempty"] = " ";
}
if (fields["cityId"] = city){
  this.state.errors["cityempty"] = " ";
}
if (fields["stateId"] = state){
  this.state.errors["stateempty"] = " ";
}
if (fields["urlId"] = url){
  this.state.errors["urlempty"] = " ";
}

  }

  countryChange(){
    var x = document.getElementById("countryId").value;
    let fields = this.state.fields;
    fields["country"] = x; 
    if(x!="Country"){
   
      this.state.errors["countryempty"] = " " ;
  
    }
    this.setState({fields});
    this.setState({disabledAttr:""});
  }

  backToList(){
    this.props.history.push({
      pathname: '/organisationView',
      state:this.state.id, 
    });
  }
  
  componentDidMount(){
  
  var timeZone=  localStorage.getItem("timezone");
    var orgid = this.props.location.state;
   // var array = url.split('/');
    this.state.id = orgid;
     var url = baseUrl.URLPath + "admin/v1.0/account/" + orgid;
     //localhost:8080/cpms/ocpptags/{ocppTagId}
    fetch(url,{
        method: "GET",
        headers: {
          "content-type":"application/json",
          "Authorization":localStorage.getItem("Authorization")
         }
        })
        .then((resp) => {
          if(resp.status==401){
            // alert("Access token is expired, please login again")
            this.togglesession();
      
            localStorage.clear();
           
          }
          if(resp.status==200){
            return resp.json();
          }
        }) 
        .then((response) =>{
          var data1 = [] ;
          data1= response.organisationDTOS;
          this.setState({
            fields:data1[0]
          })
          if(this.state.fields.country != null && this.state.fields.country != ""){
            document.getElementById('countryId').getElementsByTagName('option')[this.state.fields.country].selected= 'selected';

          }
          console.log("data1"+data1)
        })
        .catch((error) => {
          console.log(error, "catch the loop")
     })
  }

  render() {
    // const products =this.state.dataArray.map((dataArray, index) => {
    //   return(
    //  <option value={dataArray.product_name}>{dataArray.product_name}</option>
    // )
    //     })

  return (
    <>
    <main class="content-div" >
    <p> Organization
              <div className="breadcrumb_div">
              Settings &gt; <Link to="/organisationList"><span>Organization</span></Link > &gt; <Link  to={{
                   pathname:'/organisationView', 
                   state:this.state.id, 
                   }}  > <span className="">View</span></Link>&gt; <span className="breadcrumb_page">Edit</span>
              </div>
          </p>
    {/* <p className="breadcrumb_div">Charge Point > <Link to="/chargePoint" > <span className="">Overview</span></Link> >  Add</p> */}
    <form
        className='needs-validation'
        onSubmit={this.submitHandler}
        noValidate
      >
      <div className="page-outerdiv">
      {/* <div className="breadcrumb_div">Settings > <Link to="/organisationList" > <span className="">Organisation</span></Link> >  
      <Link  to={{
                   pathname:'/organisationView', 
                   state:this.state.id, 
                   }}  > <span className="">View</span></Link> > Edit</div> */}
        <div className="row mb-3 mt-3">
            <MDBCol md="4">
                <MDBInput
                  name="company"
                  value={this.state.fields["company"]}
                  onChange={this.changeHandler.bind(this, "company")}
                  type="text"
                  id="companyId"
                  label="Organization"
                  autoComplete="off"
                  className="text_lowercase"
                >
                <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["companyempty"]}</span></span> 
                </MDBInput>
            </MDBCol>
            <MDBCol md="4">
                <MDBInput
                  name="identifier"
                  value={this.state.fields["identifier"]}
                  onChange={this.changeHandler.bind(this, "identifier")}
                  type="text"
                  id="identifierId"
                  label="Identifier (Short Name)"
                  autoComplete="off"
                  disabled="true"
                >
                   <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["identifierempty"]}</span></span> 
                </MDBInput>
            </MDBCol>

          <MDBCol md="4" >
                <MDBInput
                  name="officeAddress"
                  value={this.state.fields["officeAddress"]}
                  onChange={this.changeHandler.bind(this, "officeAddress")}
                  min="0"
                  id="officeAddressIid"
                  label="Office Address"
                  autoComplete="off"
                >
                    <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["officeAddressempty"]}</span></span> 
                </MDBInput>
              </MDBCol>

            
              <MDBCol md="4" className="mt-2">
                <MDBInput
                  value={this.state.fields["city"]}
                  onChange={this.changeHandler.bind(this, "city")}
                  type="text"
                  id="cityId"
                  name="city"
                  label="City"
                >
                  <span className="error_msg w3-animate-top"><span style={{color: "red"}}>{this.state.errors["cityempty"]}</span></span> 
                </MDBInput>
              </MDBCol>
              <MDBCol md="4" className="mt-2">
                <MDBInput
                  value={this.state.fields["state"]}
                  onChange={this.changeHandler.bind(this, "state")}
                  onInput={this.numberHandler.bind(this,"state")}
                  type="text" 
                  id="stateId"
                  name="state"
                  label="State"
                >
               <span className="error_msg w3-animate-top"><span style={{color:"red"}}>{this.state.errors["stateempty"]}</span></span> 
             </MDBInput>
             </MDBCol>
              <MDBCol md="4" className="mt-2">
              <select className="browser-default custom-select select_height" id="countryId"  onChange={this.countryChange.bind(this)}>
                    <option id="Country" value="Country">Country</option>
                    <option id="Afghanistan" value="Afghanistan">Afghanistan</option>
                    <option id="Åland Islands" value="Åland Islands">Åland Islands</option>
                    <option id="Albania" value="Albania">Albania</option>
                    <option id="Algeria" value="Algeria">Algeria</option>
                    <option id="American Samoa" value="American Samoa">American Samoa</option>
                    <option id="Andorra" value="Andorra">Andorra</option>
                    <option id="Angola" value="Angola">Angola</option>
                    <option id="Anguilla" value="Anguilla">Anguilla</option>
                    <option id="Antarctica" value="Antarctica">Antarctica</option>
                    <option id="Antigua and Barbuda" value="Antigua and Barbuda">Antigua and Barbuda</option>
                    <option id="Argentina" value="Argentina">Argentina</option>
                    <option id="Armenia" value="Armenia">Armenia</option>
                    <option id="Aruba" value="Aruba">Aruba</option>
                    <option id="Australia" value="Australia">Australia</option>
                    <option id="Austria" value="Austria">Austria</option>
                    <option id="Azerbaijan" value="Azerbaijan">Azerbaijan</option>
                    <option id="Bahamas" value="Bahamas">Bahamas</option>
                    <option id="Bahrain" value="Bahrain">Bahrain</option>
                    <option id="Bangladesh" value="Bangladesh">Bangladesh</option>
                    <option id="Barbados" value="Barbados">Barbados</option>
                    <option id="Belarus" value="Belarus">Belarus</option>
                    <option id="Belgium" value="Belgium">Belgium</option>
                    <option id="Belize" value="Belize">Belize</option>
                    <option id="Benin" value="Benin">Benin</option>
                    <option id="ermuda" value="Bermuda">Bermuda</option>
                    <option id="Bhutan" value="Bhutan">Bhutan</option>
                    <option id="Bolivia" value="Bolivia">Bolivia</option>
                    <option id="Bosnia and Herzegovina" value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                    <option id="Botswana" value="Botswana">Botswana</option>
                    <option id="Bouvet Island" value="Bouvet Island">Bouvet Island</option>
                    <option id="Brazil" value="Brazil">Brazil</option>
                    <option id="British Indian Ocean Territory" value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                    <option id="Brunei Darussalam" value="Brunei Darussalam">Brunei Darussalam</option>
                    <option id="Bulgaria" value="Bulgaria">Bulgaria</option>
                    <option id="Burkina Faso" value="Burkina Faso">Burkina Faso</option>
                    <option id="Cambodia" value="Cambodia">Cambodia</option>
                    <option id="Cameroon"value="Cameroon">Cameroon</option>
                    <option id="Canada"value="Canada">Canada</option>
                    <option id="Cape Verde" value="Cape Verde">Cape Verde</option>
                    <option id="Cayman Islands" value="Cayman Islands">Cayman Islands</option>
                    <option id="Central African Republic" value="Central African Republic">Central African Republic</option>
                    <option id="Chad" value="Chad">Chad</option>
                    <option id="Chile" value="Chile">Chile</option>
                    <option id="China" value="China">China</option>
                    <option  id="Christmas Island"value="Christmas Island">Christmas Island</option>
                    <option id="Cocos (Keeling) Islands" value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                    <option id="Colombia" value="Colombia">Colombia</option>
                    <option id="Comoros" value="Comoros">Comoros</option>
                    <option id="Congo" value="Congo">Congo</option>
                    <option id="Congo, The Democratic Republic of The" value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                    <option id="Cook Islands" value="Cook Islands">Cook Islands</option>
                    <option id="Costa Rica" value="Costa Rica">Costa Rica</option>
                    <option id="Cote D'ivoire" value="Cote D'ivoire">Cote D'ivoire</option>
                    <option id="Croatia" value="Croatia">Croatia</option>
                    <option id="Cuba" value="Cuba">Cuba</option>
                    <option  id="Cyprus"value="Cyprus">Cyprus</option>
                    <option id="Czech Republic" value="Czech Republic">Czech Republic</option>
                    <option id="Denmark" value="Denmark">Denmark</option>
                    <option id="Djibouti" value="Djibouti">Djibouti</option>
                    <option  id="Dominica"value="Dominica">Dominica</option>
                    <option id="Dominican Republic" value="Dominican Republic">Dominican Republic</option>
                    <option id="Ecuador" value="Ecuador">Ecuador</option>
                    <option id="Egypt" value="Egypt">Egypt</option>
                    <option id="El Salvador" value="El Salvador">El Salvador</option>
                    <option id="Equatorial Guinea" value="Equatorial Guinea">Equatorial Guinea</option>
                    <option  id="Eritrea"value="Eritrea">Eritrea</option>
                    <option id="Estonia" value="Estonia">Estonia</option>
                    <option id="Ethiopia" value="Ethiopia">Ethiopia</option>
                    <option  id="Falkland Islands (Malvinas)"value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                    <option id="Faroe Islands" value="Faroe Islands">Faroe Islands</option>
                    <option  id="Fiji"value="Fiji">Fiji</option>
                    <option  id="Finland"value="Finland">Finland</option>
                    <option  id="France"value="France">France</option>
                    <option id="French Guiana" value="French Guiana">French Guiana</option>
                    <option id="French Polynesia" value="French Polynesia">French Polynesia</option>
                    <option  id="French Southern Territories"value="French Southern Territories">French Southern Territories</option>
                    <option id="Gabon" value="Gabon">Gabon</option>
                    <option id="Gambia" value="Gambia">Gambia</option>
                    <option id="Georgia" value="Georgia">Georgia</option>
                    <option id="Germany" value="Germany">Germany</option>
                    <option id="Ghana" value="Ghana">Ghana</option>
                    <option id="Gibraltar" value="Gibraltar">Gibraltar</option>
                    <option id="Greece" value="Greece">Greece</option>
                    <option id="Greenland" value="Greenland">Greenland</option>
                    <option id="Grenada" value="Grenada">Grenada</option>
                    <option id="Guadeloupe" value="Guadeloupe">Guadeloupe</option>
                    <option id="Guam" value="Guam">Guam</option>
                    <option id="Guatemala" value="Guatemala">Guatemala</option>
                    <option id="Guernsey" value="Guernsey">Guernsey</option>
                    <option id="Guinea" value="Guinea">Guinea</option>
                    <option  id="Guinea-bissau"value="Guinea-bissau">Guinea-bissau</option>
                    <option  id="Guyana"value="Guyana">Guyana</option>
                    <option  id="Haiti" value="Haiti">Haiti</option>
                    <option id="Heard Island and Mcdonald Islands" value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                    <option id="Holy See (Vatican City State)" value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                    <option id="Honduras" value="Honduras">Honduras</option>
                    <option id="Hong Kong" value="Hong Kong">Hong Kong</option>
                    <option id="Hungary" value="Hungary">Hungary</option>
                    <option id="Iceland" value="Iceland">Iceland</option>
                    <option id="India" value="India">India</option>
                    <option id="Indonesia" value="Indonesia">Indonesia</option>
                    <option id="Iran, Islamic Republic of" value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                    <option id="Iraq" value="Iraq">Iraq</option>
                    <option id="Ireland" value="Ireland">Ireland</option>
                    <option id="Isle of Man" value="Isle of Man">Isle of Man</option>
                    <option id="Israel" value="Israel">Israel</option>
                    <option id="Italy" value="Italy">Italy</option>
                    <option id="Jamaica" value="Jamaica">Jamaica</option>
                    <option id="Japan" value="Japan">Japan</option>
                    <option id="Jersey" value="Jersey">Jersey</option>
                    <option id="Jordan" value="Jordan">Jordan</option>
                    <option id="Kazakhstan" value="Kazakhstan">Kazakhstan</option>
                    <option id="Kenya" value="Kenya">Kenya</option>
                    <option  id="Kiribati"value="Kiribati">Kiribati</option>
                    <option id="Korea, Democratic People's Republic of" value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                    <option id="Korea, Republic of" value="Korea, Republic of">Korea, Republic of</option>
                    <option id="Kuwait" value="Kuwait">Kuwait</option>
                    <option id="Kyrgyzstan" value="Kyrgyzstan">Kyrgyzstan</option>
                    <option id="Lao People's Democratic Republic" value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                    <option id="Latvia" value="Latvia">Latvia</option>
                    <option id="Lebanon" value="Lebanon">Lebanon</option>
                    <option id="Lesotho" value="Lesotho">Lesotho</option>
                    <option id="Liberia" value="Liberia">Liberia</option>
                    <option id="Libyan Arab Jamahiriya" value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                    <option id="Liechtenstein" value="Liechtenstein">Liechtenstein</option>
                    <option id="Lithuania" value="Lithuania">Lithuania</option>
                    <option id="Luxembourg" value="Luxembourg">Luxembourg</option>
                    <option id="Macao" value="Macao">Macao</option>
                    <option id="Macedonia, The Former Yugoslav Republic of" value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                    <option  id="Madagascar"value="Madagascar">Madagascar</option>
                    <option id="Malawi" value="Malawi">Malawi</option>
                    <option id="Malaysia" value="Malaysia">Malaysia</option>
                    <option id="Maldives" value="Maldives">Maldives</option>
                    <option id="Mali" value="Mali">Mali</option>
                    <option id="Malta" value="Malta">Malta</option>
                    <option id="Marshall Islands" value="Marshall Islands">Marshall Islands</option>
                    <option id="Martinique" value="Martinique">Martinique</option>
                    <option id="Mauritania" value="Mauritania">Mauritania</option>
                    <option id="Mauritius" value="Mauritius">Mauritius</option>
                    <option id="Mayotte" value="Mayotte">Mayotte</option>
                    <option id="Mexico" value="Mexico">Mexico</option>
                    <option id="Micronesia, Federated States of" value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                    <option id="Moldova, Republic of" value="Moldova, Republic of">Moldova, Republic of</option>
                    <option id="Monaco" value="Monaco">Monaco</option>
                    <option id="Mongolia" value="Mongolia">Mongolia</option>
                    <option id="Montenegro" value="Montenegro">Montenegro</option>
                    <option id="Montserrat" value="Montserrat">Montserrat</option>
                    <option id="Morocco" value="Morocco">Morocco</option>
                    <option id="Mozambique" value="Mozambique">Mozambique</option>
                    <option id="Myanmar" value="Myanmar">Myanmar</option>
                    <option id="Namibia" value="Namibia">Namibia</option>
                    <option  id="Nauru" value="Nauru">Nauru</option>
                    <option id="Nepal" value="Nepal">Nepal</option>
                    <option  id="Netherlands" value="Netherlands">Netherlands</option>
                    <option id="Netherlands Antilles" value="Netherlands Antilles">Netherlands Antilles</option>
                    <option id="New Caledonia" value="New Caledonia">New Caledonia</option>
                    <option id="New Zealand"value="New Zealand">New Zealand</option>
                    <option id="Nicaragua" value="Nicaragua">Nicaragua</option>
                    <option id="Niger" value="Niger">Niger</option>
                    <option id="Nigeria" value="Nigeria">Nigeria</option>
                    <option id="Niue" value="Niue">Niue</option>
                    <option id="Norfolk Island" value="Norfolk Island">Norfolk Island</option>
                    <option id="Northern Mariana Islands" value="Northern Mariana Islands">Northern Mariana Islands</option>
                    <option id="Norway" value="Norway">Norway</option>
                    <option id="Oman"  value="Oman">Oman</option>
                    <option id="Pakistan" value="Pakistan">Pakistan</option>
                    <option id="Palau" value="Palau">Palau</option>
                    <option id="Palestinian Territory, Occupied" value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                    <option  id="Panama"value="Panama">Panama</option>
                    <option  id="Papua New Guinea"value="Papua New Guinea">Papua New Guinea</option>
                    <option id="Paraguay" value="Paraguay">Paraguay</option>
                    <option  id="Peru"value="Peru">Peru</option>
                    <option id="Philippines" value="Philippines">Philippines</option>
                    <option id="Pitcairn" value="Pitcairn">Pitcairn</option>
                    <option id="Poland" value="Poland">Poland</option>
                    <option id="Portugal" value="Portugal">Portugal</option>
                    <option  id="Puerto Rico"value="Puerto Rico">Puerto Rico</option>
                    <option id="Qatar" value="Qatar">Qatar</option>
                    <option id="Reunion" value="Reunion">Reunion</option>
                    <option id="Romania" value="Romania">Romania</option>
                    <option id="Russian Federation" value="Russian Federation">Russian Federation</option>
                    <option id="Rwanda" value="Rwanda">Rwanda</option>
                    <option id="Saint Helena" value="Saint Helena">Saint Helena</option>
                    <option id="Saint Kitts and Nevis" value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                    <option id="Saint Lucia" value="Saint Lucia">Saint Lucia</option>
                    <option id="Saint Pierre and Miquelon" value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                    <option id="Saint Vincent and The Grenadines" value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                    <option id="Samoa" value="Samoa">Samoa</option>
                    <option id="San Marino" value="San Marino">San Marino</option>
                    <option id="Sao Tome and Principe" value="Sao Tome and Principe">Sao Tome and Principe</option>
                    <option id="Saudi Arabia" value="Saudi Arabia">Saudi Arabia</option>
                    <option id="Senegal" value="Senegal">Senegal</option>
                    <option id="Serbia" value="c">Serbia</option>
                    <option id="Seychelles" value="Seychelles">Seychelles</option>
                    <option id="Sierra Leone" value="Sierra Leone">Sierra Leone</option>
                    <option id="Singapore" value="Singapore">Singapore</option>
                    <option id="Slovakia" value="Slovakia">Slovakia</option>
                    <option id="Slovenia" value="Slovenia">Slovenia</option>
                    <option id="Solomon Islands" value="Solomon Islands">Solomon Islands</option>
                    <option id="Somalia" value="Somalia">Somalia</option>
                    <option id="South Africa" value="South Africa">South Africa</option>
                    <option id="South Georgia and The South Sandwich Islands" value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                    <option id="Spain" value="Spain">Spain</option>
                    <option id="Spain"  value="Spain">Spain</option>
                    <option id="Spain"value="Spain">Spain</option>
                    <option id="Spain" value="Spain">Spain</option>
                    <option id="Spain" value="Spain">Spain</option>
                    <option  id="Sri Lanka"value="Sri Lanka">Sri Lanka</option>
                    <option id="Sudan" value="Sudan">Sudan</option>
                    <option id="Suriname" value="Suriname">Suriname</option>
                    <option  id="Svalbard and Jan Mayen"value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                    <option id="Swaziland" value="Swaziland">Swaziland</option>
                    <option id="Sweden" value="Sweden">Sweden</option>
                    <option id="Switzerland" value="Switzerland">Switzerland</option>
                    <option id="Syrian Arab Republicr" value="Syrian Arab Republic">Syrian Arab Republic</option>
                    <option id="Taiwan, Province of China" value="Taiwan, Province of China">Taiwan, Province of China</option>
                    <option id="Tajikistan" value="Tajikistan">Tajikistan</option>
                    <option id="Tanzania, United Republic of" value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                    <option id="Thailand" value="Thailand">Thailand</option>
                    <option id="Timor-leste" value="Timor-leste">Timor-leste</option>
                    <option id="Togo" value="Togo">Togo</option>
                    <option id="Tokelau" value="Tokelau">Tokelau</option>
                    <option id="Tonga" value="Tonga">Tonga</option>
                    <option id="Trinidad and Tobago" value="Trinidad and Tobago">Trinidad and Tobago</option>
                    <option id="Tunisia" value="Tunisia">Tunisia</option>
                    <option id="Turkey" value="Turkey">Turkey</option>
                    <option  id="Turkmenistan"value="Turkmenistan">Turkmenistan</option>
                    <option id="Turks and Caicos Islands" value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                    <option id="Tuvalu" value="Tuvalu">Tuvalu</option>
                    <option id="Uganda" value="Uganda">Uganda</option>
                    <option id="Ukraine" value="Ukraine">Ukraine</option>
                    <option id="United Arab Emirates"value="United Arab Emirates">United Arab Emirates</option>
                    <option id="United Kingdom" value="United Kingdom">United Kingdom</option>
                    <option id="United States" value="United States">United States</option>
                    <option id="United States Minor Outlying Islands" value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                    <option id="Uruguay" value="Uruguay">Uruguay</option>
                    <option id="Uzbekistan" value="Uzbekistan">Uzbekistan</option>
                    <option id="Vanuatu" value="Vanuatu">Vanuatu</option>
                    <option id="Venezuela" value="Venezuela">Venezuela</option>
                    <option  id="Viet Nam"value="Viet Nam">Viet Nam</option>
                    <option id="Virgin Islands, British" value="Virgin Islands, British">Virgin Islands, British</option>
                    <option id="Virgin Islands, U.S." value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                    <option id="Wallis and Futuna"value="Wallis and Futuna">Wallis and Futuna</option>
                    <option id="Western Sahara"value="Western Sahara">Western Sahara</option>
                    <option  id="Yemen"value="Yemen">Yemen</option>
                    <option id="Zambia" value="Zambia">Zambia</option>
                    <option id="Zimbabwe" value="Zimbabwe">Zimbabwe</option>
              </select>
              <span className="error_msg w3-animate-top mt-1">  <span style={{color: "red"}}>{this.state.errors["countryempty"]}</span></span> 
              </MDBCol>
              <MDBCol md="4" className="mt-2">
                <MDBInput
                  value={this.state.fields["url"]}
                  onChange={this.changeHandler.bind(this, "url")}
                  onInput={this.numberHandler.bind(this,"url")}
                  type="text" 
                  id="urlId"
                  name="url"
                  label="Url"
                >
               <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["urlempty"]}</span></span> 
             </MDBInput>
             </MDBCol>
              {/* <MDBCol md="4">
              <MDBInput
                value={this.state.fields["locationLatitude"]}
                onChange={this.changeHandler.bind(this, "locationLatitude")}
                type="text"
                name="adminAddress"
                label="Latitude"
            >
            <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["entervalidlat"]}</span></span> 
              </MDBInput>
              </MDBCol> */}
            {/* <MDBCol md="4" className="mt-1" >
              <MDBInput
                value={this.state.fields["locationLongitude"]}
                onChange={this.changeHandler.bind(this, "locationLongitude")}
                type="text"
                id="longitudeId"
                name="Longitude"
                label="Longitude"
              >
            </MDBInput>
    <span className="error_msg w3-animate-top"><span style={{color: "red"}}>{this.state.errors["entervalidlong"]}</span></span> 
 </MDBCol>   */}
  {/* <MDBCol md="12" className="mt-4">
    <GeoLocation google={this.props.google} center={{lat:18.520,lng:73.8567}} height='300px' zoom={15} />
  </MDBCol>  */}
     <MDBCol md="12" className="text-right mt-3">
      <button type='submit' disabled={this.state.disabledAttr} onClick={this.handleSubmit} className="btn_primary mr-2">
      Save <i class="fas fa-save ml-2"></i>
      </button>
      <button onClick={this.backToList} className="btn_cancel">
      Cancel <i class="fas fa-times ml-2"></i>
      </button>
    </MDBCol> 
</div>


    </div>
      
    </form>
    </main>
    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
      <MDBModalHeader toggle={this.toggle}>Success</MDBModalHeader>
      <MDBModalBody>
      Organization updated successfully.
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn> */}
      <MDBBtn color="primary" onClick={this.tagOk}>OK</MDBBtn>
      </MDBModalFooter>
      </MDBModal>


      {/* popup for session expire */}

 <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
      <MDBModalHeader toggle={this.togglesession}>Session has expired</MDBModalHeader>
      <MDBModalBody>
       Please login again.
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
      <MDBBtn color="primary" onClick={this.sessiontagOk}>OK</MDBBtn>
      </MDBModalFooter>
      </MDBModal>
    </>
  );
}
}
 export default editOrganization;


