import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { hasPermission } from './views/commons/auth';
import * as baseUrl from './views/commons/constants';

//components imports
import addBuild from './views/addBuild';
import addUserManagement from './views/addUserManagement';
import buildOrganization from './views/buildOrganization';
import Builds from './views/builds';
import ChargePoint from './views/chargePoint';
import cloudUserHistory from './views/cloudUserHistory';
import cloudUserSignup from './views/cloudUserSignup';
import editBuild from './views/editBuild';
import editUser from './views/editUser';
import forgotPassword from './views/forgotPassword';
import importInventory from './views/importInventory';
import InventoryList from './views/inventoryList';
import Footer from './views/layout/Footer';
import Header from './views/layout/Header';
import Sidebar from './views/layout/Sidebar';
import Login from './views/login';
import organisationAdd from './views/organisationAdd';
import OrganisationEdit from './views/organisationEdit';
import OrganisationList from './views/organisationList';
import organisationView from './views/organisationView';
import Preferences from './views/preferences';
import productList from './views/productList';
import resetPassword from './views/resetPassword';
import Reports from './views/screens/reports/Reports';
import Statistics from './views/screens/reports/Statistics';
import ConvenienceFee from './views/screens/settings/ConvenienceFee';
import updatePassword from './views/updatePassword';
import userHistory from './views/userHistory';
import userLoginHistory from './views/userLoginHistory';
import UserManagementController from './views/userManagementController';
import viewUser from './views/viewUser';
import wallBoxUserList from './views/wallBoxUserList';
import buildTypes from './views/buildTypes';
import BillDetails from './views/screens/BillDetails/BillDetails';
import BillingHistory from './views/billingHystory';
import ChargersReports from './views/chargersReport';
import PaymentGateway from './views/screens/settings/PaymentGateway';

//css imports
import './App.css';

const history = createBrowserHistory();

export default class App extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      value: "",
      user: true,
      getOperationList:[]
    }
  }

  UNSAFE_componentWillMount() {
    var location = window.location.href;
    var id = location.split("/");

    if ((id.length == 4 && id[3] == "") || (id[4] == "resetpassword" || id[4] == "invite" || id[3] == "forgotPassword")) {
      this.setState({ value: "1" });
    } else {
      var user = localStorage.getItem("user");
      if (user == null) {
        window.location.replace('/');
        this.setState({ user: false });
      } else {
        this.getCustomization();
      }
    }
  }

  getCustomization=()=> {
    let tenant=localStorage.getItem('tenant');
    let url = baseUrl.LoginPath +"/api/admin/v1.0/account/tenant/customization?orgName="+tenant;

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("Authorization")
      }
    }).then(resp => {
      if (resp.status == 200) {
        return resp.json();
      }
    }).then(response => {
     this.setState({getOperationList:response})
    }).catch(error => console.log('error', error))
  } 

  render(){
    if(this.state.value === "1"){
      return (
        <BrowserRouter history={history}>
            <Route exact path="/"  component={Login} />
            <Route exact path="/forgotPassword"  component={forgotPassword} />
            <Route  path="/:token/resetpassword" component={resetPassword} />
		    	  <Route exact path="/updatePassword"  component={updatePassword} />
            <Route exact path="/cloudUserSignup"  component={cloudUserSignup} />
        </BrowserRouter>
      );
    }else if(this.state.user){
      const user = {
        permissions: []
      };
      if (localStorage.getItem("roleAccess") !== undefined) {
        user.permissions = localStorage.getItem("roleAccess");
      }

      return (
        <BrowserRouter history={history}>
          <div className="root">
            <div id="header">
              <Header />
            </div>
            <Sidebar user={user} />
            <Route path="/preferences" render={(props) => <Preferences {...props} deeplink_R={hasPermission(user, ['Deeplink_Credentials_R'])} deeplink_W={hasPermission(user, ['Deeplink_Credentials_W'])} />} />
            {
              this.state.getOperationList?.map((operationObject,index)=>{
                if(operationObject.operation == "Wall Box" && operationObject.active == true){
                  return operationObject.subOperations.map((operation, i)=>{
                    if(operation.name == "Overview" && operation.access == true){
                      return <>
                      {/* Chargepoints */}
                      {hasPermission(user, ['ChargePoint_R']) && <Route path="/wallboxList" render={(props) => <ChargePoint ChargePoint_W={hasPermission(user, ['ChargePoint_W'])} {...props} />} />}
                      <Route path="/wallBoxUserList" component={wallBoxUserList} />
                      <Route path="/cloudWallboxList" component={ChargePoint} />
                      <Route path="/userHistory" component={userHistory} />
                      <Route path="/cloudUserHistory" component={cloudUserHistory} />
                      <Route path="/billDetails" component={BillDetails} />
                    </>
                    }
                  })
                }else if(operationObject.operation == "Report" && operationObject.active == true){
                  return operationObject.subOperations.map((operation, i)=>{
                    if(operation.name == "Statistics" && operation.access == true){
                      return <>
                      {/* Reports */}
                      {hasPermission(user, ['Statistics_R']) && <Route path="/reports" render={(props) => <Reports {...props} />} />}
                    </>
                    } else if (operation.name == "User Statistics" && operation.access == true) {
                      return <>
                        {hasPermission(user, ['UserStatistics_R']) && <Route path="/statistics" render={(props) => <Statistics {...props} />} />}
                      </>
                    } else if (operation.name == "Charging Sessions" && operation.access == true) {
                      return <>
                        {hasPermission(user, ['StatisticsHistory_R']) && <Route path="/transactions" render={(props) => <BillingHistory {...props} />} />}
                      </>
                    } else if (operation.name == "Chargers Report" && operation.access == true) {
                      return <>
                        {hasPermission(user, ['ChargersReport_R']) && <Route path="/chargersReport" render={(props) => <ChargersReports {...props} />} />}
                      </>
                    }
                  })
                }else if(operationObject.operation == "FOTA" && operationObject.active == true){
                  return operationObject.subOperations.map((operation, i)=>{
                    if(operation.name == "Builds" && operation.access == true){
                      return <>
                      {/* Reports */}
                      {hasPermission(user, ['Builds_R']) && <Route path="/builds" render={(props) => <Builds ChargePoint_W={hasPermission(user, ['builds_R'])} {...props} />} />}
                      {hasPermission(user, ['Builds_R']) && <Route path="/addBuild" component={addBuild} />}
                      {hasPermission(user, ['Builds_R']) && <Route path="/editBuild" component={editBuild} />}
                      {hasPermission(user, ['Builds_R']) && <Route path="/buildOrganization" component={buildOrganization} />}
                    </>
                    } else if (operation.name == "Products" && operation.access == true) {
                      return <> 
                        {hasPermission(user, ['Products_R']) && <Route path="/productList" component={productList} />}
                        {hasPermission(user, ['Products_R']) && <Route path="/buildTypes" component={buildTypes} />}
                      </>
                    }
                  })
                }else if(operationObject.operation == "Import Inventory" && operationObject.active == true){
                  return <>
                  {hasPermission(user, ['ImportInventory_R']) && <Route path="/importInventory" component={importInventory} />}
                  </>
                }else if(operationObject.operation == "Settings" && operationObject.active == true){
                 return operationObject.subOperations.map((operation,i)=>{
                    if(operation.name == "Users" && operation.access == true){
                      return <>
                      {/* UserManagement Pages */}
                      {hasPermission(user, ['Users_R']) && <Route path="/userManagementController" component={UserManagementController} />}
                      {hasPermission(user, ['Users_R']) && <Route path="/addUserManagement" component={addUserManagement} />}
                      {hasPermission(user, ['Users_R']) && <Route path="/viewUser" component={viewUser} />}
                      {hasPermission(user, ['Users_R']) && <Route path="/editUser" component={editUser} />}
                      <Route path="/userLoginHistory" component={userLoginHistory} />
                      </>
                    }
                    else if(operation.name == "Organization" && operation.access == true){
                      return <>
                      {hasPermission(user, ['Organizations_R']) && <Route path="/organisationList" render={(props) => <OrganisationList ChargePoint_W={hasPermission(user, ['Organizations_W'])} {...props} />} />}
                      {hasPermission(user, ['Organizations_W']) && <Route path="/organisationAdd" component={organisationAdd} />}
                      {hasPermission(user, ['Organizations_R']) && <Route path="/organisationView" component={organisationView} />}
                      {hasPermission(user, ['Organizations_R']) && <Route path="/organisationEdit" render={(props) => <OrganisationEdit ChargePoint_W={hasPermission(user, ['Organizations_W'])} {...props} />} />}
                      </>
                    }else if(operation.name == "Convenience Fee" && operation.access == true){
                      return <>
                      {hasPermission(user, ['ConvenienceFee_R']) && <Route path="/convenienceFee" component={ConvenienceFee} />}
                      </>
                    }else if(operation.name == "Payment Gateway" && operation.access == true){
                      return <>
                      {hasPermission(user, ['PaymentGateway_R']) && <Route path="/paymentGateway" component={PaymentGateway} />}
                      </>
                    }
                  })
                }else if(operationObject.operation == "Inventory" && operationObject.active == true){
                  return <>
                      {hasPermission(user, ['Inventory_R']) && <Route path="/inventory" render={(props) => <InventoryList {...props} />} />}
                      </>
                }
              })
            }
            <Footer />
          </div>
        </BrowserRouter>
      );
    }
  }
}