import React from 'react';
import * as baseUrl from '../commons/constants';


class Footer extends React.Component {

    render() {
        const year = new Date().getFullYear();
        return (
            <footer className="page-footer pt-0 mt-5 rgba-stylish-light fixed-bottom">
                {/* Copyright */}
                <div className="footer-copyright py-2 text-center">
                    <div className="container-fluid footer_div">
                        <a href={baseUrl.DataPrivacyPath} rel="noopener noreferrer" target="_blank" style={{ textDecoration: "none" }}>
                            <span className="title_font"> © Webasto Charging Systems {year} </span>
                        </a>
                        <div style={{ float: "right" }}>
                            <a href={baseUrl.Imprint} rel="noopener noreferrer" target="_blank" style={{ textDecoration: "none" }}>
                                <span className="title_font"> Imprint</span>
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;