import React from 'react';
import { MDBBtn,MDBRow,MDBCol} from 'mdbreact';
import * as baseUrl from './commons/constants';
import Datetime from 'react-datetime';
import {Link} from 'react-router-dom';

class Ocppnewtag extends React.Component{  
    constructor (props) {
        super(props);
        this.state = {
          hideActionBtn:"none", // show hide btn varible based on permission 
          writePermission:this.props.ChargePoint_W, // ChargePoint_W conndition
          fields:{
            firstName: '',
            lastName:'',
            email:'',
            mobileNumber:'',
            role:'',
            status:'',
             active:'',
             rfid:''
          
        }
        }
        this.deleteTag = this.deleteTag.bind(this)
        this.editTag = this.editTag.bind(this)
        this.redirectToListPage = this.redirectToListPage.bind(this)
        this.backToList = this.backToList.bind(this)
        this.toggleChange = this.toggleChange.bind(this)
      
        this.setValue = this.setValue.bind(this)
        this.setStartDate = this.setStartDate.bind(this)
}


 backToList(){
  this.props.history.push('/userManagementController');
}
//set date to input of datetime picker 


setStartDate(date){
  // this.setState({startDate:date});
  // var tempDate = new Date(date);
  // var date1 = (tempDate.getMonth()+1)  + '-' + tempDate.getDate() + '-' + tempDate.getFullYear() +' '+ tempDate.getHours()+':'+ tempDate.getMinutes();
  // this.setState({startDate1:date1});
 }

setValue(){

}


componentDidMount(){
 // permission related code

  
 
    var userDetails = this.props.location.state;
this.setState({fields:userDetails});
   // var array = url.split('/');
   
    }
  
toggleChange = () => {
  var x = document.getElementById("myCheck").checked;  
  if(x){
      this.state.fields.blocked="1";
       }
     else{
       this.state.fields.blocked="0";
   }
    
}
transactionChange = () => {
 
  var y = document.getElementById("transcheck").checked;  
  if(y){
      this.state.fields.inTransaction="1";
     }
     else{
       this.state.fields.inTransaction="0";
      }
    
}



redirectToListPage(){
  this.props.history.push('/Ocpptags')
}




  editTag(){
   
    this.props.history.push('./editUser');
  }

// Delete tag 
  deleteTag(){
    this.setState({
      modal: !this.state.modal
    });
    let errors = {};
    let url = baseUrl.URLPath + "ocpptags/delete/" + this.state.id; 
    fetch(url, 
          {
            method: 'DELETE', // or 'PUT'
            body: JSON.stringify("NULL"), // data can be `string` or {object}!
            headers:{
              "content-type":"application/json",
              "Authorization":localStorage.getItem("Authorization"),
             
            }
          }
        ).then(resp =>{
            if(resp.status==404){
              this.setState({errors: errors});
              return resp.json();
            }
            else if(resp.status==500){

              alert("internal server error")

            } else if(resp.status==400){

              alert("Bad request")

            }else{
              this.props.history.push('/Ocpptags');
              // $("#suc_msg").html(' <strong>Success!</strong>Charge Point Tag Id deleted successfully.').slideDown();
              // setTimeout(this.redirectToListPage, 3000);
            }
      })
       .catch(error => alert('Error:' + error));
       this.setState({errors: errors});
  }


render() {
  var yesterday = Datetime.moment().subtract( 1, 'day' );
  var valid = function( current ){
      return current.isAfter( yesterday );
  };
return(
  <>
    <main class="content-div" >
      {/* <Header page_title ={this.state.page_title} /> */}
      <div className="page-outerdiv">
        {/* breadCrumb */}
        <div className="breadcrumb_div"><a>Settings > <Link to="/userManagementController" > <span className="">Users</span></Link> ></a>User Details</div>
        {/* end breadCrumb */}
        <form
          className='needs-validation'
          onSubmit={this.toggle1}
          noValidate
        >
        <MDBRow className="mb-4 mt-3" >
       
            <MDBCol sm="6">
            <div className="view-page-row view-bg-row" >
                 <label>
                 E-Mail:
                 </label>
                 <label >
                 {this.state.fields["email"]}
                 {/* onChange={this.changeHandler.bind(this, "idTag")} */}

                 </label>
               </div>
               </MDBCol>
               <MDBCol sm="6">
            <div className="view-page-row view-bg-row">
                 <label>
                RFID:
                 </label>
                 <label>
                 {this.state.fields["rfid"]}
                 </label>               
               </div>
               </MDBCol>
               <MDBCol sm="6">
              <div className="view-page-row ">
                 <label>
                 First Name:
                 </label>
                 <label>
                 {this.state.fields.firstName}
                 </label>
               </div>
               </MDBCol>
               <MDBCol sm="6">
              <div className="view-page-row ">
                 <label>
                Last Name:
                 </label>
                 <label>
                   {this.state.fields.lastName}
                 </label>
               </div>
               </MDBCol>
               <MDBCol sm="6">
              <div className="view-page-row ">
                 <label>
               Mobile No:
                 </label>
                 <label>
                   {this.state.fields.mobileNumber}
                 </label>
               </div>
               </MDBCol>
               <MDBCol sm="6">
              <div className="view-page-row">
                 <label>
                 Role :
                 </label>
                 <label>
                 {this.state.fields["role"]}
                 </label>
               </div>
               </MDBCol>
               <MDBCol sm="6">
              <div className="view-page-row">
                 <label>
                 Status:
                 </label>
                 <label>
                 {this.state.fields.status}
                 </label>
               </div>
              </MDBCol>
              <MDBCol md="12" className="text-right">
            {/* <MDBBtn color='primary'  onClick={this.toggle1} className="mt-5 mr-3 v-hiiden" id="saveBtn" >
              Save
            </MDBBtn> */}
            
              <Link to={{
              pathname:'/editUser', 
              state: this.state.fields, 
              }}>
            <MDBBtn color="primary" className="mt-5 mr-3" onClick={this.editTag}  id="editBtn" >
           Edit
        </MDBBtn>
        </Link>
           
            <MDBBtn color="primary" className="mt-5" onClick={this.backToList} >
                <span style={{color:"#fff"}}>Back</span>   
            </MDBBtn>
            </MDBCol>  
        </MDBRow>
        </form>
      </div>
    </main>
    </>
  );
}
}

export default Ocppnewtag;




