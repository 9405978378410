import React from 'react';
import {Link} from 'react-router-dom';
import { MDBBtn,MDBCol, MDBInput ,MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter} from 'mdbreact';
import * as baseUrl from './commons/constants';
import  $ from "jquery";

class addOrganization extends React.Component{  
    constructor (props){
        super(props);
        this.state = {
          fields: {id: '', active:'', city:'', company:'', country:'', createdBy:'', createdDate:'', identifier:'',latitude:'', longitude:'', officeAddress:'',organisationType:'',root:'',state:'',updatedBy:'',updatedDate:'',url:''},
          sucess:{},
          errors: {},
          activeItem: "1",
          model:"false",
          page_title:"Add Organization",
          dataArray:[],
          modalsession:false,
          validationErrors:[]
        }
      
        this.backToList = this.backToList.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.redirectToListPage = this.redirectToListPage.bind(this)
        this.toggle = this.toggle.bind(this)
        this.tagOk = this.tagOk.bind(this);
        this.getProducts=this.getProducts.bind(this);
        this.togglesession = this.togglesession.bind(this);
}


tagOk(){
  this.props.history.push('/organisationList');
  window.location.reload();

}
toggle = () => {
  this.setState({
  modal: !this.state.modal
  });
  }

  async togglesession(){
    
    this.setState({
    modalsession: !this.state.modalsession
    });
  }
  
  
  sessiontagOk(){
    this.props.history.push('/');
    window.location.reload();
  }

backToList(){
  this.props.history.push('/organisationList');
}

 //This is for making user should enter only numbers
 numberHandler(field, e){ 
    let fields = this.state;
    if(e.target.validity.valid){
      fields[field] = e.target.value; 
      this.setState({fields});
    }else if(e.target.value==""){
      fields[field] = e.target.value; 
      this.setState({fields});
    }

  }
  organizationChange(){
    this.getProducts(this);
  }
  getProducts(e){
    var orgId=document.getElementById("organization").value;
    this.setState({orgId:orgId})
    
    if(orgId!=''){
      var url = baseUrl.URLPath + "dashboard/"+orgId+"/products";
      fetch(url,{
        method: "GET",
        headers: {
          "content-type":"application/json",
          "Authorization":localStorage.getItem("Authorization"),
          "tenant":localStorage.getItem("tenant")
        }
        })
        .then((resp) => {
          
          if(resp.status==401){
            this.togglesession();
            // alert("Access token is expired, please login again")
            localStorage.clear();
          }
         if(resp.status==200){
           return resp.json();
         }      }) 
        .then((response) =>{
          if(response!=undefined){
              
            // alert(JSON.stringify(response));
           this.setState({ dataArray: response});
             } 
        // alert("data"+JSON.stringify(this.state.data));
        // this.setState({ total_charge_point:response.data.chargePointCount })
  
        })
        .catch((error) => {
            console.log(error, "catch the loop")
        })
      }else{
        this.setState({ dataArray:[]});
      }
    }
 
  redirectToListPage(){
    this.props.history.push('/chargepoint')
  }
  isHaveAlphabetic(string){
    if(/[a-zA-Z]/.test(string) == true){
      return true;
    }else{
      return false;
    }
  }

  isLengthAllowed(string){
    if(string.length<=128){
      return true;
    }else{
      return false;
    }
  }

  validateURL(textval) {
    var urlregex = new RegExp( "^((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)");
    var var_name = urlregex.test(textval);
    return var_name;
  }

  async handleSubmit(event) {
    event.preventDefault();
        let errors = {};
        var company= this.state.fields['company'];
        if(company!="" && company!=null && company!=undefined){
          company=company.trim();
        }
        var identifier= this.state.fields['identifier'];
        if(identifier!="" && identifier!=null && identifier!=undefined){
          identifier=identifier.trim();
        }
        var officeAddress = this.state.fields['officeAddress'];
        if(officeAddress!="" && officeAddress!=null && officeAddress!=undefined){
          officeAddress=officeAddress.trim();
        }
        var city= this.state.fields['city'];
        if(city!="" && city!=null && city!=undefined){
          city=city.trim();
        }
        var state= this.state.fields['state'];
        if(state!="" && state!=null && state!=undefined){
          state=state.trim();
        }
        var country= this.state.fields['country'];
        var orgurl= this.state.fields['url'];
        if(orgurl!="" && orgurl!=null && orgurl!=undefined){
          orgurl=orgurl.trim();
        }

        if(company==''){ 
          errors["companyempty"] = "Please enter a organization.";
        }else if(company!='' && this.isHaveAlphabetic(company) == false){
         errors["companyempty"] = "Organization Name must contain one alphabetical letter";
       }else if(company!='' && /[`!$%&*()+\=\{};':"\\|,.\[\]<>\/?~#@^]/.test(company) == true ){
         errors["companyempty"] = "Organization Name must not contain special characters"; 
       }else if(company!='' && this.isLengthAllowed(company) == false){
         errors["companyempty"] = "Max length should be 128 characters"; 
       }

       if(city==''){
        errors["cityempty"] = "Please enter a city.";
      }else if(city!='' && this.isHaveAlphabetic(city) == false){
        errors["cityempty"] = "City Name must contain one alphabetical letter";
      }else if(city!='' && /[`!$%&*()+\=\{};':"\\|,.<>\/?~#@_^]/.test(city) == true){
        errors["cityempty"] = "City Name must not contain special characters other than []-"; 
      }else if(city!='' && this.isLengthAllowed(city) == false){
        errors["cityempty"] = "Max length should be 128 characters"; 
      }

      if(state==''){
        errors["stateempty"] = "Please enter a state.";
       }else if(state!='' && this.isHaveAlphabetic(state) == false){
         errors["stateempty"] = "State Name must contain one alphabetical letter";
       }else if(state!='' && /[`!$%&*()+\=\{};':"\\|,.<>\/?~#@_^]/.test(state) == true){
         errors["stateempty"] = "State Name must not contain special characters other than []-";
       }else if(state!='' && this.isLengthAllowed(state) == false){
         errors["stateempty"] = "Max length should be 128 characters"; 
       }

            if(orgurl==''){
              errors["urlempty"] = "Please enter a url.";
             }
             if(this.validateURL(orgurl)==false){
              errors["urlempty"] = "Please enter a valid url.";
             }
           if(identifier==""){
                errors["identifierempty"] = "Please enter a Short Name.";
              }else if(identifier!='' && this.isHaveAlphabetic(identifier) == false){
                
               errors["identifierempty"] = "Short Name must contain one alphabetical letter";
             }else if(identifier!='' && /[`!$%&*()+\=\{};':"\\|,.<>\/?~#@_0123456789^]/.test(identifier) == true){
               errors["identifierempty"] = "Short Name must be alphabetical letters"; 
             }else if(identifier!='' && this.isLengthAllowed(identifier) == false){
               errors["identifierempty"] = "Max length should be 128 characters"; 
             }

           if(officeAddress==""){
            errors["officeAddressempty"] = "Please enter a office address.";
       }else if(officeAddress!='' && this.isHaveAlphabetic(officeAddress) == false){
        errors["officeAddressempty"] = "Office Address must contain one alphabetical letter";
      }else if(officeAddress!='' && /[!$%*+\=\{}\[\];"\\|<>?~#@_^]/.test(officeAddress) == true){
        errors["officeAddressempty"] = "Office Address must not contain special characters other than -/(),.&':`"; 
      }else if(officeAddress!='' && this.isLengthAllowed(officeAddress) == false){
        errors["officeAddressempty"] = "Max length should be 128 characters"; 
      }
           if(identifier!==''){
             if(!identifier.match(/^[a-zA-Z]+$/)){
              errors["identifierempty"] = "Please enter a valid short name. It should be alphabetical without space ";
             }
           }
          
           if(country==''){
             errors["countryempty"] = "Please select the country." ;
          }
        //  if(locationLatitude!==''){
        //   if(!locationLatitude.match(/^[+-]?(([1-8]?[0-9])(\.[0-9]{1,6})?|90(\.0{1,6})?)$/)){
        //     errors["entervalidlat"] = "Please enter a valid latitude.";
        //    }
        //   }
        //  if(locationLongitude!==''){
        //   if(!locationLongitude.match(/^[+-]?((([1-9]?[0-9]|1[0-7][0-9])(\.[0-9]{1,6})?)|180(\.0{1,6})?)$/)){
        //     errors["entervalidlong"] = "Please enter a valid longitude.";
        //    }
        //   }
           if(errors.hasOwnProperty("companyempty")||errors.hasOwnProperty("cityempty")||errors.hasOwnProperty("stateempty") || errors.hasOwnProperty("urlempty")|| errors.hasOwnProperty("identifierempty")|| errors.hasOwnProperty("officeAddressempty"))
           {}
      
         else{
         // alert("hello");
         let url = baseUrl.URLPath+"admin/v1.0/account";
         //localhost:8080/cpms/chargepoints
         let data = {"company":company.toLowerCase(),"identifier":identifier,"officeAddress":officeAddress,"city": city, "state":state, "country":country, "url":orgurl}; 
      fetch(url, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          "content-type":"application/json",
          "Authorization":localStorage.getItem("Authorization"),
        }
      
        }).then(resp =>{
          
          if(resp.status==401){
            this.togglesession();
            // alert("Access token is expired, please login again")
            localStorage.clear();
          }
          else if(resp.status==404){
            this.setState({errors: errors});
            
           }else if(resp.status==500){
            //  alert("internal server error")
            }
         else if(resp.status==409){
            // errors["chargepointexist"]="Charge Point already exists."
            this.setState({
              errors: errors
              });
           }
           else if(resp.status==400){
            return resp.json();
          //  alert("Bad request")
          return resp.json();
        }else {
          return resp.json();
          }
        }).then((response) =>{
          if(response.status == "FAILURE"){

            if(response.errorMessage=="Organization already exists"){
              errors["shortnameempty"] = "Short name already exists.";
              $('.error_duplicate').text(response.errorMessage);
              this.setState({errors: errors});
            }else{
              $('.error_duplicate').text("");
            }

          }else if(response.status == "SUCCESS"){
            if(response!=undefined){
              this.toggle()
            }
          }else{
            this.setState({
              validationErrors: response.messageArgs
            });
          }
          
        })
        .catch(error => alert('Error:' + error));
  }
    this.setState({errors: errors});
}

  changeHandler(field, e){ 
    
    var company= document.getElementById("companyId").value;
    var identifier= document.getElementById("identifierId").value;
    var officeAddress=document.getElementById("officeAddressIid").value;
    var city=document.getElementById("cityId").value;
    var state=document.getElementById("stateId").value;
    var url=document.getElementById("urlId").value;
    
  let fields = this.state.fields;
  fields[field] = e.target.value; 
 
  this.setState({fields});
  if (fields["companyId"] = company){
    this.state.errors["companyempty"] = " ";
 }
 if (fields["identifierId"] = identifier){
  this.state.errors["identifierempty"] = " ";
}
if (fields["officeAddressIid"] = officeAddress){
  this.state.errors["officeAddressempty"] = " ";
}
if (fields["cityId"] = city){
  this.state.errors["cityempty"] = " ";
}
if (fields["stateId"] = state){
  this.state.errors["stateempty"] = " ";
}
if (fields["urlId"] = url){
  this.state.errors["urlempty"] = " ";
}

  }


 
  countryChange(){

    var x = document.getElementById("country").value;
    let fields = this.state.fields;
    if(x!="Country"){
   
      this.state.errors["countryempty"] = " " ;
  
    }
    fields["country"] = x; 
    this.setState({fields});
    

  }

  render() {

  return (
    <>
   <main class="content-div" >
   <p> Organization
              <div className="breadcrumb_div">
              Settings &gt; <Link to="/organisationList">Organization</Link > &gt; <span className="breadcrumb_page">Add</span>
              </div>
          </p>
    {/* <p className="breadcrumb_div">Charge Point > <Link to="/chargePoint" > <span className="">Overview</span></Link> >  Add</p> */}
    <form
        className='needs-validation'
        onSubmit={this.submitHandler}
        noValidate
      >
      <div className="page-outerdiv">
        <div className="row">
          <div className="col-md-12">
            <div className="pull-left">
            <button type="button" className="btn_primary" onClick={this.backToList}><i class="fa fa-angle-left mr-2" aria-hidden="true"></i> Back</button>
            </div>
          </div>
        </div>
        <div className="row mb-3 mt-3">
            <MDBCol md="4">
                <MDBInput
                  name="company"
                  value={this.state.fields["company"]}
                  onChange={this.changeHandler.bind(this, "company")}
                  type="text"
                  id="companyId"
                  label="Organization"
                  autoComplete="off"
                  className="text_lowercase"
                >
                <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["companyempty"]}</span></span> 
                </MDBInput>
            </MDBCol>
            <MDBCol md="4">
                <MDBInput
                  name="identifier"
                  value={this.state.fields["identifier"]}
                  onChange={this.changeHandler.bind(this, "identifier")}
                  type="text"
                  id="identifierId"
                  label="Short Name"
                  autoComplete="off"
                >
                   <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["identifierempty"]}</span></span> 
                   <span className="error_msg w3-animate-top">  <span style={{color: "red"}}></span></span> 

                </MDBInput>
            </MDBCol>

          <MDBCol md="4" >
                <MDBInput
                  name="officeAddress"
                  value={this.state.fields["officeAddress"]}
                  onChange={this.changeHandler.bind(this, "officeAddress")}
                  min="0"
                  id="officeAddressIid"
                  label="Office Address"
                  autoComplete="off"
                >
                    <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["officeAddressempty"]}</span></span> 
                </MDBInput>
              </MDBCol>

            
              <MDBCol md="4" className="mt-2">
                <MDBInput
                  value={this.state.fields["city"]}
                  onChange={this.changeHandler.bind(this, "city")}
                  type="text"
                  id="cityId"
                  name="city"
                  label="City"
                >
                  <span className="error_msg w3-animate-top"><span style={{color: "red"}}>{this.state.errors["cityempty"]}</span></span> 
                </MDBInput>
              </MDBCol>
              <MDBCol md="4" className="mt-2">
                <MDBInput
                  value={this.state.fields["state"]}
                  onChange={this.changeHandler.bind(this, "state")}
                  onInput={this.numberHandler.bind(this,"state")}
                  type="text" 
                  id="stateId"
                  name="state"
                  label="State"
                >
               <span className="error_msg w3-animate-top"><span style={{color:"red"}}>{this.state.errors["stateempty"]}</span></span> 
             </MDBInput>
             </MDBCol>
              <MDBCol md="4" className="mt-2" >
              <select className="browser-default custom-select select_height" id="country" onChange={this.countryChange.bind(this)}>
                <option value="Country">Country</option>
                <option value="Afghanistan">Afghanistan</option>
                <option value="Åland Islands">Åland Islands</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antarctica">Antarctica</option>
                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                <option value="Botswana">Botswana</option>
                <option value="Bouvet Island">Bouvet Island</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                <option value="Brunei Darussalam">Brunei Darussalam</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">Central African Republic</option>
                <option value="Chad">Chad</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote D'ivoire">Cote D'ivoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Territories">French Southern Territories</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guernsey">Guernsey</option>
                <option value="Guinea">Guinea</option>
                <option value="Guinea-bissau">Guinea-bissau</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="India">India</option>
                <option value="Indonesia">Indonesia</option>
                <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jersey">Jersey</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                <option value="Korea, Republic of">Korea, Republic of</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macao">Macao</option>
                <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malawi">Malawi</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                <option value="Moldova, Republic of">Moldova, Republic of</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montenegro">Montenegro</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Namibia">Namibia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherlands">Netherlands</option>
                <option value="Netherlands Antilles">Netherlands Antilles</option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau">Palau</option>
                <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Philippines">Philippines</option>
                <option value="Pitcairn">Pitcairn</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russian Federation">Russian Federation</option>
                <option value="Rwanda">Rwanda</option>
                <option value="Saint Helena">Saint Helena</option>
                <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                <option value="Saint Lucia">Saint Lucia</option>
                <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                <option value="Samoa">Samoa</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Serbia">Serbia</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                <option value="Thailand">Thailand</option>
                <option value="Timor-leste">Timor-leste</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Emirates">United Arab Emirates</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="United States">United States</option>
                <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                <option value="Uruguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Viet Nam">Viet Nam</option>
                <option value="Virgin Islands, British">Virgin Islands, British</option>
                <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                <option value="Wallis and Futuna">Wallis and Futuna</option>
                <option value="Western Sahara">Western Sahara</option>
                <option value="Yemen">Yemen</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
              </select>
              <span className="error_msg w3-animate-top mt-1">  <span style={{color: "red"}}>{this.state.errors["countryempty"]}</span></span> 
              </MDBCol>
              <MDBCol md="4">
                <MDBInput
                  value={this.state.fields["url"]}
                  onChange={this.changeHandler.bind(this, "url")}
                  onInput={this.numberHandler.bind(this,"url")}
                  type="text" 
                  id="urlId"
                  name="url"
                  label="Url"
                >
               <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["urlempty"]}</span></span> 
             </MDBInput>
             </MDBCol>
             {/* <MDBCol md="4">
                <MDBInput
                  name="identifier"
                  value={this.state.fields["identifier"]}
                  onChange={this.changeHandler.bind(this, "identifier")}
                  type="text"
                  id="identifierId"
                  label="Short Name"
                  autoComplete="off"
                >
                   <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["identifierempty"]}</span></span> 
                   <span className="error_msg w3-animate-top">  <span style={{color: "red"}}></span></span> 

                </MDBInput>
            </MDBCol> */}
              {/* <MDBCol md="4">
              <MDBInput
                value={this.state.fields["locationLatitude"]}
                onChange={this.changeHandler.bind(this, "locationLatitude")}
                type="text"
                name="adminAddress"
                label="Latitude"
            >
            <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["entervalidlat"]}</span></span> 
              </MDBInput>
              </MDBCol> */}
            {/* <MDBCol md="4" className="mt-1" >
              <MDBInput
                value={this.state.fields["locationLongitude"]}
                onChange={this.changeHandler.bind(this, "locationLongitude")}
                type="text"
                id="longitudeId"
                name="Longitude"
                label="Longitude"
              >
            </MDBInput>
    <span className="error_msg w3-animate-top"><span style={{color: "red"}}>{this.state.errors["entervalidlong"]}</span></span> 
 </MDBCol>   */}
  {/* <MDBCol md="12" className="mt-4">
    <GeoLocation google={this.props.google} center={{lat:18.520,lng:73.8567}} height='300px' zoom={15} />
  </MDBCol>  */}
  <MDBCol md="12" className="mt-3">
  <span className="error_msg w3-animate-top error_duplicate" style={{color: "red"}}></span> 
  <div className="row" style={{ marginTop: '0.05rem' }}>
                <div className="error_msg w3-animate-top backend_validations" style={{color: "red"}}>
                  {
                    this.state.validationErrors.map((validation, index) => {
                      return (
                        <div>{validation}</div>
                      )
                    })
                  }
                </div>
                </div>
  </MDBCol>
  <div className="row" style={{ marginTop: '0.05rem' }}>
                <div className="error_msg w3-animate-top backend_validations" style={{color: "red"}}>
                  {
                    this.state.validationErrors.map((validation, index) => {
                      return (
                        <div>{validation}</div>
                      )
                    })
                  }
                </div>
                </div>
     <MDBCol md="12" className="text-right mt-3">
       <button type="button" className="refresh-btn mr-3" onClick={this.handleSubmit}>Save <i class="fas fa-save ml-2"></i></button>
    </MDBCol> 
</div>


    </div>
      
    </form>
    </main>
    <MDBModal isOpen={this.state.modal} size="md" className="model_top">
      <MDBModalHeader toggle={this.toggle}>Success</MDBModalHeader>
      <MDBModalBody>
      Organization added successfully.
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn> */}
      <MDBBtn color="primary" onClick={this.tagOk}>OK</MDBBtn>
      </MDBModalFooter>
      </MDBModal>

      {/* popup for session expire */}

 <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
      <MDBModalHeader toggle={this.togglesession}>Session has expired</MDBModalHeader>
      <MDBModalBody>
       Please login again.
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
      <MDBBtn color="primary" onClick={this.sessiontagOk}>OK</MDBBtn>
      </MDBModalFooter>
      </MDBModal>
    </>
  );
}
}
 export default addOrganization;


