import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import Pagination from "react-js-pagination";
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRow, MDBCol, MDBInput, MDBBtn } from "mdbreact";
import { Form, Table } from 'react-bootstrap';
import $ from "jquery";
import DatePicker from "react-datepicker";
import * as baseUrl from './commons/constants';
import * as Roles from './commons/roles';
import { hasPermission } from './commons/auth';
import moment from 'moment';

export default class BillingHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            transactionId: '',
            multiple: false,
            options: [],
            errors: {},
            pageSize: 10,
            pageNo: 1,
            noOfRecords: '',
            saveSelectedCpid: '',
            startTime: '',
            endTime: '',
            email: '',
            dataArray: [],
            modalsession: false,
            fromDate: '',
            fromDateApi: '',
            toDate: '',
            toDateApi: ''
        }
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.getSearchInventoryList = this.getSearchInventoryList.bind(this);
        this.getTransactions = this.getTransactions.bind(this);
        this.showEntriesChange = this.showEntriesChange.bind(this);
        this.chargepointIDChange = this.chargepointIDChange.bind(this);
        this.FindCpName = this.FindCpName.bind(this);
        this.ClearChargepoint = this.ClearChargepoint.bind(this);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.dateformatinUTC = this.dateformatinUTC.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.keyPress1 = this.keyPress1.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.changeHandler1 = this.changeHandler1.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClick1 = this.handleClick1.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeEmail1 = this.onChangeEmail1.bind(this);
        this.exportTransactions = this.exportTransactions.bind(this);
    }

    // search filter 
    handleKeyDown(ref, e) {
        var serial_no = e.target.value;

        $("#nochargeexists").hide();

        if (serial_no.length > 1) {
            $("#selectcharge").hide();
        } else {
            $("#selectcharge").show();
        }

        var key = e.key;
        var ctrl = e.ctrlKey ? e.ctrlKey : ((key == 17) ? true : false);
        var getList = this.state.intial_data;

        var sno = serial_no.slice(0, serial_no.length - 1)

        this.getSearchInventoryList(sno)

        if (key == "Backspace" || key == "Delete") {
            var x = e.target.value
            var sno = x.slice(0, x.length - 1)
            if (sno == "") {
                this.state.saveSelectedCpid = sno;
                this.getTransactions()
            }
        }

        if (e.keyCode == 13) {
            $("#selectcharge").hide();
            this.FindCpName(ref, e);
            $("#rbt_id").hide();
        }

        if ((e.keyCode == 86 && ctrl)) {
            $("#selectcharge").hide();
            this.getSearchInventoryList(serial_no)
        }

        switch (e.keyCode) {
            case 8:  // Backspace
            case 9:  // Tab
            case 13: // Enter
            case 37: // Left
            case 38: // Up
            case 39: // Right
            case 40: // Down
                break;
            default:
                var regex = new RegExp("^[a-zA-Z0-9 -]");
                var key = e.key;
                if (!regex.test(key)) {
                    e.preventDefault();
                    return false;
                }
                break;
        }
    }

    componentDidMount() {
        let fromDate = moment().subtract(7, 'd');
        let fromDateApi = this.dateformatinUTC(moment().subtract(7, 'd'));
        let toDate = new Date();
        let toDateApi = this.dateformatinUTC(moment(toDate));

        this.setState({
            fromDate: new Date(fromDate),
            fromDateApi: fromDateApi,
            toDate: toDate,
            toDateApi: toDateApi,
        }, () => {
            this.getTransactions();
        });
    }

    getSearchInventoryList(cpid) {
        var url = baseUrl.WebPath + "chargers/search/partial?cpid=" + cpid;

        fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization")
            }
        })
            .then((resp) => {
                if (resp.status == 401) {
                    this.togglesession();
                    localStorage.clear();
                } else if (resp.status == 200) {
                    return resp.json();
                } else if (resp.status == 500) {
                    return resp.json();
                }
            })
            .then((response) => {
                if (response.status == 500) {
                    this.setState({
                        options: [],
                        intial_data: []
                    });
                } else if (response != undefined) {
                    this.setState({
                        options: response,
                        intial_data: response
                    });
                }
            })
            .catch((error) => {
                console.log(error, "catch the loop");
            })
    }

    getTransactions() {
        var url = baseUrl.WebPath + "billings/history" + "?pageNo=" + this.state.pageNo + "&pageSize=" + this.state.pageSize + "&startTime=" + this.state.fromDateApi + "&endTime=" + this.state.toDateApi + "&serialNo=" + this.state.saveSelectedCpid + "&email=" + this.state.email + "&tarnsactionId=" + this.state.transactionId;

        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("Authorization")
            }
        })
            .then((resp) => {
                if (resp.status == 401) {
                    this.togglesession();
                    localStorage.clear();
                } else if (resp.status == 200) {
                    return resp.json();
                }
            })
            .then((response) => {
                if (response != undefined) {
                    this.setState({
                        dataArray: response?.data ?? [],
                        noOfRecords: response?.count ?? 0
                    });
                }
            })
            .catch((error) => {
                console.log(error, "catch the loop")
            })
    }

    async togglesession() {
        this.setState({
            modalsession: !this.state.modalsession
        });
    }

    sessiontagOk() {
        this.props.history.push('/');
        window.location.reload();
    }

    handlePageChange(pageNumber) {

    if (pageNumber != this.state.pageNo) {

        this.setState({
            pageNo: pageNumber
        }, ()=>{
            this.getTransactions();
        })
    }
    }
    
    showEntriesChange(e) {
        this.state.pageNo = 1;
        this.state.pageSize = e.target.value;

        this.getTransactions();
    }

    FindCpName(ref, event) {
        this.state.saveSelectedCpid = ref.current.inputNode.value;
        var errors = {};

        if (this.state.saveSelectedCpid == null || this.state.saveSelectedCpid == "") {
            errors["selectChargepoint"] = "Please select the serial number.";
            this.setState({
                errors: errors
            });
        } else {
            errors["selectChargepoint"] = "";
            this.setState({
                errors: errors,
                pageSize: 10,
                pageNo: 1
            }, () => {
                this.getTransactions();
            });
        }

        var serial_no = event.target.value;

        if (serial_no.length > 1) {
            $("#selectcharge").hide();
        } else {
            $("#selectcharge").show();
        }
    }

    // api to get chargepointId based on search filter
    chargepointIDChange(cpid) {
        this.state.saveSelectedCpid = cpid;

        if (cpid.length == 0) {
            this.state.pageNo = 1;
            this.getTransactions();
        } else {
            let errors = {};
            errors["chargePointNotExists"] = "";
            errors["selectChargepoint"] = "";
            this.setState({
                errors: errors
            });
            this.state.pageNo = 1;
            this.getTransactions();
        }
    }

    ClearChargepoint(ref, event) {
        let errors = this.state.errors;
        this.state.saveSelectedCpid = "";
        
        if (errors['chargePointNotExists'] != "" || errors["selectChargepoint"] != "") {
            errors['chargePointNotExists'] = "";
            errors["selectChargepoint"] = "";
            this.setState({
                errors
            });
            this.state.pageNo = 1;
            this.getTransactions();
        } else if (errors['chargePointNotExists'] == "" && errors["selectChargepoint"] == "") {
            this.state.pageNo = 1;
            this.getTransactions();
        }

        ref.current.clear();
    }

    // for from date calender 
    handleFromChange = date => {
        $('.future_err1').text("");
        let fromD = '';
        if (date != undefined && date != "" && date != null) {
            fromD = this.dateformatinUTC(date)
        }
        this.setState({
            fromDate: date,
            fromDateApi: fromD,
            pageNo: 1
        }, () => {
            this.getTransactions();
        });
    }

    // for to date calender 
    handleToChange = date => {
        let toD = '';
        if (date != undefined && date != "" && date != null) {
            toD = this.dateformatinUTC(date)
        }

        this.setState({
            toDate: date,
            toDateApi: toD,
            pageNo: 1
        }, () => {
            this.getTransactions()
        });
    }

    dateformatinUTC(date) {
        var currentDate = new Date(date);
        var twoDigitMonth = ((currentDate.getUTCMonth() + 1) >= 10) ? (currentDate.getUTCMonth() + 1) : '0' + (currentDate.getUTCMonth() + 1);
        var twoDigitDate = ((currentDate.getUTCDate()) >= 10) ? (currentDate.getUTCDate()) : '0' + (currentDate.getUTCDate());
        var twohoursDate = ((currentDate.getUTCHours()) >= 10) ? (currentDate.getUTCHours()) : '0' + (currentDate.getUTCHours());
        var twominDate = ((currentDate.getUTCMinutes()) >= 10) ? (currentDate.getUTCMinutes()) : '0' + (currentDate.getUTCMinutes());
        var twosecDate = ((currentDate.getUTCSeconds()) >= 10) ? (currentDate.getUTCSeconds()) : '0' + (currentDate.getUTCSeconds());
        var twomillisecDate = ((currentDate.getUTCMilliseconds()) >= 10) ? (currentDate.getUTCMilliseconds()) : '0' + (currentDate.getUTCMilliseconds());
        var csFormatDate = currentDate.getUTCFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate + "T" + twohoursDate + ":" + twominDate + ":" + twosecDate + ":" + twomillisecDate;
        return csFormatDate;
    }

    keyPress(e) {
        let errors = this.state.errors;
        var key = e.key;

        if (e.keyCode == 13) {
            this.state.email = e.target.value;
            if (this.state.email == "") {
                errors["email_error"] = "Please enter email id";
                this.setState({
                    errors
                });
            } else {
                errors["email_error"] = "";
                this.state.pageNo = 1;
                this.getTransactions();
            }
        }

        if (key == "Backspace" || key == "Delete") {
            var x = e.target.value
            var sno = x.slice(0, x.length - 1)
            if (sno == "") {
                this.state.email = sno;
                this.state.pageNo = 1;
                this.getTransactions();
            }
        }

        switch (e.keyCode) {
            case 8:  // Backspace
            case 9:  // Tab
            case 13: // Enter
            case 37: // Left
            case 38: // Up
            case 39: // Right
            case 40: // Down
                break;
            default:
                var regex = new RegExp("^[a-zA-Z0-9.@-]");
                var key = e.key;
                if (!regex.test(key)) {
                    e.preventDefault();
                    return false;
                }
                break;
        }
    }

    keyPress1(e) {
        let errors = this.state.errors;
        var key = e.key;

        if (e.keyCode == 13) {
            this.state.transactionId = e.target.value;
            if (this.state.transactionId == "") {
                errors["transaction_error"] = "Please enter transaction id";
                this.setState({
                    errors
                });
            } else {
                errors["transaction_error"] = "";
                this.state.pageNo = 1;
                this.getTransactions();
            }
        }

        if (key == "Backspace" || key == "Delete") {
            var x = e.target.value
            var sno = x.slice(0, x.length - 1)
            if (sno == "") {
                this.state.transactionId = sno;
                this.state.pageNo = 1;
                this.getTransactions();
            }
        }

        switch (e.keyCode) {
            case 8:  // Backspace
            case 9:  // Tab
            case 13: // Enter
            case 37: // Left
            case 38: // Up
            case 39: // Right
            case 40: // Down
                break;
            default:
                var regex = new RegExp("^[a-zA-Z0-9.@-]");
                var key = e.key;
                if (!regex.test(key)) {
                    e.preventDefault();
                    return false;
                }
                break;
        }
    }

    changeHandler(e) {
        this.state.email = e.target.value;
        let errors = this.state.errors;
        errors["email_error"] = "";

        this.setState({ errors });
    }

    changeHandler1(e) {
        this.state.transactionId = e.target.value;
        let errors = this.state.errors;
        errors["transaction_error"] = "";

        this.setState({ errors });
    }

    handleClick() {
        this.state.email = "";
        let errors = this.state.errors;
        errors["email_error"] = "";
        this.state.pageNo = 1;

        this.setState({
            errors
        }, () => {
            this.getTransactions();
        });
    }

    handleClick1() {
        this.state.transactionId = "";
        let errors = this.state.errors;
        errors["transaction_error"] = "";
        this.state.pageNo = 1;

        this.setState({
            errors
        }, () => {
            this.getTransactions();
        });
    }

    //click function for email filter
    onChangeEmail() {
        if (this.state.email == "") {
            let errors = this.state.errors;
            errors["email_error"] = "Please enter email id";
            this.setState({
                errors
            });
        } else {
            let errors = this.state.errors;
            this.state.pageNo = 1;
            errors["email_error"] = "";
            this.setState({
                errors
            });
            this.getTransactions();
        }
    }

    //click function for transaction id filter
    onChangeEmail1() {
        if (this.state.transactionId == "") {
            let errors = this.state.errors;
            errors["transaction_error"] = "Please enter transaction id";
            this.setState({
                errors
            });
        } else {
            let errors = this.state.errors;
            this.state.pageNo = 1;
            errors["transaction_error"] = "";
            this.setState({
                errors
            });
            this.getTransactions();
        }
    }

    exportTransactions() {
        var url = baseUrl.WebPath + "billings/history/export";

        let payload = {};
        payload.email = this.state.email;
        payload.fileType = "xlsx";
        payload.fromTime = this.state.fromDateApi;
        payload.toTime = this.state.toDateApi;
        payload.serialNo = this.state.saveSelectedCpid;

        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization"),
            }
        }).then((resp) => {
            if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            } else if (resp.status == 200) {
                return resp.blob();
            }
        }).then((response) => {
            if (response != undefined) {
                let d = new Date();
                let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
                let url = window.URL.createObjectURL(response);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'Transactions_' + dformat + '.xls';
                a.click();
            }
        }).catch((error) => {
            console.log(error, "catch the loop")
        })
    }

    render() {
        const ref = React.createRef();

        let history = this.state.dataArray?.map((billing, index) => {
            var startTime, startTime1;

            if (billing.createdDate == null || billing.createdDate == "") {
                startTime = "";
                startTime1 = "";
            } else {
                startTime = moment(billing.createdDate).format("DD MMM YYYY");
                startTime1 = moment(billing.createdDate).format("HH:mm");
            }
            return (
                <tr>
                    <td className="wrap-word">{startTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {startTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {startTime1} </div> </>} </td>
                    <td style={{ textAlign: 'center' }}>{billing.paymentTransactionId ? billing.paymentTransactionId : "-"}</td>
                    <td style={{ textAlign: 'center' }}>{billing.serialNo}</td>
                    <td style={{ textAlign: 'center' }}>{billing.email}</td>
                    <td style={{ textAlign: 'center' }}>{billing.chargedEnergy}</td>
                    <td style={{ textAlign: 'center' }}>{parseFloat(billing.billAmount).toFixed(2)}</td>
                    <td style={{ textAlign: 'center' }}>{billing.chargedTime}</td>
                    {/* <td style={{ textAlign: 'center' }}>{billing.ccFee}</td> */}
                    <td style={{ textAlign: 'center' }}>{parseFloat(billing.paidAmount).toFixed(2)}</td>
                    <td style={{ textAlign: 'center' }}>{billing.isfreeCharge ? "Yes" : "No"}</td>
                    <td style={{ textAlign: 'center' }}>{billing.paymentStatus ? billing.paymentStatus : "-"}</td>
                </tr>
            );
        })

        return (
            <main className="content-div">
                <p> Charging Sessions
                    <div className="breadcrumb_div">Report &gt; <span className="breadcrumb_page">Charging Sessions</span></div>
                </p>

                <div className="page-outerdiv">
                    <div className="row ">
                        <div className="col-md-3">
                            <div className="input-group">
                                <i className="fas fa-search fa-sm input-searchIcon"></i>
                                <Typeahead
                                    labelKey="name"
                                    className="w_80"
                                    multiple={this.state.multiple}
                                    options={this.state.options}
                                    id="rbt_id"
                                    minLength={3}
                                    placeholder="Choose a Serial Number..."
                                    onKeyDown={this.handleKeyDown.bind(this, ref)}
                                    onChange={this.chargepointIDChange.bind(this)}
                                    ref={ref}
                                />
                                <button type="button" className="close close_icon" id="clearInput" aria-label="Close" onClick={this.ClearChargepoint.bind(this, ref)} >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <div className="input-group-btn">
                                    <button className="input-searchBtn find-btn" type="submit" onClick={this.FindCpName.bind(this, ref)}>Find</button>
                                </div>
                                <span className="pull-left error_msg w3-animate-top mt-1" id="nochargeexists"><span style={{ color: "red" }}>{this.state.errors["chargePointNotExists"]}</span></span>
                                <span className="pull-left error_msg w3-animate-top mt-1" id="selectcharge"><span style={{ color: "red" }}>{this.state.errors["selectChargepoint"]}</span></span>
                            </div>
                        </div>

                        <MDBCol md="4" className='pr-0 calWidth'>
                            <span className="cal_label mr-2">From Time: </span>
                            <div className="pull-left">
                                <DatePicker
                                    selected={this.state.fromDate}
                                    onChange={this.handleFromChange}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    id="expiryDateId"
                                    className="cal_margin tag_callogs"
                                    timeCaption="time"
                                    dateFormat="dd-MMM-yyyy HH:mm"
                                    maxDate={(this.state.toDate != "" && this.state.toDate != null) ? new Date(this.state.toDate) : new Date()}
                                />
                            </div>
                            <div className="error_msg w3-animate-top col-md-12 datetimeerror pl-0">  <span style={{ color: "red" }}>{this.state.errors["expirydate"]}</span></div>
                        </MDBCol>

                        <MDBCol md="4" className='pr-0 calWidth'>
                            <span className="cal_label mr-2">To Time: </span>
                            <div className="pull-left" >
                                <DatePicker
                                    selected={this.state.toDate}
                                    onChange={this.handleToChange}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    id="expiryDateId"
                                    className="cal_margin tag_callogs"
                                    timeCaption="time"
                                    dateFormat="dd-MMM-yyyy HH:mm"
                                    minDate={new Date(this.state.fromDate)}
                                    maxDate={new Date()}
                                />
                            </div>
                            <div className="error_msg w3-animate-top col-md-12  datetimeerror pl-0">  <span style={{ color: "red" }}>{this.state.errors["expirydate"]}</span></div>
                        </MDBCol>

                        <MDBCol md="3" className="mt-2" >
                            <div class="input-group">
                                <i className="fas fa-search fa-sm input-searchIcon"></i>
                                <input type="text" id="emailId" onKeyDown={this.keyPress} value={this.state.email} style={{ width: "60%" }} onChange={this.changeHandler.bind(this)} class="form-control input-searchBox pad-2" placeholder="Email" name="adminAddress" />
                                <button type="button" data-test="button" className="closinginput" aria-label="Close" onClick={this.handleClick} >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <div class="input-group-btn">
                                    <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeEmail}>Find</button>
                                </div>
                                <span className="error_msg w3-animate-top mt-2" style={{ color: "red" }}>{this.state.errors["email_error"]}</span>
                            </div>
                        </MDBCol>

                        <MDBCol md="3" className="mt-2" >
                            <div class="input-group">
                                <i className="fas fa-search fa-sm input-searchIcon"></i>
                                <input type="text" id="transactionId" onKeyDown={this.keyPress1} value={this.state.transactionId} style={{ width: "60%" }} onChange={this.changeHandler1.bind(this)} class="form-control input-searchBox pad-2" placeholder="Transaction Id" name="adminAddress" />
                                <button type="button" data-test="button" className="closinginput" aria-label="Close" onClick={this.handleClick1} >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <div class="input-group-btn">
                                    <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeEmail1}>Find</button>
                                </div>
                                <span className="error_msg w3-animate-top mt-2" style={{ color: "red" }}>{this.state.errors["transaction_error"]}</span>
                            </div>
                        </MDBCol>

                        <div className="col-md-2 pl-0 mt-2">
                                <button className="btn_primary pull-right " onClick={this.exportTransactions.bind(this)} type="button" data-test="button">
                                    Export <i className="fas fa-file-export ml-2"></i>
                                </button>
                        </div>
                    </div>

                    <div className="row menubar">
                        <div className="col-md-12 ">
                            <div className="">
                                <div className="pull-right">
                                    <label className="sub-text pull-left">Show entries</label>
                                    <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                                        <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange} value={this.state.pageSize}>
                                            <option>10</option>
                                            <option>20</option>
                                            <option>30</option>
                                            <option>40</option>
                                            <option>50</option>
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row ">
                        <div className="col-md-12">
                            <Table striped hover size="sm" className="page-table">
                                <thead>
                                    <tr>
                                        <th>Created Date</th>
                                        <th>Transaction ID</th>
                                        <th>Serial No</th>
                                        <th>Email</th>
                                        <th>Charged Energy</th>
                                        <th>Total Billing Amount ($)</th>
                                        <th>Charged Time</th>
                                        {/* <th>CC Fee</th> */}
                                        <th>Total Amount Authorized ($)</th>
                                        <th>Free Charge</th>
                                        <th>Payment Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {history}
                                </tbody>
                            </Table>
                        </div>
                        <div className="col-md-12 mt-2 mb-4 text-right">
                            <Pagination
                                hideDisabled
                                activePage={this.state.pageNo}
                                itemsCountPerPage={this.state.pageSize}
                                totalItemsCount={this.state.noOfRecords}
                                onChange={this.handlePageChange.bind(this)}
                            />
                        </div>
                        {/* End table  */}
                    </div>
                </div>
            </main>
        );
    }
}