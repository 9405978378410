import React from "react";
import {
    MDBCol,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
} from "mdbreact";
import { Form, Table } from "react-bootstrap";
import * as baseUrl from "./commons/constants";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import moment from "moment";

class userManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataArray: [],
            multiple: false,
            options: [],
            requestFromDate: "",
            requesttoDate: "",
            fromDate: "",
            toDate: "",
            startDate1: "",
            activePage: 1,
            noOfRecords: "",
            data: new Map(),
            pageSize: "10",
            pageNo: 1,
            modalsession: "",
            items: [],
            summaryData: {},
            errors: {},
            recordCount: {},
            email: "",
            customRange: "",
            activeFirstDiv: "activeDate",
            activeSecDiv: "",
            activeThirdDiv: "",
            activeLastDiv: "",
        };
        this.handlePageChange = this.handlePageChange.bind(this);
        this.sessiontagOk = this.sessiontagOk.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeRange = this.onChangeRange.bind(this);
        this.cancelDate = this.cancelDate.bind(this);
        this.showEntriesChange = this.showEntriesChange.bind(this);
        this.getLoginHistory = this.getLoginHistory.bind(this);
        this.backToList = this.backToList.bind(this);
    }

    backToList() {
        this.props.history.push("/userManagementController");
    }

    showEntriesChange() {
        var entries = document.getElementById("showEntries").value;
        this.setState({ pageSize: entries }, ()=>{
            this.getLoginHistory(this.state.pageSize, 1);
        });
    }

    cancelDate() {
        var today = new Date();
        var today1 = moment(today.setDate(today.getDate())).format("YYYY-MM-DD");
        var priorDate = moment(today.setDate(today.getDate() - 7)).format("YYYY-MM-DD");

        this.setState({
            requestfromDate: "",
            requesttoDate: "",
            customRange: "",
            activeFirstDiv: "activeDate",
            activeSecDiv: "",
            activeThirdDiv: "",
            activeLastDiv: "",
            fromDate: priorDate,
            toDate: today1
        },()=>{
            this.getLoginHistory(this.state.pageSize, 1);
        });
    }

    onChangeRange(dateCount, e) {
        this.setState({
            activeFirstDiv: "",
            activeSecDiv: "",
            activeThirdDiv: "",
            activeLastDiv: "",
            email: "",
        });
        if (dateCount == 7) {
            this.setState({
                activeFirstDiv: "activeDate",
            });
        } else if (dateCount == 14) {
            this.setState({
                activeSecDiv: "activeDate",
            });
        } else if (dateCount == 30) {
            this.setState({
                activeThirdDiv: "activeDate",
            });
        } else if (dateCount == "Custom Range") {
            this.setState({
                activeLastDiv: "activeDate",
            });
        } else if (dateCount == "Custom Range") {
            if (document.getElementById("customRangeDiv").classList.contains("coolclass") == false) {
                this.setState({
                    customRange: "coolclass",
                });
            }
        } else {
            var today = new Date();
            var today1 = moment(today.setDate(today.getDate())).format("YYYY-MM-DD");
            var priorDate = moment(today.setDate(today.getDate() - dateCount)).format("YYYY-MM-DD");

            this.setState({
                customRange: "",
                requestfromDate: "",
                requesttoDate: "",
                fromDate: priorDate,
                toDate: today1,
                activePage: 1
            },()=>{
                this.getLoginHistory(this.state.pageSize, 1);
            });
        }
    }

    onChangeDate() {
        this.getLoginHistory(this.state.pageSize, 1);
    }

    onChangeEmail() {
        var errors = {};
        errors["emailDoesnotExist"] = "";

        if (!this.state.email) {
            errors["emailDoesnotExist"] = "Please enter email.";
        } else if (this.state.email) {
            if (!this.state.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
                errors["emailDoesnotExist"] = "Please enter valid email id.";
            } else {
                this.setState({ activePage: 1 },()=>{
                    this.getLoginHistory(this.state.pageSize, 1, "Find");
                });
                errors["emailDoesnotExist"] = "";
            }
        }

        this.setState({
            errors: errors,
        });
    }

    changeHandler = (e) => {
        
        var email = e.target.value;
        this.setState({ email: email },()=>{
            if (this.state.email == "") {
                var today2 = new Date();
                var today1 = moment(today2.setDate(today2.getDate())).format("YYYY-MM-DD");
                var priorDate = moment(today2.setDate(today2.getDate() - 7)).format("YYYY-MM-DD");
                
                var errors = {};
                errors["emailDoesnotExist"] = "";

                this.setState({
                    errors: errors,
                    email: "",
                    fromDate: priorDate,
                    toDate: today1,
                    activeFirstDiv: "activeDate",
                    activePage: 1
                },()=>{
                    this.getLoginHistory(this.state.pageSize, 1);
                });
            }
        });
    }

    async togglesession() {
        this.setState({
            modalsession: !this.state.modalsession,
        });
    }

    handlePageChange(pageNumber) {
        if (pageNumber != this.state.activePage) {
            this.setState({ activePage: pageNumber }, () => {
                this.getLoginHistory(this.state.pageSize, this.state.activePage, "Find");
            });
        }
    }

    sessiontagOk() {
        this.props.history.push("/");
        window.location.reload();
    }

    requestfromdateChange = (date) => {
        let fromDate = this.formatCalDate(date);

        this.setState({ 
            requestfromDate: date,
            fromDate: fromDate
        });
    };

    requestTodateChange = (date) => {
        let toDate = this.formatCalDate(date);

        this.setState({ 
            requesttoDate: date,
            toDate: toDate
        });
    };

    formatCalDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [year, month, day].join("-");
    }

    componentDidMount() {
        var today = new Date();
        var today1 = moment(today.setDate(today.getDate())).format("YYYY-MM-DD");
        var priorDate = moment(today.setDate(today.getDate() - 7)).format("YYYY-MM-DD");

        this.setState({
            fromDate: priorDate,
            toDate: today1
        },()=>{
            this.getLoginHistory(this.state.pageSize, this.state.activePage);
            this.getSummary();
        });
    }

    async getSummary() {
        var url = baseUrl.URLPath + "user/loginhistory/summary";
        await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("Authorization"),
            },
        })
            .then((resp) => {
                if (resp.status == 401) {
                    this.togglesession();
                    localStorage.clear();
                }
                if (resp.status == 500) {
                    alert("internal server error");
                }
                if (resp.status == 200) {
                    return resp.json();
                }
            })
            .then((response) => {
                if (response != undefined) {
                    this.setState({
                        summaryData: response.summaryLogin,
                        recordCount: response.recordCount,
                    });
                }
            })
            .catch((error) => {
                console.log(error, "catch the loop");
            });
    }

    async getLoginHistory(pageSize, pageNo, action) {
        
        var errors = {};
        var toDate = "";
        var fromDate = "";
        var email = this.state.email;
        if (email == undefined || email == "") {
            email = "";
            toDate = this.state.toDate;
            fromDate = this.state.fromDate;
        } else {
            if (action != "") {
                this.setState({
                    activeFirstDiv: "",
                    activeSecDiv: "",
                    activeThirdDiv: "",
                    activeLastDiv: "",
                });
            }
        }

        var url = baseUrl.URLPath + "user/loginhistory?email=" + email + "&fromDate=" + fromDate + "&toDate=" + toDate + "&pageNo=" + pageNo + "&pageSize=" + pageSize;
        
        await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("Authorization"),
            },
        })
            .then((resp) => {
                if (resp.status == 401) {
                    this.togglesession();
                    localStorage.clear();
                }
                if (resp.status == 500) {
                    alert("internal server error");
                }
                if (resp.status == 200) {
                    return resp.json();
                }
            })
            .then((response) => {
                if (response != undefined) {
                    if (action == "Find" && response.count.count == 0) {
                        errors["emailDoesnotExist"] = "Email Id does not exist.";
                        this.setState({
                            errors: errors,
                        });
                    }
                    this.setState({
                        items: response.historyList,
                        noOfRecords: response.count.count,
                        dataArray: response.historyList,
                    });
                }
            })
            .catch((error) => {
                console.log(error, "catch the loop");
            });
    }

    render() {
        var dataList = this.state.items.map((item, index) => {
            if (item.loginDate == null || item.loginDate == "") {
                var date = "";
                var date1 = "";
            } else {
                var date = moment(item.loginDate).format("DD MMM YYYY");
                var date1 = moment(item.loginDate).format("HH:mm");
            }
            return (
                <tr>
                    <td>{item.firstName + " " + item.lastName}</td>
                    <td>{item.company}</td>
                    <td>{item.email}</td>
                    <td className="wrap-word">
                        {date && (
                            <>
                                <div className="nowrap pull-left pb-0 w-100">
                                    <i className="fas fa-calendar-alt fa-sm"></i> {date}
                                </div>
                                <div className="timespan pull-left pb-0">
                                    <i className="far fa-clock fa-sm"></i> {date1}
                                </div>
                            </>
                        )}
                    </td>
                </tr>
            );
        });

        var totalUser = "";

        var data = Object.keys(this.state.recordCount).map((key, index) => {
            if (key != "totalUser") {
                return (
                    <div className="userType px-0 mt-1">
                        <div className="m-2px p-2px rounded h-165">
                            <img src={process.env.PUBLIC_URL + "/assets/img/man-blue.png"} className="py-2 pic-center" alt="" />
                            <div className="font-weight-bold w-100 text-center userTypeCount">{this.state.recordCount[key]}</div>
                            <div className="w-100 text-dark text-center userTypetext">{key}</div>
                        </div>
                    </div>
                );
            } else {
                totalUser = this.state.recordCount[key];
            }
        });

        return (
            <main className="content-div">
                <p>
                    User Login History
                    <div className="breadcrumb_div">
                        Settings &gt; <Link to="/userManagementController">Users</Link> &gt;
                        <span className="breadcrumb_page"> User Login History</span>
                    </div>
                </p>
                <div className="page-outerdiv">
                    <div className="row">
                        <div className="col-md-12">
                            <button
                                type="button"
                                className="btn_primary"
                                onClick={this.backToList}
                            >
                                <i className="fa fa-angle-left mr-2" aria-hidden="true"></i>Back
                            </button>
                            <label className="totlauser pull-right mt-1">
                                Total User : {totalUser}
                            </label>
                        </div>
                    </div>
                    <div className="row mt-2 mx-0">
                        <div className="col-md-9 px-0">
                            <div className="rounded border bor-lg user-mangMainDiv" style={{ height: "auto" }}>
                                <div className="row mx-0">
                                    <div className="col-md-12 px-0">
                                        <div className="p-2px m-2px rounded text-white bg-dark text-center f-9 h-18">
                                            USER TYPE
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col-md-12 px-0">
                                        <div className="row mx-0 userTypeContainer">{data}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row menubar mt-3">
                                <div className="col-md-12 ">
                                    <div className="">
                                        <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-right ml-0 selectdiv">
                                            <Form.Control
                                                as="select"
                                                className="showentry_sel custom_selectBox"
                                                id="showEntries"
                                                onChange={this.showEntriesChange}
                                            >
                                                <option>10</option>
                                                <option>20</option>
                                                <option>30</option>
                                                <option>40</option>
                                                <option>50</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <label className="pull-right sub-text">Show entries</label>
                                    </div>
                                </div>
                            </div>
                            <Table striped hover size="sm" className="page-table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Company</th>
                                        <th>Email</th>
                                        <th>Last Login</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataList}
                                </tbody>
                            </Table>
                            <div className="col-md-12 mt-2 mb-4 text-right">
                                <Pagination
                                    hideDisabled
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.pageSize}
                                    totalItemsCount={this.state.noOfRecords}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                        </div>

                        <div className="col-md-3">
                            {/* timefilter */}
                            <div className="userType-rightdiv">
                                <div className="row mx-1">
                                    <div className={`col-md-12 userType-rightrow ${this.state.activeFirstDiv}`} onClick={this.onChangeRange.bind(this, 7)}>
                                        Last 7 days
                                    </div>
                                    <div className={`col-md-12 userType-rightrow ${this.state.activeSecDiv}`} onClick={this.onChangeRange.bind(this, 14)}>
                                        Last 14 days
                                    </div>
                                    <div className={`col-md-12 userType-rightrow ${this.state.activeThirdDiv}`} onClick={this.onChangeRange.bind(this, 30)}>
                                        Last 30 days
                                    </div>
                                    <div className={`col-md-12 userType-rightrow ${this.state.activeLastDiv}`} onClick={this.onChangeRange.bind(this, "Custom Range")}>
                                        Custom Range
                                    </div>
                                </div>
                                <div className={`row mt-1 ${this.state.customRange}`} id="customRangeDiv">
                                    <div className="col-md-6 pr-1 p-rel">
                                        <div className="pull-left mt-2">
                                            <DatePicker
                                                selected={this.state.requestfromDate}
                                                onChange={(date) => this.requestfromdateChange(date)}
                                                timeFormat="HH:mm"
                                                timeIntervals={1}
                                                className="cal_margin tag_cal"
                                                timeCaption="time"
                                                dateFormat="dd-MMM-yyyy"
                                                placeholderText="From Date"
                                                maxDate={this.state.requesttoDate ? this.state.requesttoDate : new Date()}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 pl-1 p-rel ">
                                        <div className="pull-right mt-2 rightCal">
                                            <DatePicker
                                                selected={this.state.requesttoDate}
                                                onChange={(date) => this.requestTodateChange(date)}
                                                timeFormat="HH:mm"
                                                timeIntervals={1}
                                                className="cal_margin tag_cal "
                                                timeCaption="time"
                                                dateFormat="dd-MMM-yyyy"
                                                placeholderText="To Date"
                                                minDate={this.state.requestfromDate}
                                                maxDate={new Date()}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 pr-1">
                                        <button className="btn btn-primary pl-3 pr-3" onClick={this.cancelDate}>Cancel</button>
                                    </div>
                                    <div className="col-md-6 pl-1">
                                        <button className="btn btn-primary pl-4 pr-4" onClick={this.onChangeDate}>Apply</button>
                                    </div>
                                </div>
                            </div>
                            <div className="lookup-user mt-4">
                                <div className="font-weight-bold f-12 mb-1">
                                    LOOK UP BY USER
                                </div>
                                <div className="row">
                                    <MDBCol md="12" className="mt-2">
                                        <div class="input-group">
                                            <i className="fas fa-search fa-sm input-searchIcon"></i>
                                            <input
                                                type="text"
                                                id="lookbyuserId"
                                                value={this.state.email}
                                                style={{ width: "70%" }}
                                                onChange={this.changeHandler}
                                                className="form-control input-searchBox pad-2"
                                                placeholder="Search"
                                                name="srch-term"
                                            />
                                            <div className="input-group-btn">
                                                <button className="input-searchBtn find-btn" type="submit" onClick={this.onChangeEmail}>
                                                    Find
                                                </button>
                                            </div>
                                        </div>
                                        <span className="error_msg w3-animate-top mt-1">
                                            <span style={{ color: "red" }}>
                                                {this.state.errors["emailDoesnotExist"]}
                                            </span>
                                        </span>
                                    </MDBCol>
                                </div>
                            </div>
                            <div className="summary-login mt-4">
                                <div className="font-weight-bold f-12 mb-1 ">SUMMARY LOGIN</div>
                                <div className="f-10 rounded sum-div w-100 d-flex justify-content-between border border-0">
                                    <div>Total Number of Successful Logins</div>
                                    <div className="text-primary">
                                        {this.state.summaryData.successAttempts}
                                    </div>
                                </div>
                                <div className="f-10 rounded sum-div w-100 d-flex justify-content-between border border-0">
                                    <div>Total Number of UnSuccessful Logins</div>
                                    <div className="text-primary">
                                        {this.state.summaryData.failedAttempts}
                                    </div>
                                </div>
                                <div className="f-10 rounded sum-div w-100 d-flex justify-content-between border border-0">
                                    <div>Total Number of Login Attempt</div>
                                    <div className="text-primary">
                                        {this.state.summaryData.totalAttempts}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
                    <MDBModalHeader toggle={this.togglesession}>
                        Session has expired
                    </MDBModalHeader>
                    <MDBModalBody>Please login again.</MDBModalBody>
                    <MDBModalFooter>
                        <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk}>OK</button>
                    </MDBModalFooter>
                </MDBModal>
            </main>
        );
    }
}

export default userManagement;