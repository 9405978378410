import React from 'react';
import { Link } from 'react-router-dom';
import { MDBCol, MDBInput, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import * as baseUrl from './commons/constants';
import { Alert } from 'reactstrap';

class editBuild extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabledAttr: true,
            model1: "false",
            model2: "false",
            disabled: true,
            model: false,
            dataArray: [],
            targetData: [],
            buildTypes: [],
            taregtTypeValue: "",
            buildTypeId: "",
            releaseNotes: '',
            prerequisites: [],
            prerequisite: "",
            fields: {
                version: "",
                prerequisite: "",
                releaseNote: "No file choosen",
                targetIds: "",
                fileName: "No file choosen",
                version_name: ""
            },
            sucess: {},
            errors: {},
            activeItem: "1",
            model: "false",
            page_title: "  Edit Build",
            file: "",
            sucess: false,
            colour: '',
            message: 'Multiple charge points not supported for this operation.',
            allOrSelected: false,
            typeofRadio: false,
            productId: '',
            buildTypeId: ''
        }
        this.onShowAlert = this.onShowAlert.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.targetTypechange = this.targetTypechange.bind(this);
        this.backToList = this.backToList.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.clearAllRadios = this.clearAllRadios.bind(this);
        this.changeHandlerTarget = this.changeHandlerTarget.bind(this);
        this.toggle = this.toggle.bind(this);
        this.tagOk = this.tagOk.bind(this);
        this.getBuildTypes = this.getBuildTypes.bind(this);
        this.redirectBuild = this.redirectBuild.bind(this);
        this.sucesstoggle = this.sucesstoggle.bind(this);
        this.conformationtoggle = this.conformationtoggle.bind(this);
        this.getPrerequisite = this.getPrerequisite.bind(this);
    }

    conformationtoggle(event) {
        this.setState({
            modal1: !this.state.modal1
        });
    }

    sucesstoggle = () => {
        this.setState({
            modal2: !this.state.modal2
        });
    }

    redirectBuild() {
        this.props.history.push('/builds');
    }

    tagOk() {
        this.props.history.push('/');
        window.location.reload();
    }

    async toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    onShowAlert() {
        this.setState({ sucess: true }, () => {
            window.setTimeout(() => {
                this.setState({ sucess: false })
            }, 5000)
        });
    }

    productChange() {
        var product_id = document.getElementById("product_id").value;
        this.setState({ ...this.state, model: product_id });
        this.getBuildTypes(product_id);
        this.getPrerequisite();
        this.setState({ disabledAttr: "" });
    }

    fileChange(e) {
        var imagedata = document.getElementById("buildFile").files[0];
        var simple_file_upload = document.getElementsByClassName('simple_file_upload')[0];
        simple_file_upload.nextElementSibling.setAttribute('data-file', simple_file_upload.files[0].name);
        simple_file_upload.addEventListener('focus', function () {
            simple_file_upload.classList.add('focus');
        });
        simple_file_upload.addEventListener('blur', function () {
            simple_file_upload.classList.remove('focus');
        });
        this.setState({
            file: imagedata
        })
        this.setState({ disabledAttr: "" });
    }

    releaseChange() {
        var imagedata = document.getElementById("releaseNotes").files[0];
        var simple_file1 = document.getElementsByClassName('simple_upload1')[0];
        simple_file1.nextElementSibling.setAttribute('data-file', simple_file1.files[0].name);
        simple_file1.addEventListener('focus', function () {
            simple_file1.classList.add('focus');
        });
        simple_file1.addEventListener('blur', function () {
            simple_file1.classList.remove('focus');
        });
        this.setState({
            releaseNotes: imagedata
        })
        this.setState({ disabledAttr: "" });
    }

    buildTypeChange() {
        var id = document.getElementById("buildTypes").value;
        this.setState({ buildTypeId: id });
        this.getPrerequisite();
    }

    prerequisiteChange() {
        var id = document.getElementById("prerequisites").value;
        this.state.prerequisite = id;
        this.setState({ prerequisite: id })
        this.setState({ disabledAttr: "" });
    }

    async getBuildTypes(productId) {
        var url = baseUrl.WebPath + "dashboard/buildtypes/" + productId + "?pageNo=0&pageSize=0";

        await fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization"),
            }
        })
            .then((resp) => {
                if (resp.status == 401) {
                    this.toggle();
                    localStorage.clear();
                }
                if (resp.status == 200) {
                    return resp.json();
                } else if(resp.status == 400){
                    return resp.json();
                }
            })
            .then((response) => {
                if (response != undefined) {
                    this.setState({ buildTypes: response })
                }
            })
            .catch((error) => {
                console.log(error, "catch the loop")
            });
    }

    async getProducts(e) {
        var url = baseUrl.URLPath + "cpinventory/evmodel/";
        await fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization"),
            }
        })
            .then((resp) => {
                if (resp.status == 401) {
                    this.toggle();
                    localStorage.clear();
                }
                if (resp.status == 200) {
                    return resp.json();
                }
            })
            .then((response) => {
                if (response != undefined) {
                    this.setState({ dataArray: response });
                }
            })
            .catch((error) => {
                console.log(error, "catch the loop")
            })
    }

    async getPrerequisite() {
        var url = baseUrl.URLPath + "web/dashboard/" + this.state.id + "/prerequisite?product_id=" + this.state.productId + "&build_type_id=" + this.state.buildTypeId;

        fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization"),
            }
        })
            .then((resp) => {
                if (resp.status == 401) {
                    this.toggle();
                    localStorage.clear();
                }
                if (resp.status == 200) {
                    return resp.json();
                }
            })
            .then((response) => {
                if (response != undefined) {
                    this.setState({ prerequisites: [{ version: 'none' }, ...response] });
                }
            })
            .catch((error) => {
                console.log(error, "catch the loop")
            })
    }

    //This is for making user should enter only numbers
    numberHandler(field, e) {
        let fields = this.state.fields;
        if (e.target.validity.valid) {
            fields[field] = e.target.value;
            this.setState({ fields });
            this.state.errors["versionError"] = " "

        } else if (e.target.value == "") {
            fields[field] = e.target.value;
            this.setState({ fields });
        }
        this.setState({ disabledAttr: "" });
    }

    targetTypechange(field, e) {
        if (field == "ALL") {
            document.getElementById("target-type-textarea").style.display = 'none';
            document.getElementById("target_typeid").style.display = 'none';
            document.getElementById("rolloutid").style.display = 'block';
            this.clearAllRadios('rollOut')
            document.getElementById("all").checked = true;
            this.setState({
                typeofRadio: true
            })
        } else {
            this.setState({ taregtTypeValue: "" })
            document.getElementById("target-type-textarea").style.display = 'block';
        }
    }

    clearAllRadios(name) {
        var radList = document.getElementsByName(name);
        for (var i = 0; i < radList.length; i++) {
            if (radList[i].checked) radList[i].checked = false;
        }
    }

    organizationChange() {
        this.getProducts(this);
    }

    componentDidMount() {
        var buildId = this.props.location.state;
        this.state.id = buildId;
        var buildId = this.props.location.state;
        var product_id = '';
        var build_type_id = '';
        this.setState({ buildId: this.props.location.state })
        var url = baseUrl.WebPath + "dashboard/build/" + this.state.id;

        fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization"),
            }
        })
            .then((resp) => {
                if (resp.status == 401) {
                    this.toggle();
                    localStorage.clear();
                }
                if (resp.status == 200) {
                    return resp.json();
                }
            })
            .then((response) => {
                var data1 = [];
                data1["fileName"] = response.fileName;
                data1["version_name"] = response.version_name;
                data1["build_type_id"] = response.build_type_id;
                data1["target_type_id"] = response.target_type_id;
                data1["releaseNote"] = response.releaseNote;
                data1["prerequisite"] = response.prerequisite;
                data1["target_ids"] = response.target_ids;
                data1["product_id"] = response.product_id;
                data1["model"] = response.model;

                this.setState({
                    fields: data1,
                })

                this.setState({
                    productId: data1["product_id"],
                    buildTypeId: data1["build_type_id"],
                    prerequisite: data1["prerequisite"],
                    model: data1["model"],
                },()=>{
                    this.getBuildTypes(this.state.productId);
                    this.getPrerequisite();
                })
            })
            .catch((error) => {
                console.log(error, "catch the loop")
            })
        this.getProducts();
    }

    backToList() {
        this.props.history.state = this.props.location.state;
        window.history.state.state = this.props.location.state;
        this.props.history.push('/builds')
    }

    async handleSubmit(event) {
        var buildTypeId = this.state.buildTypeId;
        var buildId = this.props.location.state;
        this.state.id = buildId;
        event.preventDefault();
        this.setState({
            modal1: !this.state.modal1
        });
        var errors = {};
        if (this.state.fields.version_name == "") {
            errors["versionError"] = "Please enter the version."
        }
        if (!this.state.prerequisite) {
            errors['prerequisite'] = 'Please enter the prerequisite.';
        }
        if (this.state.fields.version_name <= this.state.prerequisite && this.state.prerequisite !== 'none') {
            errors['prerequisite'] = 'Prerequsite should not be greater or equal of version.';
        }
        if (Object.keys(errors).length < 1) {
            var url = baseUrl.URLPath + "web/dashboard/build/" + this.state.id;
            this.setState({ errors: errors })
            var data = new FormData();
            // data.append('model', this.state.model ?? '');
            data.append("file", this.state.file);
            data.append("version_name", this.state.fields.version_name);
            // data.append("build_type_id", buildTypeId);
            data.append("release_note", this.state.releaseNotes);
            data.append("prerequisite", this.state.prerequisite);
            document.getElementById("loader_image_div").style.display = "block";
            fetch(url, {
                method: "PUT",
                body: data,
                headers: {
                    "Authorization": localStorage.getItem("Authorization"),
                }
            })
                .then((resp) => {
                    if (resp.status == 201) {
                        setTimeout(() => {
                            document.getElementById("loader_image_div").style.display = "none";
                            this.sucesstoggle();
                        }, 2000);
                    }
                    if (resp.status == 401) {
                        this.toggle();
                        localStorage.clear();
                    }
                    if (resp.status == 404) {
                    }
                    if (resp.status == 409) {
                        errors["buildexist"] = "Build already exists with this model and version."
                        this.setState({ errors: errors });
                        document.getElementById("loader_image_div").style.display = "none";
                    }
                    return resp.json();
                })
                .then((response) => {
                    if (response.status == 404) {
                        if (response.message == "Prerequsite should not be greater or equal of version.") {
                            errors["prerequisite"] = response.message;
                        } else {
                            this.setState({ message: response.message, colour: 'danger' })
                            this.onShowAlert();
                        }
                    } else { }
                    this.setState({
                        errors: errors
                    });
                })
                .catch((error) => {
                    console.log(error, "catch the loop")
                })
        }
        this.setState({ errors: errors })
    }

    changeHandler(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    changeHandlerTarget(e) {
        this.setState({ taregtTypeValue: e.target.value });
    }

    countryChange() {
        var x = document.getElementById("country").value;
        let fields = this.state.fields;
        fields["country"] = x;
        this.setState({ fields });
    }

    render() {
        const products = this.state.dataArray.map((dataArray, index) => {
            var attr1 = '';
            if (this.state.fields.model == dataArray) {
                attr1 = "selected";
            }
            return (
                <option value={dataArray} selected={attr1} >{dataArray}</option>
            )
        });

        const prerequisites = this.state.prerequisites.map((data, index) => {
            var attr3 = '';
            if (data.version.localeCompare(this.state.prerequisite) == 0) {
                attr3 = "selected";
            }
            return (
                <option value={data.id} >{data.version}</option>
            )
        });

        const targetdata = this.state.targetData.map((data, index) => {
            return (
                <MDBInput
                    type="radio"
                    name="targetType"
                    id={`target_type-${data.id}`}
                    label={data.name}
                    autoComplete="off"
                    value={data.name}
                    className="pull-left"
                    onChange={this.targetTypechange.bind(this, data.name)}
                >
                </MDBInput>
            )
        })

        return (
            <>
                <main class="content-div" >
                    <p> Builds
                        <div className="breadcrumb_div">
                            FOTA &gt; <Link to="./builds">Builds</Link > &gt; <span className="breadcrumb_page">Edit</span>
                        </div>
                    </p>
                    <form
                        className='needs-validation'
                        onSubmit={this.conformationtoggle}
                        noValidate
                    >
                        <div className="page-outerdiv">
                            <div>
                                <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <div class="alert alert-success alert-dismissible mt-2" id="suc_msg" style={{ display: "none" }} >
                                    </div>
                                    <span class=" form-title">Details:</span>
                                </div>
                                {/* <MDBCol md="4" >
                                    <div className="w_f">
                                        <label className="pull-left pt-3">Model:</label>
                                        <select className="browser-default custom-select select_height w-75 pull-right" id="product_id" value={this.state.model} onChange={this.productChange.bind(this)}>
                                            <option value="">Select Model</option>
                                            {products}
                                        </select>
                                    </div>
                                    <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["prodError"]}</span></span>
                                </MDBCol> */}
                                <MDBCol md="4" className="mt-2">
                                    <MDBInput
                                        value={this.state.fields["version_name"]}
                                        onChange={this.numberHandler.bind(this, "version_name")}
                                        type="text"
                                        label="Version"
                                        autoComplete="off"
                                    >
                                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["buildexist"]}</span></span>
                                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["buildnotexist"]}</span></span>
                                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["versionError"]}</span></span>
                                    </MDBInput>
                                </MDBCol>
                                <MDBCol md="4" >
                                    <div className="w_f">
                                        <label className="pull-left pt-3">Prerequisite:</label>
                                        <select className="browser-default custom-select select_height w-70 pull-right" id="prerequisites" value={this.state.prerequisite} onChange={this.prerequisiteChange.bind(this)}>
                                            <option value="">Select Prerequisite</option>
                                            {prerequisites}
                                        </select>
                                    </div>
                                    <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["prerequisite"]}</span></span>
                                </MDBCol>
                                <MDBCol md="4">
                                    <label className="mt-1" >
                                        <input type="file" class="simple_file_upload" id="buildFile" onChange={this.fileChange.bind(this)} />
                                        <span data-file={this.state.fields.fileName}>Choose Firmware file</span>
                                    </label>
                                    <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["firmwarenotmatched"]}</span></span>
                                </MDBCol>
                                <MDBCol md="4" >
                                    <label className="mt-1">
                                        <input type="file" class="simple_upload1" id="releaseNotes" onChange={this.releaseChange.bind(this)} />
                                        <span data-file={this.state.fields.releaseNote}>Choose Release Notes</span>
                                    </label>
                                    <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["releaseNoteError"]}</span></span>
                                    <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["releasenotmatched"]}</span></span>
                                </MDBCol>
                            </div>
                            <div className="row">
                                {/* Target Type */}
                                <MDBCol md="8" className="in-flex mt-4" id="target_typeid" style={{ "display": "none" }}>
                                    <span className="pull-left pl-1">Target Type:</span>
                                    {targetdata}
                                </MDBCol>
                                <MDBCol md="12" className="mt-3">
                                    <textarea
                                        value={this.state.taregtTypeValue}
                                        onChange={this.changeHandlerTarget.bind(this)}
                                        class="form-control rounded-0"
                                        placeholder="Please use comma separated"
                                        id="target-type-textarea"
                                        rows="3"
                                        style={{ "display": "none" }}>
                                    </textarea>
                                </MDBCol>
                                <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["chargepointidempty"]}</span></span>
                                <MDBCol md="12" className="text-right mt-3">
                                    <button className="refresh-btn mr-2" type='button' disabled={this.state.disabledAttr} onClick={this.conformationtoggle}>
                                        Save <i class="fas fa-save ml-2"></i>
                                    </button>
                                    <button className="refresh-btn text-secondary" type='button' style={{ borderColor: "grey" }} onClick={this.backToList}>
                                        Cancel   <i class="fas fa-times ml-2"></i>
                                    </button>
                                </MDBCol>
                            </div>
                        </div>
                    </form>
                </main>
                <div className="loader_img" id="loader_image_div" style={{ display: "none" }}>
                    <img src={process.env.PUBLIC_URL + "/assets/img/ajax-loading-gif-transparent-background-1.gif"} style={{ width: "138px" }} alt="" />
                </div>
                <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
                    <MDBModalHeader toggle={this.toggle}>Session has expired</MDBModalHeader>
                    <MDBModalBody>
                        Please login again.
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button type="button" data-test="button" className="btn_primary" onClick={this.tagOk}>OK</button>
                    </MDBModalFooter>
                </MDBModal>
                {/* popup for conformation */}
                <MDBModal isOpen={this.state.modal1} toggle={this.conformationtoggle} size="md" className="model_top">
                    <MDBModalHeader toggle={this.conformationtoggle}>Confirmation</MDBModalHeader>
                    <MDBModalBody>
                        Are you sure you want to save the changes?
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button type="button" data-test="button" className="btn_primary" onClick={this.conformationtoggle}>Close</button>
                        <button type="button" data-test="button" className="btn_primary" onClick={this.handleSubmit}>YES</button>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal isOpen={this.state.modal2} toggle={this.sucesstoggle} size="md" className="model_top">
                    <MDBModalHeader toggle={this.sucesstoggle}>Success</MDBModalHeader>
                    <MDBModalBody>
                        Build updated successfully.
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button type="button" data-test="button" className="btn_primary" onClick={this.sucesstoggle}>Close</button>
                        <button type="button" data-test="button" className="btn_primary" onClick={this.redirectBuild}>OK</button>
                    </MDBModalFooter>
                </MDBModal>
            </>
        );
    }
}

export default editBuild;