import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import Pagination from "react-js-pagination";
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRow, MDBCol, MDBInput, MDBBtn } from "mdbreact";
import { Form, Table } from 'react-bootstrap';
import $ from "jquery";
import DatePicker from "react-datepicker";
import * as baseUrl from './commons/constants';
import * as Roles from './commons/roles';
import { hasPermission } from './commons/auth';
import moment from 'moment';

export default class ChargersReports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            dataArray: [],
            modalsession: false,
            fromDate: '',
            fromDateApi: '',
            toDate: '',
            toDateApi: ''
        }
        this.getTransactions = this.getTransactions.bind(this);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.dateformatinUTC = this.dateformatinUTC.bind(this);
        this.exportTransactions = this.exportTransactions.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.sessiontagOk = this.sessiontagOk.bind(this);
    }

    componentDidMount() {
        let fromDate = moment().subtract(7, 'd');
        let fromDateApi = this.dateformatinUTC(moment().subtract(7, 'd'));
        let toDate = new Date();
        let toDateApi = this.dateformatinUTC(moment(toDate));

        this.setState({
            fromDate: new Date(fromDate),
            fromDateApi: fromDateApi,
            toDate: toDate,
            toDateApi: toDateApi,
        }, () => {
            this.getTransactions();
        });
    }

    getTransactions() {
        var url = baseUrl.WebPath + "billings/consolidate/report/data?startTime=" + this.state.fromDateApi + "&endTime=" + this.state.toDateApi;

        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("Authorization")
            }
        })
            .then((resp) => {
                if (resp.status == 401) {
                    this.togglesession();
                    localStorage.clear();
                } else if (resp.status == 200) {
                    return resp.json();
                }
            })
            .then((response) => {
                if (response != undefined) {
                    this.setState({
                        dataArray: response?.data ?? []
                    });
                }
            })
            .catch((error) => {
                console.log(error, "catch the loop")
            })
    }

    async togglesession() {
        this.setState({
            modalsession: !this.state.modalsession
        });
    }

    sessiontagOk() {
        this.props.history.push('/');
        window.location.reload();
    }

    // for from date calender 
    handleFromChange = date => {
        $('.future_err1').text("");
        let fromD = '';
        if (date != undefined && date != "" && date != null) {
            fromD = this.dateformatinUTC(date)
        }
        this.setState({
            fromDate: date,
            fromDateApi: fromD
        }, () => {
            this.getTransactions();
        });
    }

    // for to date calender 
    handleToChange = date => {
        let toD = '';
        if (date != undefined && date != "" && date != null) {
            toD = this.dateformatinUTC(date)
        }

        this.setState({
            toDate: date,
            toDateApi: toD
        }, () => {
            this.getTransactions()
        });
    }

    dateformatinUTC(date) {
        var currentDate = new Date(date);
        var twoDigitMonth = ((currentDate.getUTCMonth() + 1) >= 10) ? (currentDate.getUTCMonth() + 1) : '0' + (currentDate.getUTCMonth() + 1);
        var twoDigitDate = ((currentDate.getUTCDate()) >= 10) ? (currentDate.getUTCDate()) : '0' + (currentDate.getUTCDate());
        var twohoursDate = ((currentDate.getUTCHours()) >= 10) ? (currentDate.getUTCHours()) : '0' + (currentDate.getUTCHours());
        var twominDate = ((currentDate.getUTCMinutes()) >= 10) ? (currentDate.getUTCMinutes()) : '0' + (currentDate.getUTCMinutes());
        var twosecDate = ((currentDate.getUTCSeconds()) >= 10) ? (currentDate.getUTCSeconds()) : '0' + (currentDate.getUTCSeconds());
        var twomillisecDate = ((currentDate.getUTCMilliseconds()) >= 10) ? (currentDate.getUTCMilliseconds()) : '0' + (currentDate.getUTCMilliseconds());
        var csFormatDate = currentDate.getUTCFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate + "T" + twohoursDate + ":" + twominDate + ":" + twosecDate + ":" + twomillisecDate;
        return csFormatDate;
    }

    exportTransactions() {
        var url = baseUrl.WebPath + "billings/consolidate/report/export?fileType=" + "xlsx" + "&startTime=" + this.state.fromDateApi + "&endTime=" + this.state.toDateApi;

        fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization"),
            }
        }).then((resp) => {
            if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            } else if (resp.status == 200) {
                return resp.blob();
            }
        }).then((response) => {
            if (response != undefined) {
                let d = new Date();
                let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
                let url = window.URL.createObjectURL(response);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'Reports_' + dformat + '.xls';
                a.click();
            }
        }).catch((error) => {
            console.log(error, "catch the loop")
        })
    }

    render() {

        let reports = this.state.dataArray?.map((billing, index) => {
            return (
                <tr>
                    <td>{billing.serialNo ?? "-"}</td>
                    <td>{billing.name ?? "-"}</td>
                    <td>{billing.chargedEnergy ?? "-"}</td>
                </tr>
            );
        })

        return (
            <main className="content-div">
                <p> Chargers Report
                    <div className="breadcrumb_div">Report &gt; <span className="breadcrumb_page">Chargers Report</span></div>
                </p>

                <div className="page-outerdiv">
                    <div className="row ">
                        <MDBCol md="4" className='pr-0 calWidth'>
                            <span className="cal_label mr-2">From Time: </span>
                            <div className="pull-left">
                                <DatePicker
                                    selected={this.state.fromDate}
                                    onChange={this.handleFromChange}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    id="expiryDateId"
                                    className="cal_margin tag_callogs"
                                    timeCaption="time"
                                    dateFormat="dd-MMM-yyyy HH:mm"
                                    maxDate={(this.state.toDate != "" && this.state.toDate != null) ? new Date(this.state.toDate) : new Date()}
                                />
                            </div>
                            <div className="error_msg w3-animate-top col-md-12 datetimeerror pl-0" style={{ color: "red" }}>{this.state.errors["expirydate"]}</div>
                        </MDBCol>

                        <MDBCol md="4" className='pr-0 calWidth'>
                            <span className="cal_label mr-2">To Time: </span>
                            <div className="pull-left" >
                                <DatePicker
                                    selected={this.state.toDate}
                                    onChange={this.handleToChange}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    id="expiryDateId"
                                    className="cal_margin tag_callogs"
                                    timeCaption="time"
                                    dateFormat="dd-MMM-yyyy HH:mm"
                                    minDate={new Date(this.state.fromDate)}
                                    maxDate={new Date()}
                                />
                            </div>
                            <div className="error_msg w3-animate-top col-md-12 datetimeerror pl-0" style={{ color: "red" }}>{this.state.errors["expirydate"]}</div>
                        </MDBCol>

                        <div className="col-md-2 pl-0 mt-2">
                                <button className="btn_primary pull-right " onClick={this.exportTransactions.bind(this)} type="button" data-test="button">
                                    Export <i className="fas fa-file-export ml-2"></i>
                                </button>
                        </div>
                    </div>

                    <div className="row ">
                        <div className="col-md-12">
                            <Table striped hover size="sm" className="page-table">
                                <thead>
                                    <tr>
                                        <th>Serial No</th>
                                        <th>Wallbox Name</th>
                                        <th>Charged Energy (kWh)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reports}
                                </tbody>
                            </Table>
                        </div>
                        {/* End table  */}
                    </div>
                </div>
            </main>
        );
    }
}