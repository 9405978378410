import React from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn } from 'mdbreact';
import { Form, Table } from 'react-bootstrap'
import * as baseUrl from '../views/commons/constants';
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import moment from 'moment';
import Pagination from "react-js-pagination";
import { Alert } from 'reactstrap';

class builds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modal1: false,
      startDate: new Date(),
      buildStatus: "Active",
      popupStatus: "",
      build_id: "",
      dataArray: [],
      page_title: "Builds",
      products: [],
      buildTypes: [],
      noOfRecords: '',
      data: new Map(),
      pageSize: "10",
      activePage: 1,
      message: "",
      sucess: false,
      colour: 'sucess',
      orgsArray:[]
    }

    this.addBuild = this.addBuild.bind(this);
    this.backToList = this.backToList.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.onchangeProduct = this.onchangeProduct.bind(this);
    this.onChangeBuildType = this.onChangeBuildType.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleArchive = this.toggleArchive.bind(this);
    this.tagOk = this.tagOk.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.AddArchive = this.AddArchive.bind(this);
    this.getBuildStatus = this.getBuildStatus.bind(this);
    this.onChangeBuildSatus = this.onChangeBuildSatus.bind(this);
    this.editBuild = this.editBuild.bind(this);
    this.CloseArchive = this.CloseArchive.bind(this);
    this.getOrganizations = this.getOrganizations.bind(this);
  }
  onShowAlert() {
    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });
  }
  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    this.state.data.clear();
    this.setState({ pageSize: entries });
    this.getBuilds(entries, 1);
  }
  editBuild() {
    this.props.history.push('/editBuild')
  }
  handlePageChange(pageNumber) {
    if (pageNumber != this.state.activePage) {
      var data = this.state.data;
      var pageData = data.get(pageNumber);
      if (pageData == undefined) {
        this.setState({ activePage: pageNumber });
        this.getBuilds(this.state.pageSize, pageNumber);
        this.getBuildStatus(this.state.pageSize, pageNumber);
      } else {
        this.setState({ activePage: pageNumber });
        this.setState({ dataArray: pageData })
      }
    }
  }
  tagOk() {
    this.props.history.push('/');
    window.location.reload();
  }
  toggle = (boolean) => {
    this.setState({
      modal: boolean
    });
  }
  //toggle function for archive popup  
  toggleArchive = (build_type_id, text) => {
    this.setState({
      modal1: !this.state.modal1,
      build_id: build_type_id,
      popupStatus: text
    });
  }
  CloseArchive = () => {
    this.setState({
      modal1: !this.state.modal1
    });
  }
  // function to add in archive list 
  AddArchive() {
    this.setState({
      modal1: !this.state.modal1
    });
    var buildId = this.state.build_id;
    var isArchive_val = "";
    if (this.state.popupStatus == "Active") {
      isArchive_val = false;
    } else {
      isArchive_val = true;
    }
    var url = baseUrl.WebPath + "dashboard/build/" + buildId + "/archive?isArchive=" + isArchive_val;
    fetch(url, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle(true)
          localStorage.clear();
        }
        if (resp.status == 412) {
          this.setState({ modal2: false })
          this.setState({ message: "Failed to archive as this version is prerequisite of another version.", colour: 'danger' })
          this.onShowAlert();
          return resp.json();
        }
        if (resp.status == 200) {
          this.setState({ modal2: false })
          this.setState({ message: "This build is added to " + this.state.popupStatus.toLowerCase() + " sucessfully.", colour: 'success' })
          this.onShowAlert();
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.getBuildStatus(this.state.pageSize, 1);
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }
  //change function for build status filter
  onChangeBuildSatus() {
    var Status = document.getElementById("buildStatusId").value;
    this.state.buildStatus = Status;
    this.getBuildStatus(this.state.pageSize, 1);
  }
  backToList() {
    this.props.history.push('/productList')
  }
  getProducts() {
    var orgId = document.getElementById("organization").value;
    this.setState({ orgId: orgId })
    if (orgId != '') {
      var url = baseUrl.WebPath + "dashboard/" + orgId + "/products?pageNo=0&pageSize=0&status";
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization")
        }
      })
        .then((resp) => {
          if (resp.status == 401) {
            this.toggle(true)
            localStorage.clear();
          }
          if (resp.status == 200) {
            return resp.json();
          }
        })
        .then((response) => {
          if (response != undefined) {
            this.setState({ products: response.productList })
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
    } else {
      this.setState({ dataArray: [] });
    }
  }
  onchangeProduct() {
    var product_id = document.getElementById("productId").value;

    var url = baseUrl.WebPath + "dashboard/buildtypes/" + product_id + "?pageNo=0&pageSize=0";
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle(true)
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response.length > 0) {
          this.setState({ buildTypes: response });
        } else {
          this.setState({ buildTypes: [] });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
    this.setState({ buildTypes: [] })
    this.getBuilds(this.state.pageSize, 1);
  }
  onChangeBuildType() {
    var buildTypeId = document.getElementById("buildTypeId").value;
    var productId = document.getElementById("productId").value;
    if (productId != "" && buildTypeId != "") {
      var url = baseUrl.WebPath + "dashboard/builds?product_id=" + productId + "&build_type_id=" + buildTypeId + "&pageNo=1&pageSize=" + this.state.pageSize + "&status=" + this.state.buildStatus;
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization")
        }
      })
        .then((resp) => {
          if (resp.status == 401) {
            this.toggle(true)
            localStorage.clear();
          }
          if (resp.status == 200) {
            return resp.json();
          }
        })
        .then((response) => {
          if (response != undefined) {
            this.setState({ dataArray: response.data, noOfRecords: response.count.count })
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
    }
  }
  getBuilds(pageSize, activePage) {
    var productId = document.getElementById("productId").value;
    var buildType = document.getElementById("buildTypeId").value;
    if (buildType == "") {
      buildType = 0
    }
    if (productId != "") {
      var url = baseUrl.WebPath + "dashboard/builds?product_id=" + productId + "&build_type_id=" + buildType + "&pageSize=" + pageSize + "&pageNo=" + activePage + "&status=" + this.state.buildStatus;
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization")
        }
      })
        .then((resp) => {
          if (resp.status == 401) {
            this.toggle(true)
            localStorage.clear();
          }
          if (resp.status == 200) {
            return resp.json();
          }
        })
        .then((response) => {
          if (response != undefined) {
            this.setState({ dataArray: response.data, noOfRecords: response.count.count });
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
    }
  }
  getBuildStatus(pageSize, activePage) {
    var fds = this.props.history.state;
    var productId = "";
    var url = "";
    var productId = document.getElementById("productId").value;
    var buildType = document.getElementById("buildTypeId").value;
    if (buildType == "") {
      buildType = 0
    }
    if (productId == "" && buildType == "") {
      url = baseUrl.WebPath + "dashboard/builds?product_id=0&build_type_id=0&pageSize=" + pageSize + "&pageNo=" + activePage + "&status=" + this.state.buildStatus;
    } else {
      url = baseUrl.WebPath + "dashboard/builds?product_id=" + productId + "&build_type_id=" + buildType + "&pageSize=" + pageSize + "&pageNo=" + activePage + "&status=" + this.state.buildStatus;
    }
    this.setState({ productId: productId })

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle(true);
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ dataArray: response.data, noOfRecords: response.count.count });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }
  componentDidMount() {
    this.getOrganizations();
    this.getBuildStatus(this.state.pageSize, 1);
  }
  addBuild() {
    this.props.history.push('/addBuild');
  }
  async getOrganizations(){
    var url = baseUrl.URLPath + "admin/v1.0/account/all";
    await fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({orgsArray : response.organisationDTOS })
        } else {
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }
  render() {
    let userTenant = localStorage.getItem("tenant");
    let selectedId = "";

    const products = this.state.products.map((dataArray, index) => {
      return (
        <option value={dataArray.id}>{dataArray.product_name}</option>
      )
    })

    const buildTypes = this.state.buildTypes.map((dataArray, index) => {
      return (
        <option value={dataArray.id}>{dataArray.name}</option>
      )
    });

    const data = this.state.dataArray.map((dataArray, index) => {
      var trsactionData = {
        date: this.state.startDate,
        dataArray
      }
      var archiveicon = "";
      var unarchiveicon = "";
      if (dataArray.isArchive == false) {
        archiveicon = "inline-block";
        unarchiveicon = "none";
      } else {
        archiveicon = "none";
        unarchiveicon = "inline-block";
      }

      var date = moment(dataArray.created_time).format("DD-MMM-YYYY HH:mm").toUpperCase();
      var modified_date = moment(dataArray.modified_time).format("DD-MMM-YYYY HH:mm").toUpperCase();

      return (
        <tr key={index}>
          {/* <td><Link to={{
              pathname:'/meterValues', 
              state: trsactionData, 
              }}>{ dataArray.transactionId }</Link></td> */}
          <td>{dataArray.version_name}</td>
          <td>{dataArray.version_code}</td>
          <td>{date}</td>
          <td>{modified_date}</td>
          {/* <td>{dataArray.download_url}</td> */}
          <td>{dataArray.prerequisite}</td>
          {/* <td>{dataArray.releaseNotes_URL}</td> */}
          <td >
            <a href={dataArray.download_url} download> <i class="fas fa-download action-icon" title="Download Firmware"></i></a>
            <a href={dataArray.releaseNotes_URL} download ><i class="fas fa-sticky-note pl-2 pr-2 action-icon" title="Release Notes"></i></a>
            <Link to={{
              pathname: '/editBuild',
              state: dataArray.id,
            }} download style={{ display: archiveicon }} ><i class="fas fa-pencil-alt   pr-2 action-icon" title="Edit"></i></Link>
            <i class="fas fa-pencil-alt pr-2 action-icon edit_archive_icon" style={{ display: unarchiveicon }} title="Release Notes"></i>
            <i class="fas fa-archive action-icon cursor_defult" style={{ display: archiveicon }} title="Archive" onClick={this.toggleArchive.bind(this, dataArray.id, "Archive")}></i>
            <label onClick={this.toggleArchive.bind(this, dataArray.id, "Active")} className="unarchive_icon mb-0 cursor_defult" title="Active" style={{ display: unarchiveicon }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M20.55 5.22l-1.39-1.68C18.88 3.21 18.47 3 18 3H6c-.47 0-.88.21-1.15.55L3.46 5.22C3.17 5.57 3 6.01 3 6.5V19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6.5c0-.49-.17-.93-.45-1.28zM12 9.5l5.5 5.5H14v2h-4v-2H6.5L12 9.5zM5.12 5l.82-1h12l.93 1H5.12z" /></svg></label>
          </td>
        </tr>
      )
    });

    const organisationDTOS = this.state.orgsArray.map((dataArray, index) => {
      return (
        <option value={dataArray.id}>{dataArray.company}</option>
      )
    });

    return (
      <>
        <main class="content-div">
            <p> Builds
                <div className="breadcrumb_div">
                    FOTA &gt; Builds
                </div>
            </p>
          <div className="page-outerdiv">
            <div className="row menubar mt-3">
              <div className="col-md-12 ">
                <div className="">
                  <button >
                    <Link to={{
                      pathname: '/addBuild',
                      state: this.state.productId,
                    }} >
                      <i className="fa fa-plus">
                      </i>
                      <span>Add</span>
                    </Link>
                  </button>
                </div>
              </div>
              <div className="col-md-3  pull-right mt-2">
                <select className="browser-default custom-select select_height" id="organization" onChange={this.getProducts.bind(this)}>
                  <option value="">Select Organization</option>
                  {organisationDTOS}
                </select>
              </div>
              <div className=" col-md-3  pull-right mt-2">
                <select className="browser-default custom-select select_height " id="productId" onChange={this.onchangeProduct}>
                  <option value="0">Select Product</option>
                  {products}
                </select>
              </div>
              <div className=" col-md-3  pull-right mt-2">
                <select className="browser-default custom-select select_height " id="buildTypeId" onChange={this.onChangeBuildType}>
                  <option value="0">Select Build Type</option>
                  {buildTypes}
                </select>
              </div>

              <div className=" col-md-3  pull-right mt-2">
                <select className="browser-default custom-select select_height " id="buildStatusId" onChange={this.onChangeBuildSatus}>
                  <option value="">Select Status</option>
                  <option value="All">All</option>
                  <option value="Active">Active</option>
                  <option value="Archive">Archive</option>
                </select>
              </div>
            </div>
            <Alert color={this.state.colour} isOpen={this.state.sucess} className="mt-2" >{this.state.message}</Alert>

            {/* end page menu bar  */}
            <div className="row mt-4">
              <div className="col-md-12">
                <label className="pull-left sel_lbl">Show entries</label>
                <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left " >
                  <Form.Control as="select" className="form_select showentry_sel" id="showEntries" onChange={this.showEntriesChange}>
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                    <option>50</option>
                  </Form.Control>
                </Form.Group>
              </div>
            </div>
            {/* table start */}
            <div className="row mt-1 ">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th>Version</th>
                      <th>Version Code</th>
                      <th>Release Date</th>
                      <th>Modified Date</th>
                      <th>Prerequisite</th>
                      <th >Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data}
                    {
                      (this.state.dataArray.length == 0) ?
                        <tr>
                          <td colSpan="6" style={{ textAlign: "center" }}>No Data Available</td>
                        </tr> : null
                    }
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 mt-4">
                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
              {/* End table  */}
            </div>
          </div>
        </main>
        <MDBModal isOpen={this.state.modal} size="md" className="model_top">
          <MDBModalHeader>Session has expired</MDBModalHeader>
          <MDBModalBody>
            Please login again.
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={this.tagOk}>OK</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        {/* confirmation message popup for archive  */}
        <MDBModal isOpen={this.state.modal1} toggle={this.toggleArchive} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggleArchive}>Confirmation</MDBModalHeader>
          <MDBModalBody>
            Do you want to {this.state.popupStatus.toLowerCase()} this build?
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.CloseArchive}>Close</MDBBtn>
            <MDBBtn color="primary" onClick={this.AddArchive} >Yes</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default builds;