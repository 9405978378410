import React from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn, MDBRow, MDBCol, MDBInput, Alert } from 'mdbreact';
import { Form, Table } from 'react-bootstrap';
import * as baseUrl from '../../commons/constants';

export default class PaymentGateway extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataArray: [],
            modal: false,
            modalLogout: false,
            fields: {
                saveValue: false,
                saveName:""
            },
            loginModal: false,
            editModal: false,
            saveId: '',
            message:'',
            colour:'',
            sucess: false,
            errors:{},
            accountId: "",
            splitPaymentRatio: ""
        }
        this.numberHandler = this.numberHandler.bind(this);
        this.CloseLogOutArchive = this.CloseLogOutArchive.bind(this);
        this.toggleLogout = this.toggleLogout.bind(this);
        this.onShowAlert = this.onShowAlert.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.closeLogin = this.closeLogin.bind(this);
        this.toggle = this.toggle.bind(this);
        this.getWallBox = this.getWallBox.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.closeSuccess = this.closeSuccess.bind(this);
        this.closeToggleEdit = this.closeToggleEdit.bind(this);
    }

    handleChange = (event) => {
      const { name, value } = event.target;
  
      // Check if the value contains only numbers (allowing empty string)
      if (/^[0-9]*$/.test(value) || value === '') {
        this.setState({
          [name]: value,
        });
      }
    };

      CloseLogOutArchive = () => {
        this.setState({
          modalLogout: !this.state.modalLogout
        });
      }

      toggleLogout() {
        this.setState({
          modalLogout: !this.state.modalLogout,
          editModal: !this.state.editModal
        });
      }

      closeSuccess(){
        this.setState({
          sucess: !this.state.sucess
        })
      }

      onShowAlert() {
        this.setState({ sucess: true });
      }

      toggleEdit(id, name, value, accountId, splitPaymentRatio) {
        let fields = this.state.fields;
        fields.saveValue = value;
        fields.saveName = name;

        this.setState({
          editModal: !this.state.editModal,
          saveId: id,
          accountId: accountId,
          splitPaymentRatio: splitPaymentRatio,
          ...fields
        });
      }

      closeToggleEdit(){
        this.setState({
          editModal: !this.state.editModal
        });
      }

      //This is for making user should enter only numbers
      numberHandler(field, e) {
        e.preventDefault();
        let fields = this.state;
        fields[field] = e.target.value;
        this.setState({ fields: fields });
      }

      closeLogin() {
        this.props.history.push('/');
        window.location.reload();
      }

      toggle = (boolean) => {
        this.setState({
            loginModal: boolean
        });
      }

      async getWallBox() {
        var url = baseUrl.URLPath + "web/settings";
    
        await fetch(url, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            "Authorization": localStorage.getItem("Authorization")
          }
        })
          .then((resp) => {
            if (resp.status == 401) {
              this.toggle(true);
            }
            if (resp.status == 200) {
              return resp.json();
            }
          })
          .then((response) => {
            if (response != undefined) {
              let data = [];

              response.map((record, index)=>{
                if(record.settingName === "Payment Gateway") {
                  data.push(record);
                }
              });

              this.setState({ dataArray: data });
            }
            console.log(this.state.dataArray);
          })
          .catch((error) => {
            console.log(error, "catch the loop")
          })
      }

      componentDidMount() {
        document.getElementById("loader_image_div").style.display = "block";
        this.getWallBox();
        setTimeout(() => {
          document.getElementById("loader_image_div").style.display = "none";
        }, 100);
      }

    updateValue() {
        this.setState({
            modalLogout: !this.state.modalLogout
        });
        var value = this.state.fields.saveValue;
        var getId = this.state.saveId;
        var name = this.state.fields.saveName;
        var defaultAccountId = this.state.accountId;
        var splitPaymentRatio = this.state.splitPaymentRatio;
        let errors = {};

        var url = baseUrl.URLPath + "web/settings";
        let data = { "id": getId, "settingName": name, "isStripePaymentGatewayEnabled": value, "accountId": defaultAccountId, "splitPaymentRatio": splitPaymentRatio, "tenantId": localStorage.getItem('tenant') };

        fetch(url, {
            method: 'PUT', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization")
            }
        }).then((resp) => {
            if (resp.status == 200) {
                this.setState({ editModal: false, message: "Payment Gateway status updated sucessfully", colour: 'success', sucess: true }, () => {
                    this.getWallBox();
                    window.location.reload();
                })
            }
            else if (resp.status == 400) { }
            else if (resp.status == 403) { }
            else if (resp.status == 401) {
                this.toggle(true);
            } else if (resp.status == 404) {
                return resp.json();
            } else {
                return resp.json();
            }
        }).then((response) => {
            this.setState({ errors: errors })
        }).catch(error => alert('Error:' + error));

        this.setState({ errors: errors });
    }

    render() {
        return (
            <>
                <main class="content-div">
                    <p>Payment Gateway
                        <div className="breadcrumb_div">Settings &gt; <span className="breadcrumb_page">Payment Gateway</span></div>
                    </p>
                    <div className="page-outerdiv">
                        {/* table start */}
                        <div className="row mt-2 ">
                            <div className="col-md-12">
                                <Table striped hover size="sm" className="page-table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            {this.state.dataArray.map((item) => {
                                              return (
                                                <th>{item.isStripePaymentGatewayEnabled ? "Is Stripe Enabled?" : "Is Authorize.net Enabled?"}</th>
                                              )
                                            })}
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className="vertical-center" >
                                        {this.state.dataArray.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td>{item.settingName}</td>
                                                    {/* <td style={{ paddingLeft: 50 }}>{item.isStripePaymentGatewayEnabled ? "Yes" : "No"}</td> */}
                                                    <td style={{ paddingLeft: 50 }}>Yes</td>
                                                    <td>
                                                        <i class="fas fa-pencil-alt pr-2 action-icon edit_usericon" title="Enable/Disable Payment Gateway" onClick={this.toggleEdit.bind(this, item.id, item.settingName, item.isStripePaymentGatewayEnabled, item.accountId, item.splitPaymentRatio)}></i>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        {
                                            (this.state.dataArray.length == 0) ?
                                                <tr>
                                                    <td colSpan="3" style={{ textAlign: "center" }}>No Data Available</td>
                                                </tr> : null
                                        }
                                    </tbody>
                                </Table>
                            </div>
                            {/* End table  */}
                        </div>
                    </div>
                </main>
                <div className="loader_img" id="loader_image_div" style={{ display: "none" }}>
                    <img src={process.env.PUBLIC_URL + "/assets/img/ajax-loading-gif-transparent-background-1.gif"} style={{ width: "138px" }} alt="" />
                </div>

                <MDBModal isOpen={this.state.loginModal} size="md" className="model_top">
                    <MDBModalHeader>Session has expired</MDBModalHeader>
                    <MDBModalBody>
                        Please login again
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="primary" onClick={this.closeLogin}>OK</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

                <MDBModal isOpen={this.state.sucess} size="md" className="model_top">
                    <MDBModalHeader>Success</MDBModalHeader>
                    <MDBModalBody>
                        {this.state.message}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="primary" onClick={this.closeSuccess}>OK</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

                {/* confirmation message popup for edit  */}
                <MDBModal isOpen={this.state.editModal} size="md" className="model_top">
                    <MDBModalHeader>Payment Gateway</MDBModalHeader>
                    <MDBModalBody className="needs-validation">
                        <MDBRow>
                            <div className="col-md-7 pl-2">
                                <Form.Check
                                    className=""
                                    custom
                                    id={`paymentGateway`}
                                    type="checkbox"
                                    disabled
                                    checked={this.state.fields.saveValue}
                                    onChange={() => {
                                        let fields = this.state.fields;
                                        fields.saveValue = !fields.saveValue;
                                        this.setState({ fields })
                                    }}
                                    label={'Payment Gateway'}
                                />
                            </div>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol md="6" className="mt-3">
                            <MDBInput
                              name="accountId"
                              value={this.state.accountId ?? ''}
                              id="accountId"
                              label="Default Account ID"
                              autoComplete="off"
                              onInput={this.numberHandler.bind(this, "accountId")}
                            >
                            </MDBInput>
                            <span className="pull-left error_msg w3-animate-top mt-1" style={{ color: "red" }}>{this.state.errors["selectAccountId"]}</span>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol md="6" className="mt-3">
                            <MDBInput
                              name="splitPaymentRatio"
                              value={this.state.splitPaymentRatio ?? ''}
                              id="splitPaymentRatio"
                              label="Split Payment Ratio"
                              autoComplete="off"
                              onInput={this.numberHandler.bind(this, "splitPaymentRatio")}
                              onChange={this.handleChange}
                              onKeyPress={(event) => {
                                // Allowing only numbers, backspace, and delete key
                                const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete'];
                                if (!allowedKeys.includes(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            >
                            </MDBInput>
                            <span className="pull-left error_msg w3-animate-top mt-1" style={{ color: "red" }}>{this.state.errors["selectAccountId"]}</span>
                          </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="danger" onClick={this.closeToggleEdit}>Close</MDBBtn>
                        <MDBBtn color="primary" onClick={this.toggleLogout}>Update</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

                {/* confirmation message for update */}
                <MDBModal isOpen={this.state.modalLogout} size="md" className="model_top">
                    <MDBModalHeader>Confirmation</MDBModalHeader>
                    <MDBModalBody>
                        Do you want to update?
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={this.CloseLogOutArchive.bind(this)}>Close</MDBBtn>
                        <MDBBtn color="primary" onClick={this.updateValue}>Yes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </>
        );
    }
}