import React from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn, MDBNav, MDBNavLink, MDBNavItem, MDBTabPane, MDBTabContent } from 'mdbreact';
import { Form, Table } from 'react-bootstrap';
import * as baseUrl from './commons/constants';
import Pagination from "react-js-pagination";
import { Link } from 'react-router-dom';
import * as Roles from './commons/roles';

class wallBoxUserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enableSelectStatus: "",
      wallBoxNo: "",
      modal: false,
      activeItem: "1",
      page_title: "Wall Box Users",
      dataArray: [],
      dataArray1: [],
      dataArray2:[],
      noOfRecords: '',
      data: new Map(),
      pageSize: "10",
      activePage: 1,
      activeItem: "1",
      adminStatus: 1,
      pageSize1: 10,
      data1: new Map(),
      activePage1: 1,
      noOfRecords1: '',
      noOfRecords2: '',
      pageSize2: 10,
      activePage2:1,
      data2 : new Map()
    }
    this.tabToggle = this.tabToggle.bind(this);
    this.backToList = this.backToList.bind(this);
    this.toggle = this.toggle.bind(this);
    this.tagOk = this.tagOk.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.getAdmin = this.getAdmin.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.getActiveCount = this.getActiveCount.bind(this);
    this.adminsStatusChanged = this.adminsStatusChanged.bind(this);
    this.showAdminChange = this.showAdminChange.bind(this);
    this.getUserAcount = this.getUserAcount.bind(this);
    this.handlePageChangeUser = this.handlePageChangeUser.bind(this);
    this.StatusUser = this.StatusUser.bind(this);
    this.getUser = this.getUser.bind(this);
    this.changeEntries = this.changeEntries.bind(this);
    this.getCloudUsers = this.getCloudUsers.bind(this);
    this.handlePageChangeCloud = this.handlePageChangeCloud.bind(this);
  }
  changeEntries(){
    var entries = document.getElementById("showCloudUsers").value;
    this.setState({ pageSize2: entries });
    this.getCloudUsers(1, entries);
  }
  async getCloudUsers(pageNo, pageSize){
    var url = baseUrl.URLPath + "web/chargers/" + localStorage.getItem('selectedSerialNo') + "/cloudusers?pageNo=" + pageNo + "&pageSize=" + pageSize;

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle()
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ dataArray2: response })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }
  async getCloudCount(){
    var url = baseUrl.URLPath + "web/chargers/" + localStorage.getItem('selectedSerialNo') + "/cloudusers/count";

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle()
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ noOfRecords2: response.count })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }
  // tab view click
  tabToggle(tab, event) {
    let getSelectedSno = this.props.location.state;
    if (getSelectedSno != undefined) {
      localStorage.setItem('saveSno', JSON.stringify(this.props.location.state)); //stringify object and store
    } else {
      var getSavedSno = JSON.parse(localStorage.getItem('saveSno')); //retrieve the object
      this.props.location.state = getSavedSno;
    }
    this.setState({
      currentTab: tab
    });
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab
      });
      let getSelectedSno = this.props.location.state;
      if (getSelectedSno != undefined) {
        localStorage.setItem('saveSno', JSON.stringify(this.props.location.state)); //stringify object and store
      } else {
        var getSavedSno = JSON.parse(localStorage.getItem('saveSno')); //retrieve the object
        this.props.location.state = getSavedSno;
      }
    }
    if (tab == 2) {
      this.getUserAcount();
      this.getUser(this.state.activePage1, this.state.pageSize1);
      let getSelectedSno = this.props.location.state;
      if (getSelectedSno != undefined) {
        localStorage.setItem('saveSno', JSON.stringify(this.props.location.state)); //stringify object and store
      } else {
        var getSavedSno = JSON.parse(localStorage.getItem('saveSno')); //retrieve the object
        this.props.location.state = getSavedSno;
      }
    }
    if (tab == 1) {
      this.getActiveCount();
      let getSelectedSno = this.props.location.state;
      if (getSelectedSno != undefined) {
        localStorage.setItem('saveSno', JSON.stringify(this.props.location.state)); //stringify object and store
      } else {
        var getSavedSno = JSON.parse(localStorage.getItem('saveSno')); //retrieve the object
        this.props.location.state = getSavedSno;
      }
    }
    if (tab == 3) {
      this.getCloudUsers(1,10);
      this.getCloudCount();
    }
    this.getActiveCount();
  };
  getUserAcount() {
    if (this.state.userStatus == 2) {
      var url = baseUrl.URLPath + "web/chargers/" + this.state.serialNumber + "/inactiveUsers/count";
    } else {
      var url = baseUrl.URLPath + "web/chargers/" + this.state.serialNumber + "/users/count";
    }
    fetch(url, {
      method: "get",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ noOfRecords1: response.count });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
    this.getUser(this.state.activePage1, this.state.pageSize1, this.state.userStatus);
  }
  StatusUser() {
    var entries = document.getElementById("status_Select_id").value;
    this.state.userStatus = entries;
    this.getUserAcount()
    this.getUser(this.state.activePage1, this.state.pageSize1, this.state.userStatus)
  }
  async getUser(pageNo1, pageSize1, status) {
    if (status == "2") {
      var url = baseUrl.URLPath + "web/chargers/" + localStorage.getItem('selectedSerialNo') + "/inactiveUsers?pageNo=" + pageNo1 + "&pageSize=" + pageSize1;
    } else {
      var url = baseUrl.URLPath + "web/chargers/" + localStorage.getItem('selectedSerialNo') + "/users?pageNo=" + pageNo1 + "&pageSize=" + pageSize1;
    }
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle()
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ dataArray1: response })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }
  handlePageChangeUser(pageNumber) {
    if (pageNumber != this.state.activePage1) {
      var data1 = this.state.data1;
      var pageData = data1.get(pageNumber);
      if (pageData == undefined) {
        this.setState({ activePage1: pageNumber });
        this.getUser(pageNumber, this.state.pageSize1, this.state.userStatus);
      } else {
        this.setState({ activePage1: pageNumber });
        this.setState({ dataArray1: pageData })
      }
    }
  }
  handlePageChangeCloud(pageNumber){
    if (pageNumber != this.state.activePage2) {
      var data2 = this.state.data2;
      var pageData = data2.get(pageNumber);
      if (pageData == undefined) {
        this.setState({ activePage2: pageNumber });
        this.getCloudUsers(pageNumber, this.state.pageSize2);
      } else {
        this.setState({ activePage2: pageNumber });
        this.setState({ dataArray2: pageData })
      }
    }
  }
  handlePageChange(pageNumber) {
    if (pageNumber != this.state.activePage) {
      var data = this.state.data;
      var pageData = data.get(pageNumber);
      if (pageData == undefined) {
        this.setState({ activePage: pageNumber });
        this.getAdmin(pageNumber, this.state.pageSize, this.state.adminStatus);
      } else {
        this.setState({ activePage: pageNumber });
        this.setState({ dataArray: pageData })
      }
    }
  }
  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    this.state.data1.clear();
    this.setState({ pageSize1: entries });
    this.getUser(1, entries, this.state.userStatus);
  }
  showAdminChange() {
    var entries = document.getElementById("showAdminEntries").value;
    this.state.data.clear();
    this.setState({ pageSize: entries });
    this.getAdmin(1, entries, this.state.adminStatus);
  }
  backToList() {
    localStorage.setItem('lastTabName','');
    this.props.history.push('/wallboxList');
  }
  tagOk() {
    this.props.history.push('/');
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }
  adminsStatusChanged() {
    let getSelectedSno = this.props.location.state
    if (getSelectedSno != undefined) {
      localStorage.setItem('saveSno', JSON.stringify(this.props.location.state)); //stringify object and store
    } else {
      var getSavedSno = JSON.parse(localStorage.getItem('saveSno')); //retrieve the object
      this.props.location.state = getSavedSno
    }
    var inactiveId = document.getElementById("inactiveId").value;
    this.state.adminStatus = inactiveId;
    this.getActiveCount();
    this.getAdmin(this.state.activePage, this.state.pageSize, this.state.adminStatus);
  }
  async getAdmin(pageNo, pageSize) {
    var wallBox = this.props.location.state;
    this.setState({ serialNumber: wallBox });

    var url = baseUrl.URLPath + "web/chargers/" + localStorage.getItem('selectedSerialNo') + "/admins/" + this.state.adminStatus + "?pageNo=" + pageNo + "&pageSize=" + pageSize;

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle()
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ dataArray: response })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }
  componentDidMount() {

    let getSelectedSno = localStorage.getItem('selectedSerialNo');
    if (getSelectedSno != undefined) {
      localStorage.setItem('saveSno', JSON.stringify(getSelectedSno)); //stringify object and store
      this.setState({ serialNumber: getSelectedSno });
    } else {
      var getSavedSno = JSON.parse(localStorage.getItem('saveSno')); //retrieve the object
      localStorage.setItem('selectedSerialNo',getSavedSno);
      this.setState({ serialNumber: getSavedSno });
    }
    let lastTab = localStorage.getItem('lastTabName');
    if(lastTab == 'CloudUsers'){
      this.setState({
        activeItem: "3"
      });
      let getSelectedSno = localStorage.getItem('selectedSerialNo');
    if (getSelectedSno != undefined) {
      localStorage.setItem('saveSno', JSON.stringify(localStorage.getItem('selectedSerialNo'))); //stringify object and store
      this.setState({ serialNumber: getSelectedSno });
    } else {
      var getSavedSno = JSON.parse(localStorage.getItem('saveSno')); //retrieve the object
      localStorage.setItem('selectedSerialNo',getSavedSno);
      this.setState({ serialNumber: getSavedSno });
    }
      this.getCloudUsers(1,10);
      this.getCloudCount();
    }else if(lastTab == 'Users'){
      this.setState({
        activeItem: "2"
      });
      let getSelectedSno = localStorage.getItem('selectedSerialNo');
    if (getSelectedSno != undefined) {
      localStorage.setItem('saveSno', JSON.stringify(localStorage.getItem('selectedSerialNo'))); //stringify object and store
      this.setState({ serialNumber: getSelectedSno });
    } else {
      var getSavedSno = JSON.parse(localStorage.getItem('saveSno')); //retrieve the object
      localStorage.setItem('selectedSerialNo',getSavedSno);
      this.setState({ serialNumber: getSavedSno });
    }
      this.getUserAcount();
      this.getUser(this.state.activePage1, this.state.pageSize1);
      if (getSelectedSno != undefined) {
        localStorage.setItem('saveSno', JSON.stringify(localStorage.getItem('saveSno'))); //stringify object and store
        this.setState({ serialNumber: getSelectedSno });
      } else {
        var getSavedSno = JSON.parse(localStorage.getItem('saveSno')); //retrieve the object
        localStorage.setItem('selectedSerialNo',getSavedSno);
        this.setState({ serialNumber: getSavedSno });
      }
    }else{
      this.getActiveCount();
    }
  }
  getActiveCount() {
    var wallBox = this.props.location.state;
    var url = baseUrl.URLPath + "web/chargers/" + wallBox + "/admins/" + this.state.adminStatus + "/count";

    fetch(url, {
      method: "get",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ noOfRecords: response.count });
          console.log(this.state.noOfRecords);
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
    this.getAdmin(this.state.activePage, this.state.pageSize, this.state.adminStatus);
  }
  render() {

    // condition for selectstatus
    var getRole = localStorage.getItem("role");

    if (getRole == Roles.WEBASTO_WALLBOX_USER) {
      this.state.enableSelectStatus = "none";
    }
    else {
      this.state.enableSelectStatus = "block";

    }

    const admindata = this.state.dataArray.map((dataArray, index) => {
      
      if (dataArray.active == true) {
        dataArray.active = "Active";
      } else if (dataArray.active == false) {
        dataArray.active = "Inactive";
      }
      console.log(dataArray.role)

      if (dataArray.role == "Admin") {
        return (
          <tr>
            {/* <td>{index+1}</td> */}
            <td>{dataArray.email}</td>
            {/* <td>{dataArray.role}</td> */}
            <td>{dataArray.active}</td>
            {/* <td>1kWh/2$</td> */}
            {/* <td>    <Link to={{
            // pathname: '/editInventory',
            // state: orgArray.serialNumber,
          }}  ><i class="fas fa-pencil-alt   pr-2 action-icon edit_usericon" title="Edit"></i></Link></td> */}
            {/* <td> <Link to={{
                          pathname:'/userHistory', 
                          // state: items.serial_number, 
                          }}>View</Link></td> */}
          </tr>
        )
      }

    })

    const userdata = this.state.dataArray1.map((dataArray1, index) => {
      var getWallBoxData = {
        wallBoxNo: this.state.serialNumber,
        dataArray1
      }
      if (dataArray1.active == true) {
        dataArray1.active = "Active";
      } else if (dataArray1.active == false) {
        dataArray1.active = "Inactive";
      }
      if (dataArray1.index != 0) {
        return (
          <tr>
            <td>{dataArray1.email}</td>
            <td>{dataArray1.active}</td>
            <td> <Link to={{
              pathname: '/userHistory',
              state: getWallBoxData
            }} onClick={()=>{
              localStorage.setItem("lastTabName","Users");
            }}>View</Link></td>
          </tr>
        )
      }
    })

    const cloudUsers = this.state.dataArray2.map((dataArray2,index)=>{
      return (
        <tr key={index}>
          <td>{dataArray2.email}</td>
          <td>{dataArray2.firstName}</td>
          <td>{dataArray2.lastName}</td>
          <>
            {(dataArray2.status == true) ? <td>Active</td> : <td>Inactive</td>}
          </>
          <td> <Link to={{ pathname: '/cloudUserHistory' }} onClick={()=>{
            localStorage.setItem("selectedUserId",dataArray2.id);
            localStorage.setItem("isCloudUsersPage",true);
            localStorage.setItem("selectedUserEmail",dataArray2.email);
            localStorage.setItem("isFromWallboxList",true);
            localStorage.setItem("lastTabName","CloudUsers");
          }}>View</Link></td>
        </tr>
      )
    })

    return (
      <>
        <main class="content-div">
            <p>Wall Box Users</p>
          <div className="page-outerdiv">
              <div className="pull-right ">
                <button class="btn btn-primary m-0 btn-blue" type="button" onClick={this.backToList} > Back
                </button></div>
            <div className="row mt-3 trans_lable">

              <div className="col-md-4 pl-0">
                <b>Serial Number:</b> {this.state.serialNumber}
              </div>

            </div>
            {/* end page menu bar  */}

            {/* table start */}
            <form
              className='needs-validation'
              // onSubmit={this.toggle1}
              noValidate
            >
              <MDBNav className="nav-tabs mt-3">
                <MDBNavItem>
                  <MDBNavLink to="#" className={this.state.activeItem == "1" ? "activetab" : ""} onClick={this.tabToggle.bind(this, "1")} role="tab" >
                    Admin
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink to="#" className={this.state.activeItem == "2" ? "activetab" : ""} onClick={this.tabToggle.bind(this, "2")} role="tab" >
                    Users
                  </MDBNavLink>
                </MDBNavItem>
                {
                  (localStorage.getItem('role')==Roles.WEBASTO_POWER_USER|| localStorage.getItem('role')==Roles.WEBASTO_CHARGER_ADMIN)? 
                  <MDBNavItem>
                  <MDBNavLink to="#" className={this.state.activeItem == "3" ? "activetab" : ""} onClick={this.tabToggle.bind(this, "3")} role="tab" >
                    Cloud Users
                  </MDBNavLink>
                </MDBNavItem>: null
                }
                

              </MDBNav>
              <MDBTabContent activeItem={this.state.activeItem} className="pt-2" >
                {/* details */}
                <MDBTabPane tabId="1" role="tabpanel">
                  <div className="">
                    <div className="row  trans_lable">

                      <div className="col-md-9 mt-3">
                        <label className="pull-left sel_lbl">Show entries</label>
                        <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left " >
                          <Form.Control as="select" className="form_select showentry_sel" id="showAdminEntries" onChange={this.showAdminChange}>
                            <option>10</option>
                            <option>20</option>
                            <option>30</option>
                            <option>40</option>
                            <option>50</option>
                          </Form.Control>
                        </Form.Group>
                      </div>

                      <div style={{ display: this.state.enableSelectStatus }} className="col-md-3 mt-1 pull-right">
                        <select className="browser-default custom-select select_height" id="inactiveId" onChange={this.adminsStatusChanged.bind(this)}>
                          <option value="">Select Status</option>
                          <option value="1">Active</option>
                          <option value="0">Inactive</option>
                        </select>
                      </div>

                    </div>
                  </div>
                  <div className="row mt-2 ">
                    <div className="col-md-12">
                      <Table striped hover size="sm" className="page-table">
                        <thead>
                          <tr>
                            <th>E-mail</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {admindata}
                          {
                            (this.state.dataArray.length == 0) ?
                              <tr>
                                <td colSpan="5" style={{ textAlign: "center" }}>No Data Available</td>
                              </tr> : null
                          }
                        </tbody>
                      </Table>
                      <Pagination
                        hideDisabled
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.pageSize}
                        totalItemsCount={this.state.noOfRecords}
                        onChange={this.handlePageChange.bind(this)}
                      />
                    </div>


                    {/* End table  */}
                  </div>
                </MDBTabPane>

                {/* details */}
                <MDBTabPane tabId="2" role="tabpanel">

                  <div className="row  trans_lable">

                    <div className="col-md-9 mt-3">
                      <label className="pull-left sel_lbl">Show entries</label>
                      <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left " >
                        <Form.Control as="select" className="form_select showentry_sel" id="showEntries" onChange={this.showEntriesChange}>
                          <option>10</option>
                          <option>20</option>
                          <option>30</option>
                          <option>40</option>
                          <option>50</option>
                        </Form.Control>
                      </Form.Group>
                    </div>

                    <div style={{ display: this.state.enableSelectStatus }} className="col-md-3 mt-1 pull-right">
                      <select className="browser-default custom-select select_height" id="status_Select_id" onChange={this.StatusUser.bind(this)}>
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                      </select>
                    </div>

                  </div>

                  <div className="row mt-2 ">
                    <div className="col-md-12">
                      <Table striped hover size="sm" className="page-table">
                        <thead>
                          <tr>
                            <th>E-mail</th>
                            <th>Status</th>
                            <th>History</th>

                          </tr>
                        </thead>
                        <tbody>
                          {userdata}
                          {
                            (this.state.dataArray1.length == 0) ?
                              <tr>
                                <td colSpan="5" style={{ textAlign: "center" }}>No Data Available</td>
                              </tr> : null
                          }
                        </tbody>
                      </Table>
                      <Pagination
                        hideDisabled
                        activePage={this.state.activePage1}
                        itemsCountPerPage={this.state.pageSize1}
                        totalItemsCount={this.state.noOfRecords1}
                        onChange={this.handlePageChangeUser.bind(this)}
                      />
                    </div>


                    {/* End table  */}
                  </div>
                </MDBTabPane>

                {/* Cloud Users Tab Data */}
                <MDBTabPane tabId="3" role="tabpanel">
                <div className="row  trans_lable">
                    <div className="col-md-9 mt-3">
                      <label className="pull-left sel_lbl">Show entries</label>
                      <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left " >
                        <Form.Control as="select" className="form_select showentry_sel" id="showCloudUsers" onChange={this.changeEntries}>
                          <option>10</option>
                          <option>20</option>
                          <option>30</option>
                          <option>40</option>
                          <option>50</option>
                        </Form.Control>
                      </Form.Group>
                    </div>

                  </div>
                  <div className="row mt-2 ">
                    <div className="col-md-12">
                      <Table striped hover size="sm" className="page-table">
                        <thead>
                          <tr>
                            <th>E-mail</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Status</th>
                            <th>History</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cloudUsers}
                          {
                            (this.state.dataArray2.length == 0) ?
                              <tr>
                                <td colSpan="5" style={{ textAlign: "center" }}>No Data Available</td>
                              </tr> : null
                          }
                        </tbody>
                      </Table>
                      <Pagination
                        hideDisabled
                        activePage={this.state.activePage2}
                        itemsCountPerPage={this.state.pageSize2}
                        totalItemsCount={this.state.noOfRecords2}
                        onChange={this.handlePageChangeCloud.bind(this)}
                      />
                    </div>


                    {/* End table  */}
                  </div>
                </MDBTabPane> 
              </MDBTabContent>

            </form>
          </div>
        </main>
        <div className="loader_img" id="loader_image_div" style={{ display: "none" }}>
          <img src={process.env.PUBLIC_URL + "/assets/img/ajax-loading-gif-transparent-background-1.gif"} style={{ width: "138px" }} alt="" />
        </div>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggle}>Session Expired</MDBModalHeader>
          <MDBModalBody>
            Please login again.
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={this.tagOk}>OK</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default wallBoxUserList;

